import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

export default class PageNotFound extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Несъществуваща страница — Easymath.bg</title>
        </Helmet>
        
        <h1>404 Page not Found</h1>
      </Fragment>
    )
  }
}