import { call, put, select } from "redux-saga/effects";
import { fetchingData } from '../../utils/services/fetching_data';

import * as actionsUpload from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";
import * as actionsCreatorOnlineContest from "./../online_contest/actions";

export function* deliveryUploadFiles(action) {
  yield put(actionsCreatorLoading.loadingStart(action.uploadType));
  const state = yield select();

  const url = `/API/competitions/${action.competitionId}/quest/${action.questId}/answer/${action.uploadType}`;
  const response = yield call(fetchingData, url, action.data, 'POST', action.authedTypeService, state.authedUser.token, action.uploadAuthorize);

  try {

    if (!response.ok) {

      const dataError = response.body.error;

      yield put(actionsCreatorLoading.loadingStop(action.uploadType));

      if (state.uploadFiles.isUploadedFilesSuccess) {
        yield put(actionsUpload.successfullyUploadedFiles(false));
      }

      yield put(actionsCreatorNotify.notifyMessages({
        type: action.uploadType,
        htmlTemplate: `${action.uploadType}Error`,
        active: true,
        closeNotify: true,
        messageError: dataError,
        messageHeading: '',
        messageBody: ''
      }));

    } else {

      yield put(actionsCreatorLoading.loadingStop(action.uploadType));

      if (!state.uploadFiles.isUploadedFilesSuccess) {
        yield put(actionsUpload.successfullyUploadedFiles(true));
      }

      yield put(actionsUpload.listingUploadedFiles(action.competitionId, action.questId));

    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.uploadType));

    if (state.uploadFiles.isUploadedFilesSuccess) {
      yield put(actionsUpload.successfullyUploadedFiles(false));
    }

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.uploadType,
      htmlTemplate: `${action.uploadType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}

export function* listUploadedFiles(action) {
  yield put(actionsCreatorLoading.loadingStart(action.uploadType));

  const state = yield select();
  const stateOnlineContest = yield select(state => state.onlineContest);

  let response = null;

  if (action.signature !== undefined && action.signature !== null && action.signature !== '') {

    const url = `/API/competitions/${action.competitionId}/quest/${action.questId}/user/${action.userId}/answer/list`;
    response = yield call(fetchingData, url, null, 'GET', action.authedTypeService, action.signature, action.uploadAuthorize);

  } else {
    const url = `/API/competitions/${action.competitionId}/quest/${action.questId}/answer/list`;
    response = yield call(fetchingData, url, null, 'GET', action.authedTypeService, state.authedUser.token, action.uploadAuthorize);
  }

  try {

    if (!response.ok) {

      const dataError = response.body.error;

      yield put(actionsCreatorLoading.loadingStop(action.uploadType));

      if (state.uploadFiles.isUploadedFilesSuccess) {
        yield put(actionsUpload.successfullyUploadedFiles(false));
      }

      yield put(actionsCreatorNotify.notifyMessages({
        type: action.uploadType,
        htmlTemplate: `${action.uploadType}Error`,
        active: true,
        closeNotify: true,
        messageError: dataError,
        messageHeading: '',
        messageBody: ''
      }));

    } else {
      const data = response.body;

      yield put(actionsCreatorLoading.loadingStop(action.uploadType));

      if (stateOnlineContest.currentOnlineContest.exercise.length > 0) {

        yield put(actionsCreatorOnlineContest.updateOnlineContestSingleAnswer(
          {
            pageNum: stateOnlineContest.currentOnlineContest.exercise[0].number - 1,
            exerciseId: stateOnlineContest.currentOnlineContest.exercise[0].id,
            answer: [{ no_answer: "1", answerId: "none" }]
          }
        ));
      }

      if (!state.uploadFiles.isUploadedFilesSuccess) {
        yield put(actionsUpload.successfullyUploadedFiles(true));
      }

      yield put(actionsUpload.getUploadedFilesData(data));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.uploadType));

    if (state.uploadFiles.isUploadedFilesSuccess) {
      yield put(actionsUpload.successfullyUploadedFiles(false));
    }

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.uploadType,
      htmlTemplate: `${action.uploadType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}

export function* deliveryUploadFilesFromQr(action) {
  const state = yield select();

  yield put(actionsCreatorLoading.loadingStart(action.uploadType));

  const url = `/API/competitions/${action.competitionId}/quest/${action.questId}/user/${action.userId}/answer/${action.uploadType}`;
  const response = yield call(fetchingData, url, action.data, 'POST', action.authedTypeService, action.signature, action.uploadAuthorize);

  try {

    if (!response.ok) {

      const dataError = response.body.error;

      if (state.uploadFiles.isUploadedFilesSuccess) {
        yield put(actionsUpload.successfullyUploadedFiles(false));
      }

      yield put(actionsCreatorLoading.loadingStop(action.uploadType));
      yield put(actionsCreatorNotify.notifyMessages({
        type: action.uploadType,
        htmlTemplate: `${action.uploadType}Error`,
        active: true,
        closeNotify: true,
        messageError: dataError,
        messageHeading: '',
        messageBody: ''
      }));

    } else {

      if (!state.uploadFiles.isUploadedFilesSuccess) {
        yield put(actionsUpload.successfullyUploadedFiles(true));
      }

      yield put(actionsCreatorLoading.loadingStop(action.uploadType));
      yield put(actionsUpload.listingUploadedFiles(action.competitionId, action.questId, action.signature, action.userId));

    }
  } catch (error) {

    if (state.uploadFiles.isUploadedFilesSuccess) {
      yield put(actionsUpload.successfullyUploadedFiles(false));
    }
    yield put(actionsCreatorLoading.loadingStop(action.uploadType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.uploadType,
      htmlTemplate: `${action.uploadType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}

export function* deleteUploadFiles(action) {
  const state = yield select();
  yield put(actionsCreatorLoading.loadingStart(action.uploadFileType));

  let response = null;

  if (action.signature !== undefined && action.signature !== null && action.signature !== '') {

    const url = `/API/competitions/${action.competitionId}/quest/${action.questId}/user/${action.userId}/answer/${action.uploadType}`;
    response = yield call(fetchingData, url, action.data, 'POST', action.authedTypeService, action.signature);

  } else {
    const url = `/API/competitions/${action.competitionId}/quest/${action.questId}/answer/${action.uploadType}`;
    response = yield call(fetchingData, url, action.data, 'POST', action.authedTypeService, state.authedUser.token);
  }

  try {

    if (!response.ok) {

      const dataError = response.body.error;

      if (state.uploadFiles.isUploadedFilesSuccess) {
        yield put(actionsUpload.successfullyUploadedFiles(false));
      }

      yield put(actionsCreatorLoading.loadingStop(action.uploadFileType));
      yield put(actionsCreatorNotify.notifyMessages({
        type: action.uploadFileType,
        htmlTemplate: `${action.uploadFileType}Error`,
        active: true,
        closeNotify: false,
        messageError: dataError,
        messageHeading: '',
        messageBody: ''
      }));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.uploadFileType));
      yield put(actionsUpload.listingUploadedFiles(action.competitionId, action.questId, action.signature, action.userId));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.uploadFileType));

    if (state.uploadFiles.isUploadedFilesSuccess) {
      yield put(actionsUpload.successfullyUploadedFiles(false));
    }

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.uploadFileType,
      htmlTemplate: `${action.uploadFileType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}