import { call, put, select } from "redux-saga/effects";
import { fetchingData } from '../../utils/services/fetching_data';
import { login, register, forgottenPassword, authorizeNewPassword} from "../../constants/auth_types";

import * as actionsCreatorAuth from "../root_actions/auth";
import * as actionsCreatorModal from "../../components/shared/ui/modal/actions";
import * as actionsCreatorHeader from "../../components/account_stats/actions";
import * as actionsCreatorSubscription from "../../components/add_subscription/actions";
import * as actionsCreatorLoading from "../root_actions/loading";
import * as actionsCreatorNotifications from "../root_actions/notifications";
import * as actionsCreatorUpdateFiles from "../../components/upload_files/actions";
import * as actionsIntroCompetitions from "../../components/intro_competitions/actions";
import { checkNotifyActive} from "../../utils/helpers/common";

export function* deliverAuthedData(action) {

  const state = yield select();
  yield put(actionsCreatorLoading.loadingStart(action.authedType));

  const url = `/API/auth/${action.authedType}`;

  const authedData = {
    ...action.authedData
  }

  if (action.authedType === login) {

    const response = yield call(fetchingData, url, authedData, 'POST', action.authedType);

    try {

      if (!response.ok) {
        yield put(actionsCreatorLoading.loadingStop(action.authedType));

        yield put(actionsCreatorNotifications.notifyMessages({
          type: action.authedType,
          htmlTemplate: `${action.authedType}Error`,
          active: true,
          closeNotify: false,
          messageError: response.body.error,
          messageHeading:'',
          messageBody: ''
        }));

      } else {
        const data = response.body.data;

        yield put(actionsCreatorLoading.loadingStop(action.authedType));
        const isNotifyActive = checkNotifyActive(state.notifications, 'Auth');

        const currentTimestamp = new Date().getTime();
        const serverTimestamp = (data.expiration - 2) * 1000;
        const timestampExpiration = currentTimestamp + serverTimestamp;

        const newLocalStorageData = {
          token: data.token,
          refreshToken: data.refresh_token,
          email: data.email,
          name: data.name,
          userId: Number(data.id),
          expiration: Number(data.expiration),
          exprirationTime: Number(timestampExpiration),
          examPreparationSubscription: data.examPreparationSubscription,
          videoLessonsSubscription: data.videoLessonsSubscription
        };

        yield localStorage.setItem('easyMath', JSON.stringify(newLocalStorageData));
        yield document.cookie = `Authorization=${data.token};path=/;sameSite=lax`;

        yield put(actionsCreatorAuth.authSuccess(newLocalStorageData));
        yield put(actionsCreatorAuth.authModalState(false));

        if (state.modal.isUserNotRegistered) {
          yield put(actionsCreatorModal.modalUserNameRegistered(false));
        }

        if (state.authedUser.authorizeNewPasswordAuthorizeCode !== '' || state.authedUser.registerAuthorizeCode !== '') {
          yield put(actionsCreatorAuth.authorizeCodeReset());
        }
        if (state.authedUser.authorizeNewPasswordTemplate) {
          yield put(actionsCreatorAuth.authorizeNewPasswordTemplateReset());
        }
        if (state.authedUser.registerConfirmTemplate) {
          yield put(actionsCreatorAuth.authorizeRegisterTemplateReset());
        }
        if (isNotifyActive) {
          yield put(actionsCreatorNotifications.notifyMessageReset('Auth'));
        }

        if (state.introCompetitions.showButtonFlag) {
          yield put(actionsIntroCompetitions.introCompetitionsInit());
        }

      }
    } catch (error) {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));

      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.authedType,
        htmlTemplate: `${action.authedType}CatchError`,
        active: true,
        closeNotify: true,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    }
  }
  if (action.authedType === register) {

    const response = yield call(fetchingData, url, authedData, 'POST', action.authedType);
    try {

      if (!response.ok) {
        yield put(actionsCreatorLoading.loadingStop(action.authedType));
        yield put(actionsCreatorNotifications.notifyMessages({
          type: action.authedType,
          htmlTemplate: `${action.authedType}Error`,
          active: true,
          closeNotify: false,
          messageError: response.body.error,
          messageHeading:'',
          messageBody: ''
        }));
      } else {

        const data =  response.body.status;

        yield put(actionsCreatorLoading.loadingStop(action.authedType));
        yield put(actionsCreatorNotifications.notifyMessages({
          type: action.authedType,
          htmlTemplate: `${action.authedType}Success`,
          active: true,
          closeNotify: false,
          messageError: '',
          messageHeading: data.title,
          messageBody: data.message
        }));
      }
    } catch (error) {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));

      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.authedType,
        htmlTemplate: `${action.authedType}CatchError`,
        active: true,
        closeNotify: true,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    }
  }
  if (action.authedType === forgottenPassword) {

    const response = yield call(fetchingData, url, authedData, 'POST', action.authedType);
    try {

      if (!response.ok) {
        yield put(actionsCreatorLoading.loadingStop(action.authedType));

        yield put(actionsCreatorNotifications.notifyMessages({
          type: action.authedType,
          htmlTemplate: `${action.authedType}Error`,
          active: true,
          closeNotify: false,
          messageError: response.body.error,
          messageHeading:'',
          messageBody: ''
        }));
      } else {

        yield put(actionsCreatorLoading.loadingStop(action.authedType));
        yield put(actionsCreatorNotifications.notifyMessages({
          type: action.authedType,
          htmlTemplate: `${action.authedType}Success`,
          active: true,
          closeNotify: false,
          messageError: '',
          messageHeading:'Успешна заявка за <br /> смяна на парола!',
          messageBody: 'На посочения от вас e-mail адрес е изпратен линк, <br /> с който можете да смените паролата си.'
        }))
      }
    } catch (error) {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));

      console.log("====================================");
      console.log(error);
      console.log("====================================");

      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.authedType,
        htmlTemplate: `${action.authedType}CatchError`,
        active: true,
        closeNotify: true,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    }
  }
  if (action.authedType === authorizeNewPassword) {

    const response = yield call(fetchingData, url, authedData, 'POST', action.authedType);

    try {

      if (!response.ok) {
        yield put(actionsCreatorLoading.loadingStop(action.authedType));

        yield put(actionsCreatorNotifications.notifyMessages({
          type: action.authedType,
          htmlTemplate: `${action.authedType}Error`,
          active: true,
          closeNotify: false,
          messageError: response.body.error,
          messageHeading:'',
          messageBody: ''
        }));
      } else {
        yield put(actionsCreatorLoading.loadingStop(action.authedType));

        yield put(actionsCreatorNotifications.notifyMessages({
          type: action.authedType,
          htmlTemplate: `${action.authedType}Success`,
          active: true,
          closeNotify: false,
          messageError: '',
          messageHeading:'Успешна заявка за <br /> смяна на парола!',
          messageBody: 'На посочения от вас e-mail адрес е изпратен линк, <br /> с който можете да смените паролата си.'
        }))
        yield put(actionsCreatorAuth.authorizeCodeReset());
      }

    } catch (error) {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));

      console.log("====================================");
      console.log(error);
      console.log("====================================");

      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.authedType,
        htmlTemplate: `${action.authedType}CatchError`,
        active: true,
        closeNotify: true,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    }
  }
}

export function* deliverAuthedRegisterAuthorize(action) {
  yield put(actionsCreatorLoading.loadingStart(action.authedType));

  const url = `/API/auth/${action.authedType}`;
  const authedData = {
    kod: action.code
  }

  const response = yield call(fetchingData, url, authedData, 'POST', action.authedType);

  try {
    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));

      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.authedType,
        htmlTemplate: `${action.authedType}Error`,
        active: true,
        closeNotify: false,
        messageError: response.body.error,
        messageHeading:'',
        messageBody: ''
      }));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));

      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.authedType,
        htmlTemplate: `${action.authedType}Success`,
        active: true,
        closeNotify: false,
        messageError: '',
        messageHeading:'Акаунтът ви е вече активен!',
        messageBody: 'Поздравления! Вашият Easymath.bg акаунт е вече активен. Можете да влезете в профила си чрез формата за вход.'
      }));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.authedType));

    console.log("====================================");
    console.log(error);
    console.log("====================================");

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.authedType,
      htmlTemplate: `${action.authedType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }
}

export function* authLogout(action) {

  yield put(actionsCreatorLoading.loadingStart(action.authedType));

  const state = yield select();
  const url = `/API/auth/${action.authedType}`;

  const authedData = {
    refreshToken: state.authedUser.refreshToken
  }
  const response = yield call(fetchingData, url, authedData, 'POST', action.authedTypeService);

  try {
    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));
      yield put(actionsCreatorAuth.refreshLogout(action.history));

      if (state.introCompetitions.showButtonFlag) {
        yield put(actionsIntroCompetitions.introCompetitionsInit());
      }

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.authedType));
      yield logoutReset();

      if (state.introCompetitions.showButtonFlag) {
        yield put(actionsIntroCompetitions.introCompetitionsInit());
      }

      yield action.history.push('/');

    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.authedType));
    console.log("====================================");
    console.log(error);
    console.log("====================================");
  }
}

export function* authRefreshLogout(action) {

  const state = yield select();
  const authData = {};

  const url = `/API/auth/${action.authedType}`;

    if (state.authedUser.token !== null && state.authedUser.refreshToken !== null) {
      authData.refreshToken = state.authedUser.refreshToken;
    } else {
      const refreshToken = yield JSON.parse(localStorage.getItem('easyMath')).refreshToken;
      authData.refreshToken = refreshToken;
    }

    const response = yield call(fetchingData, url, authData, 'POST', action.authedTypeService);

    try {
      if (!response.ok) {
      } else {
        yield logoutReset();
        yield action.history.push('/');
      }
    } catch (error) {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
    }
}

export function* authRefresh(action) {

  yield put(actionsCreatorAuth.authRefreshInProgressFlag(true));

  const state = yield select();
  const url = `/API/auth/${action.authedType}`;
  const authedData = {};

  if (state.authedUser.token !== null && state.authedUser.refreshToken !== null) {
    authedData.refreshToken = state.authedUser.refreshToken;
  } else {
    const refreshToken = yield JSON.parse(localStorage.getItem('easyMath')).refreshToken;
    authedData.refreshToken = refreshToken;
  }

  const response = yield call(fetchingData, url, authedData, 'POST', action.authedTypeService);

  try {

    if (!response.ok) {
      yield logoutReset();
    } else {

      const data = response.body;
      const refreshTokenData = yield refreshFetchingData(data);

      yield put(actionsCreatorAuth.authRefreshSignIn(refreshTokenData));
      yield put(actionsCreatorAuth.authRefreshInProgressFlag(false));

      yield componentsAccessSwitcher(action.componentAccessSwitcher, action.data, data.token);

    }
  } catch (error) {

    console.log("====================================");
    console.log(error);
    console.log("====================================");
  }
}

function* logoutReset() {
  yield put(actionsCreatorAuth.logoutReset());
  yield put(actionsCreatorHeader.toggleAccountStatus(false));

  yield localStorage.removeItem('easyMath');
  yield document.cookie = `Authorization=${''};path=/`;
}
function* refreshFetchingData(data) {
  const currentTimestamp = new Date().getTime();
  const serverTimestamp = (data.expiration - 2) * 1000;
  const timestampExpiration = currentTimestamp + serverTimestamp;
  const newRefreshedLocalStorageData = yield JSON.parse(localStorage.getItem('easyMath'));

  newRefreshedLocalStorageData.token = data.token;
  newRefreshedLocalStorageData.expiration = data.expiration;
  newRefreshedLocalStorageData.exprirationTime = timestampExpiration;
  newRefreshedLocalStorageData.examPreparationSubscription = data.examPreparationSubscription;
  newRefreshedLocalStorageData.videoLessonsSubscription = data.videoLessonsSubscription;

  yield localStorage.setItem('easyMath', JSON.stringify(newRefreshedLocalStorageData));
  yield document.cookie = `Authorization=${data.token};path=/;sameSite=lax`;

  return newRefreshedLocalStorageData;
}
function * componentsAccessSwitcher(switcher, data, token) {

  const state = yield select();

  if (switcher === 'subscription') {
    yield put(actionsCreatorSubscription.subscriptionsInit());
  }

  if (switcher === 'subscriptionPayment') {
    yield put(actionsCreatorSubscription.deliverySubscriptionPayment(data));
  }

  if (switcher === 'promoCode') {
    yield put(actionsCreatorSubscription.deliverySubscriptionPromoCode(data));
  }

  if (switcher === 'mainHeader') {
    yield put(actionsCreatorHeader.checkAccountStatsInfo());
  }

  if (switcher === 'uploadFiles') {
    const exerciseId = state.onlineContest.currentOnlineContest.exercise[0].id;

    if (state.onlineContest.OnlineContestInfo.id !== "") {
      yield put(actionsCreatorUpdateFiles.deliveryUploadFiles(data, state.onlineContest.OnlineContestInfo.id, exerciseId));

    } else {
      const contestId = yield JSON.parse(localStorage.getItem('OnlineContestInfo')).id;
      yield put(actionsCreatorUpdateFiles.deliveryUploadFiles(data, contestId, exerciseId));

    }
  }

  if (switcher === 'deleteUploadFile') {
    const exerciseId = state.onlineContest.currentOnlineContest.exercise[0].id;

    if (state.onlineContest.OnlineContestInfo.id !== "") {
      yield put(actionsCreatorUpdateFiles.deleteUploadFiles(data, state.onlineContest.OnlineContestInfo.id, exerciseId));

    } else {
      const contestId = yield JSON.parse(localStorage.getItem('OnlineContestInfo')).id;
      yield put(actionsCreatorUpdateFiles.deleteUploadFiles(data, contestId, exerciseId));

    }
  }

  if (switcher === "listUploadFile") {

    const exerciseIdForListing = state.onlineContest.currentOnlineContest.exercise[0].id;

    if (state.onlineContest.OnlineContestInfo.id !== "") {
      yield put(actionsCreatorUpdateFiles.listingUploadedFiles(state.onlineContest.OnlineContestInfo.id, exerciseIdForListing));

    } else {
      const contestId = yield JSON.parse(localStorage.getItem('OnlineContestInfo')).id;
      yield put(actionsCreatorUpdateFiles.listingUploadedFiles(contestId, exerciseIdForListing));

    }
  }

  if (switcher === "introCompetitions") {
    yield put(actionsIntroCompetitions.introCompetitionsInit());
  }

  const query = yield usingQuerry();

  const queryPayment = query.get("payment");
  const paymentId = query.get("paymentId");

  if (queryPayment === 'success' && paymentId !== null && paymentId !== "") {
    const paymentStatus = 'paid';

    yield put(actionsCreatorSubscription.successFullyPaymentModalState(true));
    yield put(actionsCreatorSubscription.deliveryPaymentData(paymentId, paymentStatus, token));
  }

  if (queryPayment === 'cancel' && paymentId !== null && paymentId !== "") {
    const paymentStatus = 'cancelled';

    yield put(actionsCreatorSubscription.successFullyPaymentModalState(true));
    yield put(actionsCreatorSubscription.deliveryPaymentData(paymentId, paymentStatus, token));
  }
}
function* usingQuerry()  {
  return yield new URLSearchParams(window.location.search);
}