export const NOTIFY_MESSAGES = 'NOTIFY_MESSAGES';
export const NOTIFY_MESSAGE_RESET = 'NOTIFY_MESSAGE_RESET';

export function notifyMessages(notifierProps) {
  return {
    type: NOTIFY_MESSAGES,
    notifierProps
  }
}
export function notifyMessageReset(typeGroup) {
  return {
    type: NOTIFY_MESSAGE_RESET,
    typeGroup,
    notifyType: "",
    htmlTemplate: "",
    active: false,
    closeNotify: false,
    messageError: "",
    messageHeading: "",
    messageBody: ""
  }
}
