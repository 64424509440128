import { call, put, select } from "redux-saga/effects";
import { fetchingData } from '../../utils/services/fetching_data';

import * as actionsCreatorContact from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotifications from "../../store/root_actions/notifications";

export function* deliverContactData(action) {

  const state = yield select();
  yield put(actionsCreatorLoading.loadingStart(action.contactType));

  const contactData = {
    ...action.contactData
  }

  const url = `/API/${action.contactType}/new`;
  const response = yield call(fetchingData, url, contactData, 'POST', action.contactType);

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.contactType));

      if (state.contact.isContactDataDeliveredSuccess) {
        yield put(actionsCreatorContact.contactDeliveredSuccess(false));
      }
      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.contactType,
        htmlTemplate: `${action.contactType}Error`,
        active: true,
        closeNotify: false,
        messageError: response.body.error,
        messageHeading: 'Възникна грешка!<br />Моля, пробвайте отново. Благодарим Ви!',
        messageBody: ''
      }));
    } else {

      yield put(actionsCreatorLoading.loadingStop(action.contactType));
      yield put(actionsCreatorContact.contactDeliveredSuccess(true));
      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.contactType,
        htmlTemplate: `${action.contactType}Success`,
        active: true,
        closeNotify: true,
        messageError: '',
        messageHeading: response.body.status,
        messageBody: 'Благодарим Ви!'
      }));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.contactType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.contactType,
      htmlTemplate: `${action.contactType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }
}

export function* getContactInfoData(action) {

  yield put(actionsCreatorLoading.loadingStart(action.contactType));

  const url = `/API/pages/${action.contactType}`;
  const response = yield call(fetchingData, url, null, 'GET', action.contactType);

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.contactType));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.contactType));
      const data = response.body.data;

      yield put(actionsCreatorContact.contactInfoDataSuccess(true));
      yield put(actionsCreatorContact.getContactInfoData(data));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.contactType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');
    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.contactType,
      htmlTemplate: `${action.contactType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}