import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PropTypes from "prop-types";
import * as actionsCreatorSubscription from "./actions";

class SubscriptionsCompetitions extends PureComponent {
  static propTypes = {
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChangeContestFields: PropTypes.func.isRequired,
    contest: PropTypes.object.isRequired,
    contestUpdate: PropTypes.object.isRequired,
    contestValidate: PropTypes.object.isRequired,
    isFocus: PropTypes.object.isRequired,
    subscriptionList: PropTypes.array.isRequired,
    subscription: PropTypes.object.isRequired,
  };

  render() {
    const {
      onFocus,
      onBlur,
      onChangeContestFields,
      contest,
      contestValidate,
      contestUpdate,
      isFocus,
      subscriptionList,
      subscription,
    } = this.props;

    return (
      <div className="subscriptions-contests">
        <div className="subscription-contest-item subscription-contest-first">
          {/* <fieldset className="form-field-set invoice-offset">

            <input
              type="text"
              id="contest-name"
              name="name"
              value="Kyoso Child"
              className={`white-autofill form-input input-blue subscription-input ${
                !contestValidate.name && contestUpdate.name ? "invalid " : ""
              }`}
              onChange={onChangeContestFields}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Имена на участник"
            />

            <label
              htmlFor="contest-name"
              className="form-label form-label-error"
            ></label>
            <label className="form-label-required label-blue">*</label>
            <label
              className={`form-label-focus focus-white ${
                isFocus.name ? "focus-animate" : ""
              } ${
                !contestValidate.name && contestUpdate.name ? "invalid " : ""
              }`}
            ></label>
          </fieldset> */}

          <fieldset className="form-field-set invoice-offset">
            <select
              id="contest-level"
              name="level"
              className={`form-dropdown ${isFocus.level ? "focus-bg" : ""}`}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChangeContestFields}
              value={contest.level}
            >
              <option value="Клас" disabled={true}>
                Клас 2022/2023 уч.г. *
              </option>
              {subscriptionList.length > 0
                ? subscriptionList
                    .filter(
                      (selectedSubsription) =>
                        selectedSubsription.id ===
                          subscription.subscriptionId &&
                        selectedSubsription.isCompetition
                    )
                    .map((sub) => {
                      return sub.competitionData.map((option, index) => {
                        return (
                          <option
                            key={`subscriptionContestLevel${index}`}
                            value={option.class}
                            data-contestid={option.id}
                          >
                            {option.class}
                          </option>
                        );
                      });
                    })
                : null}
            </select>
          </fieldset>
        </div>

        {/* <div className="subscription-contest-item subscription-contest-second"> */}
        {/*<fieldset className="form-field-set invoice-offset">

            <input type="text" id="contest-school" name="school" value={contest.school}
              className={`white-autofill form-input input-blue subscription-input ${!contestValidate.school && contestUpdate.school ? 'invalid ' : ''}`}
              onChange={onChangeContestFields}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Училище"
            />

            <label
              htmlFor="contest-school"
              className="form-label form-label-error"
            >
              {!contestValidate.school &&
              contestUpdate.school &&
              contest.school !== ""
                ? "Въвеждате забранени и/или мин. брой символа (2)!"
                : ""}
            </label>
            <label
              className={`form-label-focus focus-white ${
                isFocus.school ? "focus-animate" : ""
              } ${
                !contestValidate.school && contestUpdate.school
                  ? "invalid "
                  : ""
              }`}
            ></label>
          </fieldset>
          */}

        {/* <fieldset className="form-field-set invoice-offset">
            <input
              type="text"
              id="contest-city"
              name="city"
              value="Online"
              className={`white-autofill form-input input-blue subscription-input ${
                !contestValidate.city && contestUpdate.city ? "invalid " : ""
              }`}
              onChange={onChangeContestFields}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Град"
              hidden
            />

            <label
              htmlFor="contest-city"
              className="form-label form-label-error"
            >
              {!contestValidate.city &&
              contestUpdate.city &&
              contest.city !== ""
                ? "Въвеждате забранени и/или мин. брой символа (2)!"
                : ""}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label
              className={`form-label-focus focus-white ${
                isFocus.city ? "focus-animate" : ""
              } ${
                !contestValidate.city && contestUpdate.city ? "invalid " : ""
              }`}
            ></label>
          </fieldset>
        </div> */}

        <div className="subscription-contest-item subscription-contest-last">
          <fieldset className="form-field-set invoice-offset">
            <input
              type="text"
              id="contest-parent"
              name="parent"
              value={contest.parent}
              className={`white-autofill form-input input-blue subscription-input ${
                !contestValidate.parent && contestUpdate.parent
                  ? "invalid "
                  : ""
              }`}
              onChange={onChangeContestFields}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Родител"
            />

            <label
              htmlFor="contest-parent"
              className="form-label form-label-error"
            >
              {!contestValidate.parent &&
              contestUpdate.parent &&
              contest.parent !== ""
                ? "Въвеждате забранени и/или мин. брой символа (2)!"
                : ""}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label
              className={`form-label-focus focus-white ${
                isFocus.parent ? "focus-animate" : ""
              } ${
                !contestValidate.parent && contestUpdate.parent
                  ? "invalid "
                  : ""
              }`}
            ></label>
          </fieldset>

          <fieldset className="form-field-set invoice-offset">
            <input
              type="text"
              id="contest-phone"
              name="contestPhone"
              value={contest.contestPhone}
              className={`white-autofill form-input input-blue subscription-input ${
                !contestValidate.contestPhone && contestUpdate.contestPhone
                  ? "invalid "
                  : ""
              }`}
              onChange={onChangeContestFields}
              onFocus={onFocus}
              onBlur={onBlur}
              inputMode="numeric"
              pattern="\d*"
              placeholder="Тел. за връзка"
            />

            <label
              htmlFor="contest-phone"
              className="form-label form-label-error"
            >
              {!contestValidate.contestPhone &&
              contestUpdate.contestPhone &&
              contest.contestPhone !== ""
                ? "Въвеждате забранени символи и/или мин. брой цифри (5)!"
                : ""}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label
              className={`form-label-focus focus-white ${
                isFocus.contestPhone ? "focus-animate" : ""
              } ${
                !contestValidate.contestPhone && contestUpdate.contestPhone
                  ? "invalid "
                  : ""
              }`}
            ></label>
          </fieldset>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ addSubscription }) => {
  return {
    subscriptionList: addSubscription.subscriptionList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actionsCreatorSubscription,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsCompetitions);
