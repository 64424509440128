import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  showButtonFlag: false,
  competitionInProgress: false,
  competitionInProgressCompleted: false,
  userRegisteredInCompetitionFlag: false,
  activeCompetitionInfo: {}
};

const getIntroCompetitionsData = (state, action) => {
  return updateObject(state, {
    showButtonFlag: action.data.showButtonFlag,
    competitionInProgress: action.data.competitionInProgress,
    competitionInProgressCompleted: action.data.competitionInProgressCompleted,
    userRegisteredInCompetitionFlag: action.data.userRegisteredInCompetitionFlag,
    activeCompetitionInfo: action.data.activeCompetitionInfo
  })
}

const resetIntoContestState = (state) => {
  return updateObject(state, {
    showButtonFlag: false,
    competitionInProgress: false,
    competitionInProgressCompleted: false,
    userRegisteredInCompetitionFlag: false,
    activeCompetitionInfo: {}
  })
}

const introCompetitions = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INTRO_COMPETITIONS_DATA: return getIntroCompetitionsData(state, action);
    case actionTypes.RESET_INTRO_CONTEST_STATE: return resetIntoContestState(state, action);
    default: return state
  }
}

export default introCompetitions;