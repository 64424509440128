import { call, put } from 'redux-saga/effects';
import { getContentGeneric } from '../../utils/services/get_content';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorVideoList from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotifiy from "../../store/root_actions/notifications";

export function* getPageContentVideoList(action) {

  yield put(actionsCreatorLoading.loadingStart(action.videoType));

  const url = '/API/lessons/list?count=' + action.itemsPerPage + '&page=' + action.activePage;
  const response = yield call(fetchingData, url, null, 'GET');

  try {
    if (!response.ok) {

      yield put(actionsCreatorLoading.loadingStop(action.videoType));
    } else {
      const data = response.body.data;

      yield put(actionsCreatorLoading.loadingStop(action.videoType));
      yield put(actionsCreatorVideoList.getPageContentSuccess(data));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.videoType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifiy.notifyMessages({
      type: action.videoType,
      htmlTemplate: `${action.videoType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* getPageContentVideoInfo(action) {

  yield put(actionsCreatorLoading.loadingStart(action.modalVideoInfoType));

  const url = `/API/lessons/${action.infoType}`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.modalVideoInfoType));

    } else {

      const data = response.body.data.teachs;
      const newModalVideoInfoData = {
        videoInfo: data.info,
        videoName: data.name,
        literature: data.literature,
        literatureName: 'Използвана литература',
        loaded: true
      }
      yield put(actionsCreatorLoading.loadingStop(action.modalVideoInfoType));
      yield put(actionsCreatorVideoList.getModalContentSuccess(newModalVideoInfoData));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.modalVideoInfoType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifiy.notifyMessages({
      type: action.modalVideoInfoType,
      htmlTemplate: `${action.modalVideoInfoType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }
}

export function* getCurrentVideoInfo(action) {

  try {

    const url = '/API/lessons/' + action.id;
    let response = yield call(getContentGeneric, url, {}, 'GET');

    if (response.error === null) {
      yield put(actionsCreatorVideoList.getCurrentVideoInfoSuccess(
        {
          ...response.lesson
        }
      ));
    } else {
      console.log(response.error);
    }
  }
  catch(error) {
    console.log(error);
  }

}

export function* getCurrentVideoExerciseInfo(action) {

  yield put(actionsCreatorLoading.loadingStart(action.videoType));

  const url = `/API/lessons/${action.id}/${action.videoType}?number=${action.pageNumber}`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.videoType));
      yield put(actionsCreatorNotifiy.notifyMessages({
        type: action.videoType,
        htmlTemplate: `${action.videoType}Error`,
        active: true,
        closeNotify: false,
        messageError: 'Получи се грешка при зареждането на съдържанието на задачата.!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));

      } else {

        const data = response.body.data;
        const newInfoData = {
          ...data
        }

        yield put(actionsCreatorLoading.loadingStop(action.videoType));
        yield put(actionsCreatorVideoList.getCurrentVideoExerciseInfoSuccess(newInfoData));
      }
    }
    catch(error) {
      yield put(actionsCreatorLoading.loadingStop(action.videoType));

      console.log('------------------------------------');
      console.log(error);
      console.log('------------------------------------');

      yield put(actionsCreatorNotifiy.notifyMessages({
        type: action.videoType,
        htmlTemplate: `${action.videoType}CatchError`,
        active: true,
        closeNotify: false,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
  }

}