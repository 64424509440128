import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import * as actionsCreatorNotify from "../../store/root_actions/notifications";
import { checkValidationFields } from "../../utils/helpers/common";
import Spinner from "../shared/ui/spinner/spinner";
import Notify from "../shared/ui/notifications/notify_template";

class AuthorizeNewPassword extends PureComponent {

  static propTypes = {
    notifyAuthorizeNewPassword: PropTypes.object.isRequired,
    onChangeInfo: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    isInputsValid: PropTypes.object.isRequired,
    isUpdate: PropTypes.object.isRequired,
    inputsValue: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleFocusOut: PropTypes.func.isRequired,
    isFocus: PropTypes.object.isRequired
	}

  render() {

    const { onChangeInfo, onSubmitHandler, isInputsValid, isUpdate, inputsValue, loading,
      notifyAuthorizeNewPassword, handleFocus, handleFocusOut, isFocus } = this.props;
    const isValid = checkValidationFields(isInputsValid);

    const notifyAuthorizeNewPasswordTemplate = <Notify
      typeGroup={'Auth'}
      type={notifyAuthorizeNewPassword.type}
      htmlTemplate={notifyAuthorizeNewPassword.htmlTemplate}
      active={notifyAuthorizeNewPassword.active}
      closeNotify={notifyAuthorizeNewPassword.closeNotify}
      messageHeading={notifyAuthorizeNewPassword.messageHeading}
      messageBody={notifyAuthorizeNewPassword.messageBody}
      messageError={notifyAuthorizeNewPassword.messageError}
      classStyle={'notify-blue'}
      classPosition={'notify-absolute'} />

    return (
      <Router>
        <form className="authorize-form auth-form">
          <h2 className="auth-title text-register">Забравена Парола</h2>
          <p className="auth-text-content text-register">Въведете нова парола за акаунта си. Паролата трябва да съдъжра между 6 и 32 символа.</p>

          {
            loading
              ? <Spinner classColor={`spinner-white`} />
              : null
          }
          {
            notifyAuthorizeNewPassword.htmlTemplate === 'authorizeNewPasswordSuccess' || notifyAuthorizeNewPassword.htmlTemplate === 'authorizeNewPasswordCatchError'
            ? notifyAuthorizeNewPasswordTemplate
            : null
          }

          <fieldset className="form-field-set form-offset">
            <input type="password" id="activate-password" name="password" value={inputsValue.password}
              className={`form-input input-white ${!isInputsValid.password && isUpdate.password ? 'invalid ': ''}`}
              onChange={onChangeInfo}
              onFocus={handleFocus}
              onBlur={handleFocusOut}
              placeholder="Нова Парола" />

            <label htmlFor="activate-password" className="form-label form-label-error">
              {!isInputsValid.password && isUpdate.password && inputsValue.password !== '' ? 'Минимален брой сомволи (6)!' : ''}
            </label>
            <label className={`form-label-focus focus-blue ${isFocus.password ? 'focus-animate' : ''}
              ${!isInputsValid.password && isUpdate.password ? 'invalid ': ''}`}>
            </label>
          </fieldset>

          <fieldset className="form-field-set form-offset">
            <input type="password" id="authorize-repassword" name="repassword" value={inputsValue.repassword}
              className={`form-input input-white ${!isInputsValid.repassword && isUpdate.repassword ? 'invalid ': ''}`}
              onChange={onChangeInfo}
              onFocus={handleFocus}
              onBlur={handleFocusOut}
              placeholder="Потвърждение Нова парола" />

            <label htmlFor="authorize-repassword" className="form-label form-label-error">
              {!isInputsValid.repassword && isUpdate.repassword && inputsValue.repassword !== '' ? 'Паролите не съвпадат' : ''}
            </label>
            <label className={`form-label-focus focus-white ${isFocus.repassword ? 'focus-animate' : ''}
              ${!isInputsValid.repassword && isUpdate.repassword ? 'invalid ': ''}`}>
            </label>
          </fieldset>

          <fieldset className="form-field-set field-set-btn auth-submit-offset">
            <button type="button" className="easy-math-button btn-white btn-form-size" title="Промяна" disabled={!isValid}
              onClick={onSubmitHandler}>
              Промяна
            </button>
            <label className="form-label form-label-error-single">
              { notifyAuthorizeNewPassword.htmlTemplate === "authorizeNewPasswordError" ? notifyAuthorizeNewPassword.messageError : '' }
            </label>
          </fieldset>

          <fieldset className="form-field-set form-offset">
            <p className="auth-contact-text">Ако срещате затруднения с регистрацията и акаунта си, <Link className="rules-link" to='/contact-us' target="_blank" title="Контакти">свържете се с нас.</Link></p>
          </fieldset>

        </form>
      </Router>
    )
  }
}

const mapStateToProps = ({loading, notifications}) => {

  return {
    loading: loading.authorizeNewPasswordLoading,
    notifyAuthorizeNewPassword: notifications.authorizeNewPassword
  };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
    ...actionsCreatorNotify
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeNewPassword)