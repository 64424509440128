import { call, put, select } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';

import * as actionsCreatorHeader from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";

export function* initAccountStatsInfo(action) {

  const { authedUser } = yield select();

  yield put(actionsCreatorLoading.loadingStart(action.accountType));

  const url = '/API/user/info';
  const serverBodyPayload = {
    userId: authedUser.userId
  };

  const response = yield call(fetchingData, url, serverBodyPayload, 'POST', action.authedTypeService, authedUser.token);

  try {
    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.accountType));

    } else {
        yield put(actionsCreatorLoading.loadingStop(action.accountType));
        yield put(actionsCreatorHeader.deliverAccountStats({
          loaded: true,
          subscriptions: response.body.pays
        }))
      }
    }
    catch(error){
    yield put(actionsCreatorLoading.loadingStop(action.accountType));
    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');
  }
}