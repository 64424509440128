import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PropTypes from "prop-types";
import './modal.scss';

import BackToTop from '../back_to_top/back_to_top';

class Modal extends PureComponent {

	static propTypes = {
		classID: PropTypes.string.isRequired,
		stateModal: PropTypes.bool.isRequired,
		handleClose: PropTypes.func.isRequired,
		range: PropTypes.number.isRequired,
		classMain: PropTypes.string.isRequired,
		classWrapper: PropTypes.string.isRequired,
		classShell: PropTypes.string.isRequired,
		isUserNotRegistered: PropTypes.bool.isRequired,
		isSubscriptionNotPaid: PropTypes.bool.isRequired
	}

	render() {
		const { classID, classMain, classWrapper, classShell, stateModal, handleClose, range, isUserNotRegistered, isSubscriptionNotPaid } = this.props;

		return (
			<div className={`main-modal overlay ${classMain} ${!stateModal ? "" : "active"}`} id={classID}>
				<BackToTop id={classID} range={range} />
				<div className={`modal-wrapper ${classWrapper}`}>
					{
						isUserNotRegistered || isSubscriptionNotPaid
						? <div className="modal-bought-subscription">
								<p className="modal-bought-subscription-icon"><i className="fas fa-exclamation-triangle"></i></p>
								{
									isUserNotRegistered
										? <p className="modal-authBought-notify">За да продължите е необходимо да влезете в профила със закупен абонамент. Ако нямате регистрация, моля направете такава и следвайте стъпките за закупуване на абонамент.</p>
										: isSubscriptionNotPaid
											? <p className="modal-authBought-notify">За да продължите е необходимо да закупите абонамент.</p>
											: null
								}
							</div>
						: null
					}

					<div className={`modal-shell ${classShell}`}>

						<button type="button" title="Затвори прозореца" className="easy-math-button modal-close-btn btn-text-yellow no-padding" onClick={handleClose}>
							<i className="fas fa-times no-margin"></i>
						</button>

						{this.props.children}

					</div>

				</div>

			</div>
		)
	};
}

const mapStateToProps = ({ modal }) => {
	return {
		isUserNotRegistered: modal.isUserNotRegistered,
		isSubscriptionNotPaid: modal.isSubscriptionNotPaid
	};
};

export default connect(mapStateToProps)(Modal);