import * as actionTypes from './actions';
import { updateObject } from './../../hoc/update-object/update-object';

const initialState = {
  subscriptions: '',
  pricelist: [],
  loaded: false
}

const getContentSuccess = (state, action) => {

  return updateObject(state, {
    subscriptions: action.content.dataSubscription,
    pricelist: action.content.pricelist,
    loaded: action.content.loaded
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_SUBSCRIPTIONS_SUCCESS: return getContentSuccess(state, action);
    default: return state;
  }
}

export default reducer;