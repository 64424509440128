import { call, put } from "redux-saga/effects";
import { fetchingData } from "../../utils/services/fetching_data";
import * as actions from "./actions";

export function* getHotStrips() {
  try {
    const url = "/API/news/hotstrips";
    const response = yield call(fetchingData, url, null, "GET");

    if (!response.ok) {
      console.log(response.error);
    } else {
      yield put(
        actions.getHotStripsSuccess({
          loaded: true,
          data: response.body.data,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}
