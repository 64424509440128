export const GET_HOT_STRIPS = "GET_HOT_STRIPS";
export const GET_HOT_STRIPS_SUCCESS = "GET_HOT_STRIPS_SUCCESS";

export function getHotStrips() {
  return {
    type: GET_HOT_STRIPS,
  };
}

export function getHotStripsSuccess(hotStrips) {
  return {
    type: GET_HOT_STRIPS_SUCCESS,
    hotStrips,
  };
}
