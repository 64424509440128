export const DELIVER_CONTACT_DATA = "DELIVER_CONTACT_DATA";
export const GET_CONTACT_INFO_INIT = "GET_CONTACT_INFO_INIT";
export const CONTACT_DELIVERED_SUCCESS = "CONTACT_DELIVERED_SUCCESS";
export const CONTACT_INFO_DATA_SUCCESS = "CONTACT_INFO_DATA_SUCCESS";
export const GET_CONTACT_INFO_DATA = "GET_CONTACT_INFO_DATA";

export function deliverContactData(contactData, contactType) {
  return {
    type: DELIVER_CONTACT_DATA,
    contactData,
    contactType
  };
}
export function contactDeliveredSuccess(contactDeliveredState) {
  return {
    type: CONTACT_DELIVERED_SUCCESS,
    contactDeliveredState
  };
}
export function contactInfoDataSuccess(contactInfoState) {
  return {
    type: CONTACT_INFO_DATA_SUCCESS,
    contactInfoState
  };
}
export function getContactInfoInit() {
  return {
    type: GET_CONTACT_INFO_INIT,
    contactType: 'contactInfo'
  };
}
export function getContactInfoData(data) {
  return {
    type: GET_CONTACT_INFO_DATA,
    data
  };
}