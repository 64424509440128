export const VALID_EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const VALID_PASSWORD_REG = /^[A-Za-z0-9_!@#$%^&*()+]{6,32}$/i;
export const VALID_NAME_REG = /^[a-z-\u0400-\u04FF][a-z-\s\u0400-\u04FF]{1,40}$/i;
export const VALID_CITY_REG = /^[a-z-\u0400-\u04FF][a-z-\s\u0400-\u04FF]{1,32}$/i;
export const VALID_MESSAGE_REG = /^[a-z0-9!?.,"'-()@&_\u0400-\u04FF][a-z0-9!?.,"'-@&_\s\u0400-\u04FF]{1,1500}$/i;
export const VALID_PHONE_REG = /^[0-9+]{0,32}$/i;
export const VALID_PHONE_REQ = /^[0-9+]{5,32}$/i;
export const VALID_PROMO_CODE = /^[A-Za-z0-9][A-Za-z0-9\s]{2,12}$/i;
export const VALID_COMPANY_NAME_REG = /^[a-z0-9"'.:\-\u0400-\u04FF][a-z0-9"'.:\-\s\u0400-\u04FF]{1,32}$/i;
export const VALID_ADDRESS_REG = /^[a-z0-9"'.,\-\u0400-\u04FF][a-z0-9"'.,\-\s\u0400-\u04FF]{6,50}$/i;
export const VALID_COMPANY_VAT_REG = /^[a-z0-9]{9,15}$/i;
export const VALID_COMPANYID_REG = /^[0-9]{7,13}$/i;
export const VALID_ANSWER_LEGACY_REG = /[^A-Za-z0-9:\s\u0400-\u04FF]/g;
export const VALID_ANSWER_ALPHANUMERIC_REG = /[^A-Za-z0-9\u0400-\u04FF]/g;
export const VALID_ANSWER_SYMBOLS_REG = /[^A-Za-z\u0400-\u04FF]/g;
export const VALID_ANSWER_NUMBERS_REG = /[^0-9]/g;
export const RELATIVE_PATH_REG = /\.\.\//gi;
export const VALID_UPLOAD_FILE_EXTENSIONS = /\.(gif|jpe?g|png|bmp|pdf)$/i;