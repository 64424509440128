export const GET_CONTENT_CONTEST_LIST = 'GET_CONTENT_CONTEST_LIST';
export const GET_CONTENT_CONTEST_LIST_SUCCESS = 'GET_CONTENT_CONTEST_LIST_SUCCESS'
export const GET_CONTENT_CONTEST_INFO = 'GET_CONTENT_CONTEST_INFO';
export const GET_CONTENT_CONTEST_INFO_SUCCESS = 'GET_CONTENT_CONTEST_INFO_SUCCESS';
export const GET_CONTENT_CONTEST_FILTERS = 'GET_CONTENT_CONTEST_FILTERS';
export const GET_CONTENT_CONTEST_FILTERS_SUCCESS = 'GET_CONTENT_CONTEST_FILTERS_SUCCESS';
export const GET_CONTENT_CURRENT_CONTEST_INFO = "GET_CONTENT_CURRENT_CONTEST_INFO";
export const GET_CONTENT_CURRENT_CONTEST_INFO_SUCCESS = "GET_CONTENT_CURRENT_CONTEST_INFO_SUCCESS"
export const GET_CONTENT_CURRENT_CONTEST_EXERCISE = "GET_CONTENT_CURRENT_CONTEST_EXERCISE";
export const GET_CONTENT_CURRENT_CONTEST_EXERCISE_SUCCESS = "GET_CONTENT_CURRENT_CONTEST_EXERCISE_SUCCESS";
export const GET_CONTENT_CURRENT_CONTEST_EXERCISE_FAILURE = "GET_CONTENT_CURRENT_CONTEST_EXERCISE_FAILURE";
export const UPDATE_MATH_QUIZ_SINGLE_ANSWER = "UPDATE_MATH_QUIZ_SINGLE_ANSWER";
export const MATH_CONTEST_MASS_RESET_STATE = "MATH_CONTEST_MASS_RESET_STATE";
export const MATH_CONTEST_CALCULATE_RESULTS = "MATH_CONTEST_CALCULATE_RESULTS";
export const MATH_CONTEST_CALCULATE_RESULTS_SUCCESS = "MATH_CONTEST_CALCULATE_RESULTS_SUCCESS";
export const GET_MATH_CONTEST_RESULTS_LITERATURE = "GET_MATH_CONTEST_RESULTS_LITERATURE";
export const GET_MATH_CONTEST_RESULTS_LITERATURE_SUCCESS = "GET_MATH_CONTEST_RESULTS_LITERATURE_SUCCESS";
export const GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON = "GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON";
export const GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON_SUCCESS = "GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON_SUCCESS";

export function getPageContent(activePage, itemsPerPage, filterModel=null) {
  return {
    type: GET_CONTENT_CONTEST_LIST,
    quizType: "mathQuizList",
    activePage,
    itemsPerPage,
    filterModel
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_CONTEST_LIST_SUCCESS,
    content
  }
}

export function getModalContent() {
  return {
    type: GET_CONTENT_CONTEST_INFO,
    infoType: 'info',
    modalQuizType: 'modalMathQuiz'
  }
}

export function getModalContentSuccess(info) {
  return {
    type: GET_CONTENT_CONTEST_INFO_SUCCESS,
    info
  }
}

export function getPageContentFilters() {
  return {
    type: GET_CONTENT_CONTEST_FILTERS
  }
}

export function getPageContentFiltersSuccess(filters) {
  return {
    type: GET_CONTENT_CONTEST_FILTERS_SUCCESS,
    filters
  }
}

export function getCurrentContestInfo(id) {
  return {
    type:  GET_CONTENT_CURRENT_CONTEST_INFO,
    id,
    contestType: "contestInfo"
  }
}

export function getCurrentContestInfoSuccess(contestInfo) {
  return {
    type:  GET_CONTENT_CURRENT_CONTEST_INFO_SUCCESS,
    contestInfo
  }
}

export function getCurrentContestExercise(id, pageNumber) {
  return {
    type: GET_CONTENT_CURRENT_CONTEST_EXERCISE,
    id,
    pageNumber,
    contestType: 'contestExercise'
  }
}

export function getCurrentContestExerciseSuccess(info) {
  return {
    type: GET_CONTENT_CURRENT_CONTEST_EXERCISE_SUCCESS,
    info
  }
}

export function getCurrentContestExerciseFailure(error) {
  return {
    type: GET_CONTENT_CURRENT_CONTEST_EXERCISE_FAILURE,
    error
  }
}

export function updateMathQuizSingleAnswer(quizAnswerData) {
  return {
    type: UPDATE_MATH_QUIZ_SINGLE_ANSWER,
    quizAnswerData
  }
}

export function mathContestMassResetState() {
  return {
    type: MATH_CONTEST_MASS_RESET_STATE
  }
}

export function mathContestCalculateResults(clientAnswers, contestId) {
  return {
    type: MATH_CONTEST_CALCULATE_RESULTS,
    clientAnswers,
    contestId,
    pageType: 'calculateResult'
  }
}

export function mathContestCalculateResultsSuccess(calculatedAnswerResults) {
  return {
    type: MATH_CONTEST_CALCULATE_RESULTS_SUCCESS,
    calculatedAnswerResults
  }
}

export function getMathResultsLiteratureContent(contestId) {
  return {
    type: GET_MATH_CONTEST_RESULTS_LITERATURE,
    contestId,
    modalType: 'modalResults'
  }
}

export function getMathResultsLiteratureContentSuccess(contestLiterature) {
  return {
    type: GET_MATH_CONTEST_RESULTS_LITERATURE_SUCCESS,
    contestLiterature
  }
}

export function getMathResultsAnswerComparison(contestId, questionId, exerciseNumber) {
  return {
    type: GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON,
    contestId,
    questionId,
    exerciseNumber,
    modalType: 'modalResults'
  }
}

export function getMathResultsAnswerComparisonSuccess(answerComparison) {
  return {
    type: GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON_SUCCESS,
    answerComparison
  }
}