import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  categories: ["Всички"],
  questions: [],
  loaded: false
}

const getContentSuccess = (state, action) => {

  action.content.categories.splice(0, 0, state.categories[0]);

  return updateObject(state, {
    categories: action.content.categories,
    questions: action.content.questions,
    loaded: action.content.loaded
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_FAQ_PAGE_SUCCESS: return getContentSuccess(state, action);
    default: return state;
  }
}

export default reducer;