export const DELIVER_AUTHED_DATA = "DELIVER_AUTHED_DATA";
export const DELIVER_AUTHED_REGISTER_AUTHORIZE = "DELIVER_AUTHED_REGISTER_AUTHORIZE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const REFRESH_LOGOUT = "REFRESH_LOGOUT";
export const AUTH_REFRESH = "AUTH_REFRESH";
export const AUTH_REFRESH_SIGNIN = "AUTH_REFRESH_SIGNIN";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOGOUT_RESET = "AUTH_LOGOUT_RESET";
export const AUTH_MODAL_STATE = "AUTH_MODAL_STATE";
export const FORGOTTEN_PASSWORD_STATE = "FORGOTTEN_PASSWORD_STATE";
export const AUTHORIZE_CODE = "AUTHORIZE_CODE";
export const REGISTER_AUTHORIZE = "REGISTER_AUTHORIZE";
export const AUTHORIZE_CODE_RESET = "AUTHORIZE_CODE_RESET";
export const AUTHORIZE_NEW_PASSWORD_TEMPLATE_RESET = "AUTHORIZE_NEW_PASSWORD_TEMPLATE_RESET";
export const AUTHORIZE_REGISTER_TEMPLATE_RESET = "AUTHORIZE_REGISTER_TEMPLATE_RESET";

export const AUTH_REFRESH_TOKEN_IN_PROGRESS = "AUTH_REFRESH_TOKEN_IN_PROGRESS";

export function deliverAuthedData(authedData, authedType) {
  return {
    type: DELIVER_AUTHED_DATA,
    authedData,
    authedType
  };
}

export const authSuccess = (authData) => {
  return {
    type: AUTH_SUCCESS,
    authData
  };
}

export const authRefresh = (Switcher, data) => {
  return {
    type: AUTH_REFRESH,
    authedType: "refresh",
    authedTypeService: "authorizationAccessToken",
    componentAccessSwitcher: Switcher,
    data
  };
}
export const authRefreshSignIn = (refreshedData) => {
  return {
    type: AUTH_REFRESH_SIGNIN,
    authData: refreshedData

  };
}

export function authModalState(typeState) {
  return {
    type: AUTH_MODAL_STATE,
    authModalState: typeState
  }
}

export function forgottenPasswordState(stateValue) {
  return {
    type: FORGOTTEN_PASSWORD_STATE,
    isForgottenPasswordOpen: stateValue
  };
}

export function authorizeCode(code, authType) {
  return {
    type: AUTHORIZE_CODE,
    authCode: code,
    authType: authType,
    authHtmlTemplate: true
  };
}

export function authorizeCodeReset() {
  return {
    type: AUTHORIZE_CODE_RESET,
    authCode: ''
  };
}

export function authorizeNewPasswordTemplateReset() {
  return {
    type: AUTHORIZE_NEW_PASSWORD_TEMPLATE_RESET,
    authHtmlTemplate: false
  };
}

export function authorizeRegisterTemplateReset() {
  return {
    type: AUTHORIZE_REGISTER_TEMPLATE_RESET,
    authHtmlTemplate: false
  };
}

export function deliverAuthedRegisterAuthorize(code) {
  return {
    type: DELIVER_AUTHED_REGISTER_AUTHORIZE,
    authedType: "registerConfirm",
    code: code
  };
}

export function logout(history) {
  return {
    type: AUTH_LOGOUT,
    authedType: "logout",
    authedTypeService: "authorizationAccessToken",
    history
  };
}
export function refreshLogout(history) {
  return {
    type: REFRESH_LOGOUT,
    authedType: "refresh",
    authedTypeService: "authorizationAccessToken",
    history
  };
}

export function logoutReset() {
  return {
    type: AUTH_LOGOUT_RESET
  };
}

export const authRefreshInProgressFlag = (flag) => {
  return {
    type: AUTH_REFRESH_TOKEN_IN_PROGRESS,
    refreshingToken: flag
  };
}