export const GET_CONTENT_SUBSCRIPTIONS = 'GET_CONTENT_SUBSCRIPTIONS';
export const GET_CONTENT_SUBSCRIPTIONS_SUCCESS = 'GET_CONTENT_SUBSCRIPTIONS_SUCCESS'

export function getPageContent() {
  return {
    type: GET_CONTENT_SUBSCRIPTIONS,
    typeSubscription: 'subscriptions',
    typeSubscriptionsGrid: 'subscriptionsGridV2',
    typeSubscriptionsPage: 'subscriptionsPage'
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_SUBSCRIPTIONS_SUCCESS,
    content
  }
}