import * as actionTypes from './actions';
import { updateObject } from './../../hoc/update-object/update-object';

const initialState = {
  name: '',
  body: '' ,
  image:'',
  loaded: false
}

const getContentSuccess = (state, action) => {

  return updateObject(state, {
    name: action.content.name,
    body: action.content.body,
    image: action.content.image,
    loaded: action.content.loaded
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_GENERAL_TERMS_SUCCESS: return getContentSuccess(state, action);
    default: return state;
  }
}

export default reducer;