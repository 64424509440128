import { call, put, select } from "redux-saga/effects";
import { fetchingData } from '../../utils/services/fetching_data';

import * as actionsCreatorAddSubscription from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";
import * as actionsIntroCompetitions from "../intro_competitions/actions";

export function* subscriptions(action) {

  const state = yield select();
  yield put(actionsCreatorLoading.loadingStart(action.subscriptionType));

  const url = `/API/subscriptions/${action.subscriptionAuthorize}`;

  const authedData = {
    userId: state.authedUser.userId
  }

  const response = yield call(fetchingData, url, authedData, 'POST', action.authedTypeService, state.authedUser.token);

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.subscriptionType));

    } else {
      const data = response.body.data;

      yield put(actionsCreatorLoading.loadingStop(action.subscriptionType));
      yield put(actionsCreatorAddSubscription.getSubscriptionsData(data));

    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.subscriptionType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.subscriptionType,
      htmlTemplate: `${action.subscriptionType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}

export function* deliverySubscriptionPayment(action) {

  const state = yield select();
  yield put(actionsCreatorLoading.loadingStart(action.subscriptionType));

  const url = `/API/subscriptions/${action.subscriptionAuthorize}`;

  let authedData = {
    ...action.data
  }

  const response = yield call(fetchingData, url, authedData, 'POST', action.authedTypeService, state.authedUser.token);

  try {
    yield put(actionsCreatorLoading.loadingStop(action.subscriptionType));

    if (!response.ok) {

    } else {

      const data = response.body.data;
      const newData = {
        type: data.paymentType,
        successPayment: data.SuccessPaymentMsg,
        bic: data.SuccessPaymentBody.subscriptionBic,
        company: data.SuccessPaymentBody.subscriptionFirm,
        IBAN: data.SuccessPaymentBody.subscriptionIban,
        childId: data.SuccessPaymentBody.subscriptionChildUid,
      }
      if (data.paymentType === 'epay') {

        const dataEpay = {
          action: data.epayForm.action,
          method: data.epayForm.method,
          redirect: data.epayForm.redirectTimeOut,
          ...data.epayForm.formInputs
        }
        yield put(actionsCreatorAddSubscription.getSubscriptionPayment(newData, dataEpay));

      } else {

        yield put(actionsCreatorAddSubscription.getSubscriptionPayment(newData, {}));
      }

      yield put(actionsCreatorNotify.notifyMessages({
        type: action.subscriptionType,
        htmlTemplate: `${action.subscriptionType}Success`,
        active: true,
        closeNotify: false,
        messageError: '',
        messageHeading: `<p class="">${data.SuccessTitle}</p>
        <p class="subscription-payment-title">${data.SuccessSubTitle}</p>`,
        messageBody: `
        <div class="subscription-success-payment">
          <img src="${data.SuccessPaymentBody.subscriptionImage}" class="subscription-payment-img" alt="payment image"/>
          <p class="selected-subscription">${data.SuccessPaymentBody.subscriptionTitle}</p>
          <p class="subscription-description">
            <span class="subscription-month"><i class="far fa-calendar-alt"></i> ${data.SuccessPaymentBody.isCompetition ? data.SuccessPaymentBody.subscriptionPeriod : `${data.SuccessPaymentBody.subscriptionPeriod} месец/а`}</span>
            <span class="subscription-sum"><i class="fas fa-coins"></i> ${data.SuccessPaymentBody.subscriptionPrice} лв.</span>
          </p>
        </div>`
      }));

      if (state.introCompetitions.showButtonFlag) {
        yield put(actionsIntroCompetitions.introCompetitionsInit());
      }
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.subscriptionType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.subscriptionType,
      htmlTemplate: `${action.subscriptionType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}

export function* deliverySubscriptionPromoCode(action) {

  const state = yield select();
  yield put(actionsCreatorLoading.loadingStart(action.subscriptionLoading));

  const url = `/API/subscriptions/${action.subscriptionType}`;

  let authedData = {
    ...action.data
  }

  const response = yield call(fetchingData, url, authedData, 'POST', action.authedTypeService, state.authedUser.token);

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.subscriptionLoading));

      const dataError = response.body.error;

      if (state.addSubscription.isDiscountSuccessLoaded) {
        yield put(actionsCreatorAddSubscription.isDiscountLoadedSuccess(false));
      }

      yield put(actionsCreatorNotify.notifyMessages({
        type: action.subscriptionType,
        htmlTemplate: `${action.subscriptionType}Error`,
        active: true,
        closeNotify: false,
        messageError: dataError,
        messageHeading: '',
        messageBody: ''
      }));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.subscriptionLoading));

      const data = response.body.data;
      const newData = {
        code: data.code,
        percent: Number(data.percent)
      }

      yield put(actionsCreatorAddSubscription.getSubscriptionDiscount(newData));
      yield put(actionsCreatorNotify.notifyMessages({
        type: action.subscriptionType,
        htmlTemplate: `${action.subscriptionType}Success`,
        active: true,
        closeNotify: false,
        messageError: '',
        messageHeading: '',
        messageBody: `<span class="notify-success-promocode">Успешно въведен код: - ${data.code}</span>`
      }));

      yield put(actionsCreatorAddSubscription.isDiscountLoadedSuccess(true));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.subscriptionLoading));
    if (state.addSubscription.isDiscountSuccessLoaded) {
      yield put(actionsCreatorAddSubscription.isDiscountLoadedSuccess(false));
    }

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.subscriptionType,
      htmlTemplate: `${action.subscriptionType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }
}
export function* subscriptionPaymentNotify(action) {

  const state = yield select();
  yield put(actionsCreatorLoading.loadingStart(action.paymentType));

  const url = `/API/subscriptions/${action.paymentService}`;

  let authedData = {
    userId: state.authedUser.userId,
    paymentId: action.paymentId,
    status: action.paymentStatus
  }

  const response = yield call(fetchingData, url, authedData, 'POST', action.authedTypeService, action.token);

  try {

    if (!response.ok) {
      const data = response.body.error;

      yield put(actionsCreatorLoading.loadingStop(action.paymentType));
      yield put(actionsCreatorNotify.notifyMessages({
        type: action.paymentType,
        htmlTemplate: `${action.paymentType}Error`,
        active: true,
        closeNotify: false,
        messageError: `Възникна грешка! <br /><br /> ${data}`,
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.paymentType));
      yield put(actionsCreatorAddSubscription.paymentStatus(action.paymentStatus));

      const data = response.body.data;
      let articleDate = null;

      if (data.paymentDate) {
        const date = new Date(data.paymentDate * 1000);
        // const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCHours(), date.getUTCMinutes()));
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };

        articleDate = date.toLocaleDateString("bg-BG", dateOptions);
      }

      yield put(actionsCreatorNotify.notifyMessages({
        type: action.paymentType,
        htmlTemplate: `${action.paymentType}Success`,
        active: true,
        closeNotify: false,
        messageError: '',
        messageHeading: data.title,
        messageBody: `
        <div class="subscription-success-payment">
          <p class="selected-subscription">${data.status}</p>
          <p class="subscription-description">
            <span class="subscription-month"><i class="far fa-calendar-alt"></i>${articleDate} часа.</span>
            <span class="subscription-sum"><i class="fas fa-coins"></i> ${data.price} лв.</span>
          </p>
        </div>`
      }));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.paymentType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.paymentType,
      htmlTemplate: `${action.paymentType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }
}