export const GET_CONTENT_GENERAL_TERMS = 'GET_CONTENT_GENERAL_TERMS';
export const GET_CONTENT_GENERAL_TERMS_SUCCESS = 'GET_CONTENT_GENERAL_TERMS_SUCCESS'

export function getPageContent() {
  return {
    type: GET_CONTENT_GENERAL_TERMS,
    pageType: 'generalTerms'
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_GENERAL_TERMS_SUCCESS,
    content
  }
}