export const MODAL_USERNAME_REGISTTERED = "MODAL_USERNAME_REGISTTERED";
export const MODAL_SUBSCRIPTION_PAID = "MODAL_SUBSCRIPTION_PAID";

export function modalUserNameRegistered(usernameRegisterState) {
  return {
    type: MODAL_USERNAME_REGISTTERED,
    usernameRegisterState
  }
}

export function modalSubscriptionPaid(subscriptionPaidState) {
  return {
    type: MODAL_SUBSCRIPTION_PAID,
    subscriptionPaidState
  }
}