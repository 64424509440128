import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import "./css/style.scss";

import MainNav from "./inner_components/MainNav";
import AccountStats from "./../account_stats/";
import PlatformSvg from "./../shared/ui/svg/platform_svg";
import Logo from "./../shared/ui/logo";
import {
  debounced,
  checkAccessTokenExpirationTime,
} from "./../../utils/helpers/common";

import * as actionsAccountStats from "./../account_stats/actions";
import * as actionsCreatorAuth from "../../store/root_actions/auth";
import * as actions from "./actions";
import CookieConsent from "react-cookie-consent";

class HeaderBar extends Component {
  static propTypes = {
    accessTokenExprirationTime: PropTypes.number.isRequired,
    signIn: PropTypes.bool.isRequired,
    accountStats: PropTypes.bool.isRequired,
    authName: PropTypes.string.isRequired,
    hotStrips: PropTypes.object.isRequired,
  };

  state = {
    menuVisible: false,
  };

  componentDidMount() {
    if (!this.props.hotStrips.loaded) {
      this.props.getHotStrips();
    }
    if (document.body.clientWidth > 1200) {
      document.addEventListener("mouseup", this.accountDetailsCloseHandler);
    } else {
      document.addEventListener("touchstart", this.accountDetailsCloseHandler);
      document.addEventListener("mouseup", this.menuCloseHandler);
      document.addEventListener("touchstart", this.menuCloseHandler);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.accountDetailsCloseHandler);
    document.removeEventListener("touchstart", this.accountDetailsCloseHandler);
    document.removeEventListener("mouseup", this.menuCloseHandler);
    document.removeEventListener("touchstart", this.menuCloseHandler);
  }

  menuCloseHandler = (e) => {
    let path = e.path || (e.composedPath && e.composedPath());
    const menuOpen = document.querySelector(".menu");
    const menuButton = document.querySelector(".nav-link.nav-caller");

    if (!path.includes(menuOpen)) {
      this.state.menuVisible &&
        !path.includes(menuButton) &&
        this.setState({ menuVisible: false });
    }
  };

  accountDetailsCloseHandler = (e) => {
    const accountDetails = document.querySelector(".account-details");
    const accountButton = document.querySelector(".nav-link.visible-desktop");
    const mobileButton = document.querySelector(".nav-link.user-caller");

    let path = e.path || (e.composedPath && e.composedPath());

    if (path.includes(accountButton) || path.includes(mobileButton)) {
      this.props.toggleAccountStatus(!this.props.accountStats);
    } else {
      this.props.accountStats &&
        !path.includes(accountDetails) &&
        this.props.toggleAccountStatus(false);
    }
  };

  handleAuthModal = () => {
    this.props.authModalState(true);
  };

  handleAccountStats = (e) => {
    if (this.state.menuVisible) {
      this.setState({
        menuVisible: !this.state.menuVisible,
      });
    }

    const isAccessTokenExpired = checkAccessTokenExpirationTime(
      this.props.accessTokenExprirationTime
    );

    if (isAccessTokenExpired && !this.props.accountStats) {
      this.props.authRefresh("mainHeader", null);
    } else {
      this.props.checkAccountStatsInfo();
    }
  };

  handleNavMenu = () => {
    if (this.props.accountStats) {
      this.props.toggleAccountStatus(!this.props.accountStats);
    }
    this.setState({
      menuVisible: !this.state.menuVisible,
    });
  };

  displayHotStrips = (data) => {
    let allCookiesKeys = document.cookie
      .split(";")
      .map((x) => x.split("=")[0].trim())
      .flat();
    let filteredHotStrips = data.filter(
      (x) => !allCookiesKeys.includes(x.hash) && x.title.trim() !== "NULL"
    );
    return filteredHotStrips.map((hotStrip, index) => (
      <CookieConsent
        key={hotStrip.hash}
        location="none"
        buttonText="X"
        cookieName={hotStrip.hash}
        style={{
          background: "#015286",
          color: "#fff",
          fontWeight: "700",
          left: 0,
          borderTop: "1px solid #ffc534",
          zIndex: 1,
          paddingTop: "2px",
          position: "relative",
        }}
        buttonStyle={{
          color: "#ffc534",
          fontSize: "14px",
          background: "#015286",
        }}
        expires={30}
        onAccept={() => {
          let strippedHotStrips = filteredHotStrips.splice(
            filteredHotStrips.findIndex((x) => x.hash === hotStrip.hash),
            1
          );
          this.displayHotStrips(strippedHotStrips);
          this.forceUpdate();
        }}
      >
        <a
          style={{
            color: "#ffffff",
            textDecoration: "none",
          }}
          href={hotStrip.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {hotStrip.title}
        </a>{" "}
        <a
          style={{
            color: "#ffc534",
            textDecoration: "underline",
            fontSize: "20px",
          }}
          href={hotStrip.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          . . .
        </a>
      </CookieConsent>
    ));
  };

  render() {
    const { menuVisible } = this.state;
    const { signIn, authName, accountStats, location, hotStrips } = this.props;

    let platformWasLoaded = !location.pathname.includes("platform");

    return platformWasLoaded ? (
      <div className="header-wrapper">
        <header className="grid-container header">
          <div className="grid-item header-logo">
            <NavLink exact to="/">
              <Logo />
            </NavLink>
          </div>
          <NavLink
            className="nav-link nav-cta visible-mobile grid-item"
            activeClassName="selected"
            to="/platform/intro"
          >
            <PlatformSvg />
          </NavLink>

          <div className="visible-mobile icons-holder grid-item">
            <button
              className="nav-link user-caller"
              onClick={
                signIn
                  ? debounced(500, this.handleAccountStats)
                  : debounced(500, this.handleAuthModal)
              }
            >
              <i className="fas fa-user"></i>
            </button>

            <button
              className="nav-link nav-caller"
              onClick={debounced(500, this.handleNavMenu)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          <div className="visible-desktop grid-item main-nav-wrap">
            <MainNav
              authModalState={this.handleAuthModal}
              accountStats={this.handleAccountStats}
              signIn={signIn}
              navMenu={this.handleNavMenu}
              menuVisible={this.state.menuVisible}
              authName={authName}
            />
          </div>
        </header>

        {menuVisible && (
          <div className="visible-mobile menu">
            <MainNav
              authModalState={this.handleAuthModal}
              accountStats={this.handleAccountStats}
              signIn={signIn}
              navMenu={this.handleNavMenu}
              menuVisible={this.state.menuVisible}
              authName={authName}
            />
          </div>
        )}

        {Boolean(authName) && accountStats && <AccountStats />}

        <div
          style={{
            background: "#015286",
            position: "absolute",
            left: "0",
            right: "0",
          }}
        >
          {this.displayHotStrips(hotStrips.data)}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ authedUser, accountStats, headerBar }) => {
  return {
    signIn: authedUser.signIn,
    authName: authedUser.name,
    accessTokenExprirationTime: authedUser.accessTokenExprirationTime,
    accountStats: accountStats.accountStats,
    accountStatsContent: accountStats.accountStatsContent,
    hotStrips: headerBar.hotStrips,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      ...actionsAccountStats,
      ...actionsCreatorAuth,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar);
