export const GET_CONTENT_ABOUT_US = 'GET_CONTENT_ABOUT_US';
export const GET_CONTENT_ABOUT_US_SUCCESS = 'GET_CONTENT_ABOUT_US_SUCCESS'

export function getPageContent() {
  return {
    type: GET_CONTENT_ABOUT_US,
    pageType: 'aboutUs'
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_ABOUT_US_SUCCESS,
    content
  }
}