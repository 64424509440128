export const SUBSCRIPTIONS_INIT = 'SUBSCRIPTIONS_INIT';

export const ADD_SUBSCRIPTION_MODAL_STATE = 'ADD_SUBSCRIPTION_MODAL_STATE';
export const GET_SUBSCRIPTIONS_DATA = 'GET_SUBSCRIPTIONS_DATA';
export const DELIVERY_SUBSCRIPTION_PAYMENT = 'DELIVERY_SUBSCRIPTION_PAYMENT';
export const GET_SUBSCRIPTION_PAYMENT = 'GET_SUBSCRIPTION_PAYMENT';
export const RESET_ADD_SUBSCRIPTIONS = 'RESET_ADD_SUBSCRIPTIONS';
export const DELIVERY_SUBSCRIPTION_PROMO_CODE = 'DELIVERY_SUBSCRIPTION_PROMO_CODE';
export const GET_SUBSCRIPTION_DISCOUNT = 'GET_SUBSCRIPTION_DISCOUNT';
export const IS_DISCOUNT_LOADED_SUCCESS = 'IS_DISCOUNT_LOADED_SUCCESS';
export const SUBSCRIPTION_DISCOUNT_RESET = 'SUBSCRIPTION_DISCOUNT_RESET';
export const SUCCESSFULLY_PAYMENT_MODAL_STATE = 'SUCCESSFULLY_PAYMENT_MODAL_STATE';
export const DELIVERY_PAYMENT_DATA = 'DELIVERY_PAYMENT_DATA';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';

export const addSubscriptionModalState = (modalState) => {
  return {
    type: ADD_SUBSCRIPTION_MODAL_STATE,
    modalState
  }
}

export const subscriptionsInit = () => {
  return {
    type: SUBSCRIPTIONS_INIT,
    subscriptionType: 'subscriptions',
    subscriptionAuthorize: 'subscriptionForm',
    authedTypeService: "authorizationAccessToken"
  }
}

export const deliverySubscriptionPayment = (data) => {
  return {
    type: DELIVERY_SUBSCRIPTION_PAYMENT,
    subscriptionType: 'subscriptions',
    subscriptionAuthorize: 'subscriptionFormPayment',
    authedTypeService: "authorizationAccessToken",
    data
  }
}

export const deliverySubscriptionPromoCode = (data) => {
  return {
    type: DELIVERY_SUBSCRIPTION_PROMO_CODE,
    subscriptionType: 'subscriptionDiscount',
    authedTypeService: "authorizationAccessToken",
    subscriptionLoading: 'subscriptions',
    data
  }
}

export const getSubscriptionsData = (data) => {
  return {
    type: GET_SUBSCRIPTIONS_DATA,
    data
  }
}

export const getSubscriptionDiscount = (data) => {
  return {
    type: GET_SUBSCRIPTION_DISCOUNT,
    data
  }
}

export const isDiscountLoadedSuccess = (successState) => {
  return {
    type: IS_DISCOUNT_LOADED_SUCCESS,
    successState
  }
}
export const subscriptionDiscountReset = () => {
  return {
    type: SUBSCRIPTION_DISCOUNT_RESET,
    promoCode: '',
    percentPrice: 0
  }
}

export const getSubscriptionPayment = (dataPayment, dataEpay) => {
  return {
    type: GET_SUBSCRIPTION_PAYMENT,
    dataPayment,
    dataEpay
  }
}

export const resetAddSubscriptions = () => {
  return {
    type: RESET_ADD_SUBSCRIPTIONS
  }
}
export const successFullyPaymentModalState = (modalState) => {
  return {
    type: SUCCESSFULLY_PAYMENT_MODAL_STATE,
    modalState
  }
}
export const paymentStatus = (status) => {
  return {
    type: PAYMENT_STATUS,
    status
  }
}
export const deliveryPaymentData = (paymentId, paymentStatus, token) => {
  return {
    type: DELIVERY_PAYMENT_DATA,
    paymentService: 'subscriptionEpayCheck',
    paymentType: 'paymentSubscription',
    authedTypeService: "authorizationAccessToken",
    paymentId,
    paymentStatus,
    token
  }
}