import * as actionTypes from "./actions";
import { updateObject } from "../../hoc/update-object/update-object";

const initialState = {
  hotStrips: {
    loaded: false,
    data: [],
  },
};

const getHotStripsSuccess = (state, action) => {
  return updateObject(state, {
    hotStrips: action.hotStrips,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOT_STRIPS_SUCCESS:
      return getHotStripsSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
