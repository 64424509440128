export const loadingInitialState = {
  loginLoading: false,
  registerLoading: false,
  forgottenPasswordLoading: false,
  authorizeNewPasswordLoading: false,
  logoutLoading: false,
  contactLoading: false,
  contactInfoLoading: false,
  categoriesNewsLoading: false,
  newsLoading: false,
  newsArticleLoading: false,
  subscriptionsLoading: false,
  accountInfoLoading: false,
  videoQuizLoading: false,
  modalVideoInfoLoading: false,
  videoListLoading: false,
  partialSubscriptionsLoading: false,
  subscriptionsPageLoading: false,
  missionLoading: false,
  exercisesLoading: false,
  aboutUsLoading: false,
  mathQuizListLoading: false,
  modalMathQuizLoading: false,
  contestExerciseLoading: false,
  paymentSubscriptionLoading: false,
  contestInfoLoading: false,
  authorLoading: false,
  generalTermsLoading: false,
  privateDataLoading: false,
  modalResultsLoading: false,
  calculateResultLoading: false,
  faqPageLoading: false,
  uploadLoading: false
};