export const ACCOUNT_STATS_INFO = 'ACCOUNT_STATS_INFO';
export const ACCOUNT_STATS_DELIVER = 'ACCOUNT_STATS_DELIVER';
export const TOGGLE_ACCOUNT_STATUS = 'TOGGLE_ACCOUNT_STATUS';

export function toggleAccountStatus(acccountState) {
  return {
    type: TOGGLE_ACCOUNT_STATUS,
    accountStats: acccountState

  }
}

export function checkAccountStatsInfo() {
  return {
    type: ACCOUNT_STATS_INFO,
    accountType: 'accountInfo',
    authedTypeService: "authorizationAccessToken"
  }
}

export function deliverAccountStats(payload) {
  return {
    type: ACCOUNT_STATS_DELIVER,
    accountStatsContent: payload
  }
}