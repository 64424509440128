import { call, put, select } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorMathPlatform from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotifications from "../../store/root_actions/notifications";

export function* getPageContentContestList(action) {

  yield put(actionsCreatorLoading.loadingStart(action.quizType));

  try {

    const filterModel = action.filterModel !== null ? action.filterModel : "";

    const url = '/API/contests/list?count=' + action.itemsPerPage + '&page=' + action.activePage + filterModel;
    const response = yield call(fetchingData, url, null, 'GET');

    if (!response.ok) {

      yield put(actionsCreatorLoading.loadingStop(action.quizType));
    } else {

      yield put(actionsCreatorLoading.loadingStop(action.quizType));
      yield put(actionsCreatorMathPlatform.getPageContentSuccess(response.body.data));
    }
  }
  catch(error) {

    yield put(actionsCreatorLoading.loadingStop(action.quizType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.quizType,
      htmlTemplate: `${action.quizType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* getPageContentContestInfo(action) {

  yield put(actionsCreatorLoading.loadingStart(action.modalQuizType));

  try {

    const url = `/API/contests/${action.infoType}`;
    const response = yield call(fetchingData, url, null, 'GET');

    if (!response.ok) {

      yield put(actionsCreatorLoading.loadingStop(action.modalQuizType));
    } else {

      yield put(actionsCreatorLoading.loadingStop(action.modalQuizType));
      yield put(actionsCreatorMathPlatform.getModalContentSuccess(
        {
          loaded: false,
          ...response.body.data
        }
      ));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.modalQuizType));

    console.log(error);

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.modalQuizType,
      htmlTemplate: `${action.modalQuizType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* getPageContentContestFilters() {

  try {

    const url = '/API/contests/filters';
    const response = yield call(fetchingData, url, null, 'GET');

    if (!response.ok) {

      console.log(response.error);
    } else {

      yield put(actionsCreatorMathPlatform.getPageContentFiltersSuccess(
        {
          loaded: true,
          ...response.body.data
        }
      ));
    }
  }
  catch(error) {
    console.log(error);
  }

}

export function* getCurrentContestInfo(action) {

  yield put(actionsCreatorLoading.loadingStart(action.contestType));

  const url = '/API/contests/' + action.id;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.contestType));

    } else {

      yield put(actionsCreatorLoading.loadingStop(action.contestType));
      yield put(actionsCreatorMathPlatform.getCurrentContestInfoSuccess(
        {
          ...response.body
        }
        ));
      }
    }
    catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.contestType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.contestType,
      htmlTemplate: `${action.contestType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }))
  }

}

export function* getCurrentContestExerciseInfo(action) {

  const state = yield select(state => state.mathPlatform);

  yield put(actionsCreatorLoading.loadingStart(action.contestType));
  try {

    const url =  `/API/contests/${action.id}/exercises?number=${action.pageNumber}`;
    let response = yield call(fetchingData, url, null, 'GET');

    if (!response.ok) {

      yield put(actionsCreatorLoading.loadingStop(action.contestType));
      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.contestType,
        htmlTemplate: `${action.contestType}Error`,
        active: true,
        closeNotify: false,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    } else {



      yield put(actionsCreatorLoading.loadingStop(action.contestType));

      if (response.body.data.exercise.length > 0) {
        if (response.body.data.exercise[0].no_answer === "1" && state.exerciseAnswersStatus.length > 0){
          yield put(actionsCreatorMathPlatform.updateMathQuizSingleAnswer(
            {
              pageNum: action.pageNumber - 1,
              exerciseId: response.body.data.exercise[0].id,
              answer: [{ no_answer: "1", answerId: "none" }]
            }
          ));
        }
      }
      
      yield put(actionsCreatorMathPlatform.getCurrentContestExerciseSuccess(
        {
          ...response.body.data
        }
      ));

    }
  } catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.contestType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.contestType,
      htmlTemplate: `${action.contestType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* mathContestCalculateResults(action) {

  yield put(actionsCreatorLoading.loadingStart(action.pageType));

  const url = `/API/contests/${action.contestId}/${action.pageType}`;
  const payload = {
    clientAnswers: action.clientAnswers
  }

  const response = yield call(fetchingData, url, payload, 'POST');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.pageType));
    } else {

      yield put(actionsCreatorLoading.loadingStop(action.pageType));
      yield put(actionsCreatorMathPlatform.mathContestCalculateResultsSuccess({
        ...response.body.data
      }));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.pageType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.pageType,
      htmlTemplate: `${action.pageType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* getMathContestResultsLiterature(action) {

  yield put(actionsCreatorLoading.loadingStart(action.modalType));

  const url = `/API/contests/${action.contestId}/literature`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.modalType));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.modalType));
      yield put(actionsCreatorMathPlatform.getMathResultsLiteratureContentSuccess({
        ...response.body,
        loaded: true
      }));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.modalType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.modalType,
      htmlTemplate: `${action.modalType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* getMathContestResultsAnswerComparison(action) {
  yield put(actionsCreatorLoading.loadingStart(action.modalType));

  const url = `/API/contests/${action.contestId}/${action.questionId}/answer`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.modalType));
    } else {

      yield put(actionsCreatorLoading.loadingStop(action.modalType));
      yield put(actionsCreatorMathPlatform.getMathResultsAnswerComparisonSuccess({
        ...response.body,
        exerciseNumber: action.exerciseNumber
      }));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.modalType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.modalType,
      htmlTemplate: `${action.modalType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}