import * as actionTypes from  './actions';
import { updateObject } from '../../../../hoc/update-object/update-object';

const initialState = {
  isUserNotRegistered: false,
  isSubscriptionNotPaid: false
};

const modalUserNameRegistered = (state, action) => {
  return updateObject(state, {
    isUserNotRegistered: action.usernameRegisterState
  })
}

const modalSubscriptionPaid = (state, action) => {
  return updateObject(state, {
    isSubscriptionNotPaid: action.subscriptionPaidState
  })
}

const modal = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MODAL_USERNAME_REGISTTERED: return modalUserNameRegistered(state, action);
    case actionTypes.MODAL_SUBSCRIPTION_PAID: return modalSubscriptionPaid(state, action);

    default: return state
  }
}

export default modal;