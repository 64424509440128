export const GET_CONTENT_AUTHOR_PAGE = 'GET_CONTENT_AUTHOR_PAGE';
export const GET_CONTENT_AUTHOR_PAGE_SUCCESS = 'GET_CONTENT_AUTHOR_PAGE_SUCCESS'

export function getPageContent() {
  return {
    type: GET_CONTENT_AUTHOR_PAGE,
    pageType: 'author'
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_AUTHOR_PAGE_SUCCESS,
    content
  }
}