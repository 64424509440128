export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";

export const loadingStart = (typeState) => {
  return {
    type: LOADING_START,
    typeState: typeState,
  };
};

export const loadingStop = (typeState) => {
  return {
    type: LOADING_STOP,
    typeState: typeState
  };
};