const fnConfig = (payload, methodAction, dataType, accessToken, upload) => {
  let headers;

  headers = headerConfig(dataType, accessToken, upload);

  if (methodAction === "POST" && payload !== null) {

    if (upload === 'uploadAuthorize') {

        return {
          method: methodAction,
          body: payload,
          headers: {
            ...headers
          },
        };
    } else {
        return {
          method: methodAction,
          body: JSON.stringify(payload),
          headers: new Headers({
            ...headers
          }),
        };
    }

  } else {
    return {
      method: methodAction,
      headers: new Headers({
        ...headers,
      }),
    };
  }
};

const headerConfig = (dataType, accessTokenAuthorize, upload) => {
  let headers;

  if (dataType === "authorizationAccessToken") {
    let accessToken;

    if (accessTokenAuthorize === undefined) {
      accessToken = JSON.parse(localStorage.getItem("easyMath")).token;

    } else {
      accessToken = accessTokenAuthorize;
    }

    if (upload === 'uploadAuthorize') {
      headers = {
        Authorization: accessToken
      }
    } else {
      headers = {
        Authorization: accessToken,
        "Content-Type": "application/json",
      }
    }

    return headers;

  } else {
    headers = {
      "Content-Type": "application/json"
    }
    return headers;
  }
}

export const fetchingData = async (url, payload, methodAction, dataType, accessTokenAuthorize, uploadAuthorize) => {

  let response = await fetch(url, fnConfig(payload, methodAction, dataType, accessTokenAuthorize, uploadAuthorize))
    .then((res) =>
      res.json().then((body) => {
        return {
          status: res.status,
          ok: res.ok,
          body: body
        };
      })
    )
    .catch((error) => {
      console.log("====================================");
      console.log("Error: ", error);
      console.log("====================================");
    });

  return response;
}
