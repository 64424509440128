import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class InvoiceIndividual extends PureComponent {
  static propTypes = {
    individual: PropTypes.object.isRequired,
    individualUpdate: PropTypes.object.isRequired,
    individualValidate: PropTypes.object.isRequired,
    isFocus: PropTypes.object.isRequired,
    onChangeInfo: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  }

  render() {

    const { individual, isFocus, onChangeInfo, onFocus, onBlur, individualValidate, individualUpdate } = this.props;

    return (
      <div className="invoice-individual">
        <div className="invoice-legal-item invoice-individual-first">
          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-name" name="name" value={individual.name}
              className={`white-autofill form-input input-blue subscription-input ${!individualValidate.name && individualUpdate.name ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Име"
            />
            <label htmlFor="invoice-name" className="form-label form-label-error">
              {!individualValidate.name && individualUpdate.name && individual.name !== '' ? 'Въвеждате забранени и/или мин. брой символа (2)!' : ''}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.name ? 'focus-animate' : ''} ${!individualValidate.name && individualUpdate.name ? 'invalid ' : ''}`}></label>
          </fieldset>
        </div>
        <div className="invoice-legal-item invoice-individual-last">
          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-personalAddress" name="address" value={individual.address}
              className={`white-autofill form-input input-blue subscription-input ${!individualValidate.address && individualUpdate.address ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Адрес"
            />
            <label htmlFor="invoice-personalAddress" className="form-label form-label-error">
              {!individualValidate.address && individualUpdate.address && individual.address !== '' ? 'Моля, въведете валиден адрес и/или мин./макс. брой символа (7/50)!' : ''}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.address ? 'focus-animate' : ''} ${!individualValidate.address && individualUpdate.address ? 'invalid ' : ''}`}></label>
          </fieldset>
          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-personalCity" name="city" value={individual.city}
              className={`white-autofill form-input input-blue subscription-input ${!individualValidate.city && individualUpdate.city ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Град"
            />
            <label htmlFor="invoice-personalCity" className="form-label form-label-error"></label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.city ? 'focus-animate' : ''} ${!individualValidate.city && individualUpdate.city ? 'invalid ' : ''}`}></label>
          </fieldset>
        </div>
      </div>
    )
  }
}

export default InvoiceIndividual;