import React from 'react';

export default function PlatformSvg(props){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="46.631" height="40.284" viewBox="0 0 46.631 40.284" className="platform-svg">
      <g id="Group_1" data-name="Group 1" transform="translate(-106.045 -18.822)">
        <path id="Path_1" data-name="Path 1" d="M152.672,30.841a2.2,2.2,0,0,0-.1-.5h0a2.011,2.011,0,0,0-1.646-1.383l-.021,0-.042-.01H137.336l5.619-8.5a1.045,1.045,0,0,0-1.086-1.6,1.035,1.035,0,0,0-.658.447l-1.071,1.567H107.881l-.031,0a2.109,2.109,0,0,0-.858.307,2.014,2.014,0,0,0-.933,1.474c0,.009,0,.018-.005.027l-.008.041v24.5l0,.029a2.11,2.11,0,0,0,.268.822,2,2,0,0,0,1.568,1.009h0l.022.006.024.006h13.708l-5.572,8.409a1.044,1.044,0,0,0,1.744,1.15l1.025-1.474h31.961a2.088,2.088,0,0,0,.826-.252,2,2,0,0,0,1.045-1.607.2.2,0,0,1,0-.021l.008-.042V30.885Zm-1.684,24.707a.576.576,0,0,1-.356.131H119.81l5.1-7.787v4.59a.732.732,0,0,0,.731.731h4.672a.732.732,0,0,0,.731-.731V47.809a.732.732,0,0,0-.731-.731h-4.672a.727.727,0,0,0-.22.034l2.764-4.217h17.091a.731.731,0,0,0,0-1.462H129.148l.873-1.332H142a.731.731,0,1,0,0-1.462H130.98l2.927-4.465H151.19v1.9q0,9.484,0,18.967a.609.609,0,0,1-.2.51Zm.2-22.857q-8.059,0-16.117,0h-.2l1.486-2.266h14.185a.685.685,0,0,1,.355.069.567.567,0,0,1,.287.509c0,.013,0,.026,0,.039ZM126.377,51.75V48.54h3.21v3.21Zm-18.8-4.5a.5.5,0,0,1-.042-.2c0-.016,0-.033,0-.049v-.017q0-10.447,0-20.894,14.279,0,28.558,0h.623l-14.1,21.507H108.129A.553.553,0,0,1,107.572,47.249ZM137.68,24.606H107.529V22.948a.56.56,0,0,1,.314-.553.465.465,0,0,1,.218-.053h31.1Z"/>
        <path id="Path_2" data-name="Path 2" d="M196.4,180.129a7.058,7.058,0,1,0-7.058-7.058A7.066,7.066,0,0,0,196.4,180.129Zm0-12.654a5.6,5.6,0,1,1-5.6,5.6A5.6,5.6,0,0,1,196.4,167.475Z" transform="translate(-77.158 -136.344)"/>
        <path id="Path_3" data-name="Path 3" d="M253.2,203.89v8.533l6.565-4.267Z" transform="translate(-136.307 -171.429)"/>
        <path id="Path_4" data-name="Path 4" d="M596.492,402.235h-4.672a.732.732,0,0,0-.731.731v4.672a.732.732,0,0,0,.731.731h4.672a.732.732,0,0,0,.731-.731v-4.672A.732.732,0,0,0,596.492,402.235Zm-.731,1.462v3.21h-3.21V403.7Z" transform="translate(-449.298 -355.158)"/>
        <path id="Path_5" data-name="Path 5" d="M476.488,380.2a.726.726,0,0,0-.517.214l-1.409,1.409H470.12a.732.732,0,0,0-.731.731v.014a.735.735,0,0,0-.617.206.731.731,0,0,0,0,1.034l.617.617v2.8a.732.732,0,0,0,.731.731h4.672a.732.732,0,0,0,.731-.731v-4.3l1.482-1.482a.731.731,0,0,0-.517-1.248Zm-4.78,4.478-.857-.857v-.535H473.1Zm2.353-.285v2.1h-3.21v-.607l.34.34a.731.731,0,0,0,1.034,0Z" transform="translate(-335.797 -334.751)"/>
      </g>
    </svg>
  )
}