import * as actionTypes from './actions';
import { updateObject } from './../../hoc/update-object/update-object';

const initialState = {
  video: {
    name: '',
    body: '',
    loaded: false
  },

  contests: {
    name: '',
    body: '',
    loaded: false
  }
}

const getVideoLessons = (state, action) => {
  return updateObject(state, {
    video: action.data
  })
}
const getExamPreparation = (state, action) => {
  return updateObject(state, {
    contests: action.data
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.GET_VIDEO_LESSONS: return getVideoLessons(state, action);
    case actionTypes.GET_EXAM_PREPARATION: return getExamPreparation(state, action);
    default: return state;
  }
}

export default reducer;