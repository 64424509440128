import { call, put, all } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorContactUs from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";

export function* getPageContentSubscriptions(action) {

  yield put(actionsCreatorLoading.loadingStart(action.typeSubscriptionsPage));

  const urlSubscription = `/API/pages/${action.typeSubscription}`;
  const urlPriceList = `/API/partial/${action.typeSubscriptionsGrid}`;

  const { responseSubscription, responsePriceList } = yield all({
    responseSubscription: call(fetchingData, urlSubscription, null, 'GET'),
    responsePriceList: call(fetchingData, urlPriceList, null, 'GET')
  })

  try {

    if (!responseSubscription.ok && !responsePriceList.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.typeSubscriptionsPage));
    } else {
      yield put(actionsCreatorLoading.loadingStop(action.typeSubscriptionsPage));

      const dataSubscription = responseSubscription.body.data.subscriptions;
      const dataPriceList = responsePriceList.body.data;

      const newData = {
        loaded: true,
        pricelist: dataPriceList,
        dataSubscription: dataSubscription.body,
      }

      console.log(newData)
      yield put(actionsCreatorContactUs.getPageContentSuccess(newData));

    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.typeSubscriptionsPage));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.typeSubscriptionsPage,
      htmlTemplate: `${action.typeSubscriptionsPage}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}