import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import PropTypes from "prop-types";

import { checkValidationFields } from "../../utils/helpers/common";
import ForgottenPassword from "./forgotten_password";
import Spinner from "../shared/ui/spinner/spinner";
import Notify from "../shared/ui/notifications/notify_template";

class Login extends PureComponent {
  static propTypes = {
    onChangeInfo: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    onForgottenPasswordHandler: PropTypes.func.isRequired,
    isInputsValid: PropTypes.object.isRequired,
    isUpdate: PropTypes.object.isRequired,
    inputsValue: PropTypes.object.isRequired,
    isForgottenPasswordOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    forgottenPasswordLoading: PropTypes.bool.isRequired,
    notifyMessageError: PropTypes.string.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleFocusOut: PropTypes.func.isRequired,
    isFocus: PropTypes.object.isRequired,
    notifyLogin: PropTypes.object.isRequired,
  };

  render() {
    const {
      onChangeInfo,
      isInputsValid,
      isUpdate,
      onSubmitHandler,
      loading,
      inputsValue,
      notifyMessageError,
      isForgottenPasswordOpen,
      onForgottenPasswordHandler,
      forgottenPasswordLoading,
      handleFocus,
      handleFocusOut,
      isFocus,
      notifyLogin,
    } = this.props;
    const isValid = checkValidationFields(isInputsValid);

    const notifyLoginTemplate = (
      <Notify
        typeGroup={"Auth"}
        type={notifyLogin.type}
        htmlTemplate={notifyLogin.htmlTemplate}
        active={notifyLogin.active}
        closeNotify={notifyLogin.closeNotify}
        messageHeading={notifyLogin.messageHeading}
        messageBody={notifyLogin.messageBody}
        messageError={notifyLogin.messageError}
        classStyle={"notify-white"}
        classPosition={"notify-absolute"}
      />
    );

    return (
      <Router>
        <div className="login-form">
          {loading || forgottenPasswordLoading ? (
            <Spinner classColor={`spinner-white`} />
          ) : null}
          {notifyLogin.htmlTemplate === "loginSuccess" ||
          notifyLogin.htmlTemplate === "loginCatchError"
            ? notifyLoginTemplate
            : null}

          <form className="auth-form">
            <h2 className="auth-title text-login">Вход</h2>
            <p className="auth-text-content text-login">
              За съществуващи потребители, моля влезте в профила си чрез формата
              по-долу.
            </p>

            <fieldset className="form-field-set form-offset">
              <input
                type="text"
                id="login-email"
                name="email"
                value={inputsValue.email}
                className={`white-autofill form-input input-blue ${
                  !isInputsValid.email && isUpdate.email ? "invalid " : ""
                }`}
                title="Моля попълнете полето в правилния формат (без шпация в началото или края)."
                onChange={onChangeInfo}
                onFocus={handleFocus}
                onBlur={handleFocusOut}
                placeholder="E-mail"
              />
              <label
                htmlFor="login-email"
                className="form-label form-label-error"
              ></label>
              <label
                className={`form-label-focus focus-white ${
                  isFocus.email ? "focus-animate" : ""
                }
              ${!isInputsValid.email && isUpdate.email ? "invalid " : ""}`}
              ></label>
            </fieldset>

            <fieldset className="form-field-set form-offset">
              <input
                type="password"
                id="login-password"
                name="password"
                value={inputsValue.password}
                className={`form-input input-blue ${
                  !isInputsValid.password && isUpdate.password ? "invalid " : ""
                }`}
                onChange={onChangeInfo}
                onFocus={handleFocus}
                onBlur={handleFocusOut}
                placeholder="Парола"
              />
              <label
                htmlFor="login-password"
                className="form-label form-label-error"
              >
                {!isInputsValid.password &&
                isUpdate.password &&
                inputsValue.password !== ""
                  ? "Минимален брой сомволи (6)"
                  : ""}
              </label>
              <label
                className={`form-label-focus focus-white ${
                  isFocus.password ? "focus-animate" : ""
                }
              ${
                !isInputsValid.password && isUpdate.password ? "invalid " : ""
              }`}
              ></label>
            </fieldset>

            <fieldset className="form-field-set field-set-btn auth-submit-offset">
              <button
                type="button"
                className="easy-math-button btn-blue btn-form-size"
                value="Enter"
                title="Вход"
                disabled={!isValid}
                onClick={onSubmitHandler}
              >
                Вход
              </button>
              <label className="form-label form-label-error-single">
                {notifyLogin.htmlTemplate === "loginError"
                  ? notifyMessageError
                  : ""}
              </label>
            </fieldset>

            {!isForgottenPasswordOpen ? (
              <fieldset className="form-field-set field-set-btn">
                <Link
                  onClick={onForgottenPasswordHandler}
                  className="form-redirect-link"
                  to=""
                  title="Забравена Парола"
                >
                  Забравена Парола
                </Link>
              </fieldset>
            ) : null}
          </form>

          {isForgottenPasswordOpen ? (
            <ForgottenPassword
              isForgottenPasswordOpen={isForgottenPasswordOpen}
            />
          ) : null}
        </div>
      </Router>
    );
  }
}
const mapStateToProps = ({ loading, notifications, authedUser }) => {
  return {
    loading: loading.loginLoading,
    forgottenPasswordLoading: loading.forgottenPasswordLoading,
    isForgottenPasswordOpen: authedUser.isForgottenPasswordOpen,
    notifyMessageError: notifications.login.messageError,
    notifyLogin: notifications.login,
  };
};

export default connect(mapStateToProps)(Login);
