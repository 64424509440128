import * as actionTypes from '../root_actions/loading';
import { loadingInitialState } from '../../constants/loading';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  ...loadingInitialState
};

const loadingStart = (state, action) => {

  return updateObject(state, {
    [`${action.typeState}Loading`]: true
  })
}

const loadingStop = (state, action) => {
  return updateObject(state, {
    [`${action.typeState}Loading`]: false
  })
}

const authedUser = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.LOADING_START: return loadingStart(state, action)
    case actionTypes.LOADING_STOP: return loadingStop(state, action);

    default: return state
  }
}

export default authedUser;