import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import PropTypes from "prop-types";

import { checkValidationFields } from "../../utils/helpers/common";
import Spinner from "../shared/ui/spinner/spinner";
import Notify from "../shared/ui/notifications/notify_template";

class Register extends PureComponent {
  static propTypes = {
    onChangeInfo: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    isInputsValid: PropTypes.object.isRequired,
    inputsValue: PropTypes.object.isRequired,
    isUpdate: PropTypes.object.isRequired,
    registerLoading: PropTypes.bool.isRequired,
    notifyRegisterMessageError: PropTypes.string.isRequired,
    notifyRegisterHtmlTemplate: PropTypes.string.isRequired,
    notifyRegisterConfirm: PropTypes.object.isRequired,
    handleFocus: PropTypes.func.isRequired,
    handleFocusOut: PropTypes.func.isRequired,
    isFocus: PropTypes.object.isRequired,
  };

  render() {
    const {
      onChangeInfo,
      onSubmitHandler,
      isInputsValid,
      isUpdate,
      registerLoading,
      inputsValue,
      notifyRegisterMessageError,
      notifyRegisterHtmlTemplate,
      handleFocus,
      handleFocusOut,
      isFocus,
      notifyRegisterConfirm,
    } = this.props;
    const isValid = checkValidationFields(isInputsValid);

    const notifyRegisterConfirmTemplate = (
      <Notify
        typeGroup={"Auth"}
        type={notifyRegisterConfirm.type}
        htmlTemplate={notifyRegisterConfirm.htmlTemplate}
        active={notifyRegisterConfirm.active}
        closeNotify={notifyRegisterConfirm.closeNotify}
        messageHeading={notifyRegisterConfirm.messageHeading}
        messageBody={notifyRegisterConfirm.messageBody}
        messageError={notifyRegisterConfirm.messageError}
        classStyle={"notify-blue"}
        classPosition={"notify-absolute"}
      />
    );

    return (
      <Router>
        <form className="register-form auth-form">
          <h2 className="auth-title text-register">Регистрация</h2>
          <p className="auth-text-content text-register">
            Ако сте нов потребител, е необходимо да създадете ваш профил, за да
            използвате платформата пълноценно.
          </p>

          {registerLoading ? <Spinner classColor={`spinner-white`} /> : null}
          {notifyRegisterConfirm.htmlTemplate === "registerConfirmSuccess" ||
          notifyRegisterConfirm.htmlTemplate === "registerConfirmCatchError"
            ? notifyRegisterConfirmTemplate
            : null}

          <fieldset className="form-field-set form-offset">
            <input
              type="text"
              id="register-name"
              name="name"
              value={inputsValue.name}
              className={`blue-autofill form-input input-white ${
                !isInputsValid.name && isUpdate.name ? "invalid " : ""
              }`}
              onChange={onChangeInfo}
              onFocus={handleFocus}
              onBlur={handleFocusOut}
              placeholder="Име"
            />
            <label
              htmlFor="register-name"
              className="form-label form-label-error"
            ></label>
            <label
              className={`form-label-focus focus-blue ${
                isFocus.name ? "focus-animate" : ""
              }
              ${!isInputsValid.name && isUpdate.name ? "invalid " : ""}`}
            ></label>
          </fieldset>

          <fieldset className="form-field-set form-offset">
            <input
              type="text"
              id="register-email"
              name="email"
              value={inputsValue.email}
              className={`blue-autofill form-input input-white ${
                !isInputsValid.email && isUpdate.email ? "invalid " : ""
              }`}
              title="Моля попълнете полето в правилния формат (без шпация в началото или края)."
              onChange={onChangeInfo}
              onFocus={handleFocus}
              onBlur={handleFocusOut}
              placeholder="E-mail"
            />
            <label
              htmlFor="register-email"
              className="form-label form-label-error"
            >
              {notifyRegisterHtmlTemplate === "registerError"
                ? notifyRegisterMessageError
                : ""}
            </label>
            <label
              className={`form-label-focus focus-blue ${
                isFocus.email ? "focus-animate" : ""
              }
              ${!isInputsValid.email && isUpdate.email ? "invalid " : ""}`}
            ></label>
          </fieldset>

          <fieldset className="form-field-set form-offset">
            <input
              type="password"
              id="register-password"
              name="password"
              value={inputsValue.password}
              className={`form-input input-white ${
                !isInputsValid.password && isUpdate.password ? "invalid " : ""
              }`}
              onChange={onChangeInfo}
              onFocus={handleFocus}
              onBlur={handleFocusOut}
              placeholder="Парола"
            />
            <label
              htmlFor="register-password"
              className="form-label form-label-error"
            >
              {!isInputsValid.password &&
              isUpdate.password &&
              inputsValue.password !== ""
                ? "Минимален брой сомволи (6)!"
                : ""}
            </label>
            <label
              className={`form-label-focus focus-blue ${
                isFocus.password ? "focus-animate" : ""
              }
              ${
                !isInputsValid.password && isUpdate.password ? "invalid " : ""
              }`}
            ></label>
          </fieldset>

          <fieldset className="form-field-set form-offset">
            <input
              type="password"
              id="register-repassword"
              name="repassword"
              value={inputsValue.repassword}
              className={`form-input input-white ${
                !isInputsValid.repassword && isUpdate.repassword
                  ? "invalid "
                  : ""
              }`}
              onChange={onChangeInfo}
              onFocus={handleFocus}
              onBlur={handleFocusOut}
              placeholder="Потвърждение Парола"
            />
            <label
              htmlFor="register-repassword"
              className="form-label form-label-error"
            >
              {!isInputsValid.repassword &&
              isUpdate.repassword &&
              inputsValue.repassword !== ""
                ? "Паролите не съвпадат"
                : ""}
            </label>
            <label
              className={`form-label-focus focus-blue ${
                isFocus.repassword ? "focus-animate" : ""
              }
              ${
                !isInputsValid.repassword && isUpdate.repassword
                  ? "invalid "
                  : ""
              }`}
            ></label>
          </fieldset>

          <fieldset className="form-field-set form-offset">
            <input
              type="checkbox"
              id="register-rules"
              name="rules"
              className={`form-checkbox ${
                !isInputsValid.rules && isUpdate.rules ? "invalid " : ""
              }`}
              onChange={onChangeInfo}
              checked={inputsValue.rules}
            />
            <label
              htmlFor="register-rules"
              className="form-checked-tick border-white"
            >
              <i className="fas fa-check"></i>
            </label>
            <label
              htmlFor="register-rules"
              className={`form-label label-white auth-label ${
                !isInputsValid.rules && isUpdate ? "invalid" : ""
              }`}
            >
              Прочетох и съм съгласен с{" "}
              <Link
                className="rules-link"
                to="/general-terms"
                target="_blank"
                title="Общите условия и правила"
              >
                общите условия
              </Link>{" "}
              на сайта.
            </label>
          </fieldset>

          <fieldset className="form-field-set field-set-btn">
            <button
              type="button"
              className="easy-math-button btn-white btn-form-size"
              title="Регистрация"
              disabled={!isValid}
              onClick={onSubmitHandler}
            >
              Регистрация
            </button>
          </fieldset>

          <fieldset className="form-field-set form-offset">
            <p className="auth-contact-text">
              Ако срещате затруднения с регистрацията и акаунта си,{" "}
              <Link
                className="rules-link"
                to="/contact-us"
                target="_blank"
                title="Контакти"
              >
                свържете се с нас.
              </Link>
            </p>
          </fieldset>
        </form>
      </Router>
    );
  }
}

const mapStateToProps = ({ loading, notifications }) => {
  return {
    registerLoading: loading.registerLoading,
    notifyRegisterConfirm: notifications.registerConfirm,
    notifyRegisterHtmlTemplate: notifications.register.htmlTemplate,
    notifyRegisterMessageError: notifications.register.messageError,
  };
};

export default connect(mapStateToProps)(Register);
