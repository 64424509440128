import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  OnlineContestInfo: {
    body: "",
    name: "",
    id: "",
    free: "",
    class: "",
    year: ""
  },
  currentOnlineContest: {
    answers: [],
    exercise: [],
    remainingTime: 0
  },
  currentOnlineContestError: false,
  onlineContestAnswers: [],
  contestSubmissionMessage: "",
  contestSubmittedSuccessfuly: false
}

const getOnlineContestInfoSuccess = (state, action) => {

  localStorage.setItem('OnlineContestInfo', JSON.stringify(action.contestInfo.contest[0]));

  // comparison note for behaviour between stored answers for math quiz and online contest:
  // online contest keeps the stored answers in localstorage till:
  // - contest answers were submitted
  // - the user lands on a different contest, comparison made by id
  // math quiz keeps the stored answers in localstorage till:
  // - the user goes to info page, then the stored answers are removed 

  return updateObject(state, {
    OnlineContestInfo: action.contestInfo.contest[0]
  })
}

const getCurrentOnlineContestSuccess = (state, action) => {

  let updatedAction = {
    answers: action.info.answers,
    exercise: action.info.exercise,
    remainingTime: action.info.remainingSeconds
  }
  // in case the key:
  // onlineContestAnswers
  // is already populated, don't do anything 
  if (state.onlineContestAnswers.length > 0){

    return updateObject(state, {
      currentOnlineContest: updatedAction
    })
  } else if (localStorage.getItem('onlineContestAnswers') !== undefined && localStorage.getItem('onlineContestAnswers') !== null) {
    // this case covers the moment when the user has started the test
    // refreshed and lost the answers to the questions at the redux store
    // and in this case, we take the questions answers from the localstorage if they exist
    let onlineContestAnswersFromStore = JSON.parse(localStorage.getItem('onlineContestAnswers'));

    return updateObject(state, {
      currentOnlineContest: updatedAction,
      onlineContestAnswers: onlineContestAnswersFromStore
    })
  } else {
    // in order to keep track of answered and not answer questions we keep 
    // a separate key holding record on how the quiz the going

    // as we get the total number of pages and a pagination needs to be build
    // the id, which corresponds to the itterator is pushed to handmage array
    let arrayMadeByHand = [];
  
    for (let itterator = 1; itterator <= action.info.total_count; itterator++) {

      if (itterator === 1 && action.info.exercise[0].no_answer === "1" && action.info.exercise[0].number === "1"){

        arrayMadeByHand.push({
          countNumber: itterator,
          wasItAnswered: true,
          no_answer: true,
          answer: {},
          exerciseId: action.info.exercise[0].id,
          isOpenAnswer: ""
        });
      } else {
        
        arrayMadeByHand.push({
          countNumber: itterator,
          wasItAnswered: false,
          answer: {},
          exerciseId: "",
          isOpenAnswer: ""
        });  
      }
    }
  
    return updateObject(state, {
      currentOnlineContest: updatedAction,
      onlineContestAnswers: arrayMadeByHand,
    })
  }
}

const getCurrentOnlineContestFailure = (state, action) => {

  return updateObject(state, {
    currentOnlineContestError: action.error
  })
}

const updateOnlineContestSingleAnswer = (state, action) => {

  // this case covers refresh, when you have stored answers in localstorage
  // and you want to update an answer
  if (!state.onlineContestAnswers.length && localStorage.getItem('onlineContestAnswers') !== undefined && localStorage.getItem('onlineContestAnswers') !== null){
    state.onlineContestAnswers = JSON.parse(localStorage.getItem('onlineContestAnswers'));
  }

  if (action.quizAnswerData.answer[0].no_answer === "0") {

    let filteredEmptyAnswers = action.quizAnswerData.answer.filter(singleAnswer => singleAnswer.answer !== "");
    state.onlineContestAnswers[action.quizAnswerData.pageNum].wasItAnswered = filteredEmptyAnswers.length > 0 ? true : false;
    state.onlineContestAnswers[action.quizAnswerData.pageNum].isOpenAnswer = action.quizAnswerData.isOpenAnswer;
    state.onlineContestAnswers[action.quizAnswerData.pageNum].exerciseId = state.currentOnlineContest.exercise[0].id;
    state.onlineContestAnswers[action.quizAnswerData.pageNum].answer[state.currentOnlineContest.exercise[0].id] = action.quizAnswerData.answer;
    state.onlineContestAnswers[action.quizAnswerData.pageNum].no_answer = false;
  } else {

    state.onlineContestAnswers[action.quizAnswerData.pageNum].wasItAnswered = true;
    state.onlineContestAnswers[action.quizAnswerData.pageNum].no_answer = true;
    state.onlineContestAnswers[action.quizAnswerData.pageNum].exerciseId = action.quizAnswerData.exerciseId;
  }

  localStorage.setItem('onlineContestAnswers', JSON.stringify(state.onlineContestAnswers));

  return state;
}

const onlineContestMassResetState = (state) => {

  localStorage.removeItem('onlineContestAnswers');
  localStorage.removeItem('OnlineContestInfo');

  state = initialState;
  return state;
}

const onlineContestCalculateResultsSuccess = (state, action) => {

  // in case of successful answer submission all stored data is removed
  localStorage.removeItem('onlineContestAnswers');
  localStorage.removeItem('OnlineContestInfo');

  return updateObject(state, {
    contestSubmissionMessage: action.contestSubmissionMessage,
    contestSubmittedSuccessfuly: true
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ONLINE_CONTEST_INFO_SUCCESS: return getOnlineContestInfoSuccess(state, action);
    case actionTypes.GET_ONLINE_CONTEST_EXERCISE_SUCCESS: return getCurrentOnlineContestSuccess(state, action);
    case actionTypes.GET_ONLINE_CONTEST_EXERCISE_FAILURE: return getCurrentOnlineContestFailure(state, action);
    case actionTypes.UPDATE_ONLINE_CONTEST_SINGLE_ANSWER: return updateOnlineContestSingleAnswer(state, action);
    case actionTypes.ONLINE_CONTEST_MASS_RESET_STATE: return onlineContestMassResetState(state);
    case actionTypes.ONLINE_CONTEST_CALCULATE_RESULTS_SUCCESS: return onlineContestCalculateResultsSuccess(state, action);
    default: return state;
  }
}

export default reducer;