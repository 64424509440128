import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import * as actionsCreatorAuth from "../../store/root_actions/auth";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";
import * as actionsCreatorModal from "../shared/ui/modal/actions";
import "./auth.scss";

import Notify from "../shared/ui/notifications/notify_template";
import { authInitState } from "../../constants/auth_types";
import Modal from "../shared/ui/modal/modal";
import Login from "./login";
import Register from "./register";
import AuthorizeNewPassword from "./authorize_new_password";
import {
  isInputValid,
  checkNotifyActive,
  rejectObjectProp,
  debounced,
} from "../../utils/helpers/common";

class Auth extends Component {
  static propTypes = {
    notify: PropTypes.object.isRequired,
    notifyRegister: PropTypes.object.isRequired,
    notifyForgottenPassword: PropTypes.object.isRequired,
    stateModal: PropTypes.bool.isRequired,
    authorizeNewPasswordAuthorizeCode: PropTypes.string.isRequired,
    registerConfirmAuthorizeCode: PropTypes.string.isRequired,
    authorizeNewPasswordTemplate: PropTypes.bool.isRequired,
    registerConfirmTemplate: PropTypes.bool.isRequired,
    isForgottenPasswordOpen: PropTypes.bool.isRequired,
    isUserNotRegistered: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...authInitState,
    };
  }

  componentDidMount() {
    if (!this.props.authModalState) {
      this.setState({ ...authInitState });
    }
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = "unset";
  }

  handleChange = (e, type) => {
    let newFormData = { ...this.state[type] };
    let newValidateFormData = { ...this.state[`${type}ValidateInputs`] };
    let newUpdateFormData = { ...this.state[`${type}Update`] };
    let isValid;

    const targetValue = e.currentTarget.value;
    const targetName = e.currentTarget.name;
    const isChecked = e.currentTarget.checked;

    if (targetName !== "rules" && targetName !== "repassword") {
      isValid = isInputValid(targetValue, `${targetName.toUpperCase()}`);
    }
    if (targetName === "repassword" && newFormData.hasOwnProperty(targetName)) {
      isValid = isInputValid(
        targetValue,
        `${targetName.toUpperCase()}`,
        newFormData["password"]
      );
      newValidateFormData["repassword"] = isValid;
    }
    if (targetName === "password" && newFormData.hasOwnProperty("repassword")) {
      if (
        targetValue !== newFormData["repassword"] &&
        newFormData["repassword"] !== ""
      ) {
        newValidateFormData["repassword"] = false;
      } else {
        if (newUpdateFormData["repassword"])
          newValidateFormData["repassword"] = true;
      }
    }

    targetName !== "rules"
      ? (newFormData[targetName] = targetValue)
      : (newFormData[targetName] = isChecked);
    targetName === "rules"
      ? (newValidateFormData[targetName] = isChecked)
      : (newValidateFormData[targetName] = isValid);
    newUpdateFormData[targetName] = true;

    this.setState({
      [type]: newFormData,
      [`${type}ValidateInputs`]: newValidateFormData,
      [`${type}Update`]: newUpdateFormData,
    });
  };

  handleSubmit = (type) => {
    let newDeliveryData;
    const deliveryData = { ...this.state[type] };

    if (
      deliveryData.hasOwnProperty("repassword") ||
      deliveryData.hasOwnProperty("rules")
    ) {
      newDeliveryData = rejectObjectProp(deliveryData, ["rules", "repassword"]);
    } else {
      newDeliveryData = deliveryData;
    }

    this.checkAuthorizeCodeAvailable(type, newDeliveryData);

    console.log();

    if (type === "login") {
      this.handleReset(type);
    }
  };

  handleFocus = (e, type) => {
    const currentName = e.currentTarget.name;
    const newFocusData = {
      ...this.state[`${type}Focus`],
    };
    newFocusData[currentName] = true;

    this.setState({
      [`${type}Focus`]: newFocusData,
    });
  };

  handleFocusOut = (e, type) => {
    const currentName = e.currentTarget.name;
    const newFocusData = {
      ...this.state[`${type}Focus`],
    };
    newFocusData[currentName] = false;

    this.setState({
      [`${type}Focus`]: newFocusData,
    });
  };

  checkAuthorizeCodeAvailable = (type, data) => {
    const { authorizeNewPasswordAuthorizeCode } = this.props;

    if (type === "authorizeNewPassword") {
      data.code = authorizeNewPasswordAuthorizeCode;
      this.props.deliverAuthedData(data, type);
    } else {
      this.props.deliverAuthedData(data, type);
    }
  };
  handleClose = () => {
    this.handleReset();
  };
  handleForgottenPassword = () => {
    this.props.forgottenPasswordState(true);
  };
  handleReset = (type) => {
    const {
      registerConfirmAuthorizeCode,
      authorizeNewPasswordAuthorizeCode,
      authorizeNewPasswordTemplate,
      isForgottenPasswordOpen,
      registerConfirmTemplate,
      notify,
      isUserNotRegistered,
    } = this.props;

    if (type === undefined) {
      if (isUserNotRegistered) {
        this.props.authModalState(false);
        this.props.modalUserNameRegistered(false);
      } else {
        this.props.authModalState(false);
      }

      const isNotifyActive = checkNotifyActive(notify, "Auth");

      if (isNotifyActive) {
        this.props.notifyMessageReset("Auth");
      }

      if (
        authorizeNewPasswordAuthorizeCode !== "" ||
        registerConfirmAuthorizeCode !== ""
      ) {
        this.props.authorizeCodeReset();
      }
      if (authorizeNewPasswordTemplate) {
        this.props.authorizeNewPasswordTemplateReset();
      }
      if (registerConfirmTemplate) {
        this.props.authorizeRegisterTemplateReset();
      }
    }

    if (isForgottenPasswordOpen) {
      this.props.forgottenPasswordState(false);
    }
  };

  render() {
    const {
      loginValidateInputs,
      registerValidateInputs,
      authorizeNewPasswordUpdate,
      authorizeNewPasswordValidateInputs,
      loginUpdate,
      registerUpdate,
      login,
      register,
      authorizeNewPassword,
      loginFocus,
      registerFocus,
      authorizeNewPasswordFocus,
    } = this.state;

    const {
      notifyForgottenPassword,
      notifyRegister,
      stateModal,
      authorizeNewPasswordTemplate,
    } = this.props;

    const notifyRegisterTemplate = (
      <Notify
        typeGroup={"Auth"}
        type={notifyRegister.type}
        htmlTemplate={notifyRegister.htmlTemplate}
        active={notifyRegister.active}
        closeNotify={notifyRegister.closeNotify}
        messageHeading={notifyRegister.messageHeading}
        messageBody={notifyRegister.messageBody}
        messageError={notifyRegister.messageError}
        classStyle={"notify-blue"}
        classPosition={"notify-absolute"}
      />
    );

    const notifyForgottenPasswordTemplate = (
      <Notify
        typeGroup={"Auth"}
        type={notifyForgottenPassword.type}
        htmlTemplate={notifyForgottenPassword.htmlTemplate}
        active={notifyForgottenPassword.active}
        closeNotify={notifyForgottenPassword.closeNotify}
        messageHeading={notifyForgottenPassword.messageHeading}
        messageBody={notifyForgottenPassword.messageBody}
        messageError={notifyForgottenPassword.messageError}
        classStyle={"notify-white"}
        classPosition={"notify-absolute"}
      />
    );

    return (
      <Modal
        classID={`auth-modal`}
        classMain=""
        classWrapper=""
        classShell={`auth-shell`}
        handleClose={this.handleClose}
        stateModal={stateModal}
        range={300}
      >
        {notifyForgottenPassword.htmlTemplate === "forgottenPasswordSuccess" ||
        notifyForgottenPassword.htmlTemplate === "forgottenPasswordCatchError"
          ? notifyForgottenPasswordTemplate
          : null}
        {notifyRegister.htmlTemplate === "registerSuccess" ||
        notifyRegister.htmlTemplate === "registerCatchError"
          ? notifyRegisterTemplate
          : null}

        {authorizeNewPasswordTemplate ? (
          <AuthorizeNewPassword
            onChangeInfo={(e) => this.handleChange(e, "authorizeNewPassword")}
            onSubmitHandler={debounced(500, this.handleSubmit, [
              "authorizeNewPassword",
            ])}
            isInputsValid={authorizeNewPasswordValidateInputs}
            isUpdate={authorizeNewPasswordUpdate}
            inputsValue={authorizeNewPassword}
            handleFocus={(e) => this.handleFocus(e, "authorizeNewPassword")}
            handleFocusOut={(e) =>
              this.handleFocusOut(e, "authorizeNewPassword")
            }
            isFocus={authorizeNewPasswordFocus}
          />
        ) : (
          <Register
            onChangeInfo={(e) => this.handleChange(e, "register")}
            onSubmitHandler={debounced(500, this.handleSubmit, ["register"])}
            isInputsValid={registerValidateInputs}
            isUpdate={registerUpdate}
            inputsValue={register}
            handleFocus={(e) => this.handleFocus(e, "register")}
            handleFocusOut={(e) => this.handleFocusOut(e, "register")}
            isFocus={registerFocus}
          />
        )}

        <Login
          onChangeInfo={(e) => this.handleChange(e, "login")}
          onSubmitHandler={debounced(500, this.handleSubmit, ["login"])}
          onForgottenPasswordHandler={this.handleForgottenPassword}
          isInputsValid={loginValidateInputs}
          isUpdate={loginUpdate}
          inputsValue={login}
          handleFocus={(e) => this.handleFocus(e, "login")}
          handleFocusOut={(e) => this.handleFocusOut(e, "login")}
          isFocus={loginFocus}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ authedUser, notifications, modal }) => {
  return {
    stateModal: authedUser.authModalState,
    authorizeNewPasswordAuthorizeCode:
      authedUser.authorizeNewPasswordAuthorizeCode,
    registerConfirmAuthorizeCode: authedUser.registerConfirmAuthorizeCode,
    isForgottenPasswordOpen: authedUser.isForgottenPasswordOpen,
    authorizeNewPasswordTemplate: authedUser.authorizeNewPasswordTemplate,
    registerConfirmTemplate: authedUser.registerConfirmTemplate,
    isUserNotRegistered: modal.isUserNotRegistered,
    notify: notifications,
    notifyRegister: notifications.register,
    notifyForgottenPassword: notifications.forgottenPassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actionsCreatorAuth,
      ...actionsCreatorNotify,
      ...actionsCreatorModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
