import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class InvoiceLegal extends PureComponent {

  static propTypes = {
    legal: PropTypes.object.isRequired,
    legalValidate: PropTypes.object.isRequired,
    legalUpdate: PropTypes.object.isRequired,
    isFocus: PropTypes.object.isRequired,
    onChangeInfo: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  }

  render() {
    const { legal, onChangeInfo, onFocus, onBlur, isFocus, legalValidate, legalUpdate } = this.props;

    return (
      <div className="invoice-legal">

        <div className="invoice-legal-item invoice-legal-first">
          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-company" name="company" value={legal.company}
              className={`white-autofill form-input input-blue subscription-input ${!legalValidate.company && legalUpdate.company ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Фирма" />

            <label htmlFor="invoice-company" className="form-label form-label-error"></label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.company ? 'focus-animate' : ''} ${!legalValidate.company && legalUpdate.company ? 'invalid ' : ''}`}></label>
          </fieldset>

          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-companyId" name="companyId" value={legal.companyId}
              className={`white-autofill form-input input-blue subscription-input ${!legalValidate.companyId && legalUpdate.companyId ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="ЕИК" />

            <label htmlFor="invoice-companyId" className="form-label form-label-error">
              {!legalValidate.companyId && legalUpdate.companyId && legal.companyId !== '' ? 'Само цифри (7-13)' : ''}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.companyId ? 'focus-animate' : ''} ${!legalValidate.companyId && legalUpdate.companyId ? 'invalid ' : ''}`}></label>
          </fieldset>

          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-vatNumber" name="vatNumber" value={legal.vatNumber}
              className={`white-autofill form-input input-blue subscription-input ${!legalValidate.vatNumber && legalUpdate.vatNumber ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="ДДС Номер" />

            <label htmlFor="invoice-vatNumber" className="form-label form-label-error">
              {!legalValidate.vatNumber && legalUpdate.vatNumber && legal.vatNumber !== '' ? 'Символи (9-15)' : ''}
            </label>
            <label className={`form-label-focus focus-white ${isFocus.vatNumber ? 'focus-animate' : ''} ${!legalValidate.vatNumber && legalUpdate.vatNumber ? 'invalid ' : ''}`}></label>
          </fieldset>
        </div>
        <div className="invoice-legal-item invoice-legal-second">
          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-address" name="address" value={legal.address}
              className={`white-autofill form-input input-blue subscription-input ${!legalValidate.address && legalUpdate.address ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Адрес" />

            <label htmlFor="invoice-address" className="form-label form-label-error">
              {!legalValidate.address && legalUpdate.address && legal.address !== '' ? 'Моля, въведете валиден адрес и/или мин./макс. брой символа (7/50)!' : ''}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.address ? 'focus-animate' : ''} ${!legalValidate.address && legalUpdate.address ? 'invalid ' : ''}`}></label>
          </fieldset>

          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-city" name="city" value={legal.city}
              className={`white-autofill form-input input-blue subscription-input ${!legalValidate.city && legalUpdate.city ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Град" />

            <label htmlFor="invoice-city" className="form-label form-label-error"></label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.city ? 'focus-animate' : ''} ${!legalValidate.city && legalUpdate.city ? 'invalid ' : ''}`}></label>
          </fieldset>
        </div>
        <div className="invoice-legal-item invoice-legal-last">
          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-personInCharge" name="personInCharge" value={legal.personInCharge}
              className={`white-autofill form-input input-blue subscription-input ${!legalValidate.personInCharge && legalUpdate.personInCharge ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="МОЛ" />

            <label htmlFor="invoice-personInCharge" className="form-label form-label-error">
              {!legalValidate.personInCharge && legalUpdate.personInCharge && legal.personInCharge !== '' ? 'Въвеждате забранени и/или мин. брой символа (2)!' : ''}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.personInCharge ? 'focus-animate' : ''} ${!legalValidate.personInCharge && legalUpdate.personInCharge ? 'invalid ' : ''}`}></label>
          </fieldset>

          <fieldset className="form-field-set invoice-offset">

            <input type="text" id="invoice-recipient" name="recipient" value={legal.recipient}
              className={`white-autofill form-input input-blue subscription-input ${!legalValidate.recipient && legalUpdate.recipient ? 'invalid ' : ''}`}
              onChange={onChangeInfo}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="Получател" />

            <label htmlFor="invoice-recipient" className="form-label form-label-error">
              {!legalValidate.recipient && legalUpdate.recipient && legal.recipient !== '' ? 'Въвеждате забранени и/или мин. брой символа (2)!' : ''}
            </label>
            <label className="form-label-required label-blue">*</label>
            <label className={`form-label-focus focus-white ${isFocus.recipient ? 'focus-animate' : ''} ${!legalValidate.recipient && legalUpdate.recipient ? 'invalid ' : ''}`}></label>
          </fieldset>
        </div>

      </div>

    )
  }
}

export default InvoiceLegal;
