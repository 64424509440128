import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  subscriptionModalState: false,
  paymentModalState: false,
  subscriptionList: [],
  invoiceType: [],
  successPayment: {},
  subscriptionEpay: {},
  percentPrice: 0,
  promoCode: '',
  isDiscountSuccessLoaded: false,
  statusPayment: ''
};

const addSubscriptionModalState = (state, action) => {

  return updateObject(state, {
    subscriptionModalState: action.modalState
  })
}
const getSubscriptionsData = (state, action) => {

  return updateObject(state, {
    subscriptionList: action.data.subscriptionPeriods,
    invoiceType: action.data.subscriptionPersonType
  })
}
const getSubscriptionPayment = (state, action) => {

  return updateObject(state, {
    successPayment: action.dataPayment,
    subscriptionEpay: action.dataEpay
  })
}
const resetAddSubscriptions = (state) => {

  return updateObject(state, {
    subscriptionList: [],
    invoiceType: [],
    successPayment: {},
    subscriptionEpay: {}
  })
}
const getSubscriptionDiscount = (state, action) => {
  return updateObject(state, {
    percentPrice: action.data.percent,
    promoCode: action.data.code
  })
}

const isDiscountLoadedSuccess = (state, action) => {
  return updateObject(state, {
    isDiscountSuccessLoaded: action.successState
  })
}
const subscriptionDiscountReset = (state, action) => {
  return updateObject(state, {
    percentPrice: action.percentPrice,
    promoCode: action.promoCode
  })
}
const successFullyPaymentModalState = (state, action) => {
  return updateObject(state, {
    paymentModalState: action.modalState
  })
}
const paymentStatus = (state, action) => {
  return updateObject(state, {
    statusPayment: action.status
  })
}

const addSubscription = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.ADD_SUBSCRIPTION_MODAL_STATE: return addSubscriptionModalState(state, action);
    case actionTypes.GET_SUBSCRIPTIONS_DATA: return getSubscriptionsData(state, action);
    case actionTypes.GET_SUBSCRIPTION_PAYMENT: return getSubscriptionPayment(state, action);
    case actionTypes.RESET_ADD_SUBSCRIPTIONS: return resetAddSubscriptions(state, action);
    case actionTypes.GET_SUBSCRIPTION_DISCOUNT: return getSubscriptionDiscount(state, action);
    case actionTypes.IS_DISCOUNT_LOADED_SUCCESS: return isDiscountLoadedSuccess(state, action);
    case actionTypes.SUBSCRIPTION_DISCOUNT_RESET: return subscriptionDiscountReset(state, action);
    case actionTypes.SUCCESSFULLY_PAYMENT_MODAL_STATE: return successFullyPaymentModalState(state, action);
    case actionTypes.PAYMENT_STATUS: return paymentStatus(state, action);

    default: return state
  }
}

export default addSubscription;