import {
  VALID_EMAIL_REG,
  VALID_PASSWORD_REG,
  VALID_NAME_REG,
  VALID_PHONE_REG,
  VALID_MESSAGE_REG,
  VALID_PROMO_CODE,
  VALID_COMPANY_NAME_REG,
  VALID_COMPANYID_REG,
  VALID_COMPANY_VAT_REG,
  VALID_ADDRESS_REG,
  VALID_CITY_REG,
  VALID_PHONE_REQ,
} from "../../constants/formats";

export const isInputValid = (value, type, secondValue, selected, checked) => {
  let result;

  const isEmailValid = new RegExp(VALID_EMAIL_REG);
  const isPasswordValid = new RegExp(VALID_PASSWORD_REG);
  const isNameValid = new RegExp(VALID_NAME_REG);
  const isPhoneValid = new RegExp(VALID_PHONE_REG);
  const isPhoneReqValid = new RegExp(VALID_PHONE_REQ);
  const isMessageValid = new RegExp(VALID_MESSAGE_REG);
  const isPromoCodeValid = new RegExp(VALID_PROMO_CODE);
  const isCopanyNameValid = new RegExp(VALID_COMPANY_NAME_REG);
  const isCompanyIdValid = new RegExp(VALID_COMPANYID_REG);
  const isVatNumberValid = new RegExp(VALID_COMPANY_VAT_REG);
  const isAddressValid = new RegExp(VALID_ADDRESS_REG);
  const isCityValid = new RegExp(VALID_CITY_REG);

  switch (type) {
    case "EMAIL":
      result = isEmailValid.test(value);

      break;

    case "PASSWORD":
      result = isPasswordValid.test(value);
      break;

    case "REPASSWORD":
      value === secondValue ? (result = true) : (result = false);
      break;

    case "NAME":
    case "PERSONINCHARGE":
    case "RECIPIENT":
      result = isNameValid.test(value);
      break;

    case "PARENT":
      result = isNameValid.test(value) || value === "";
      break;

    case "COMPANY":
      result = isCopanyNameValid.test(value);
      break;

    case "SCHOOL":
      result = isCopanyNameValid.test(value) || value === "";
      break;

    case "COMPANYID":
      result = isCompanyIdValid.test(value);
      break;

    case "VATNUMBER":
      result = isVatNumberValid.test(value) || value === "";
      break;

    case "PROMOCODE":
      result = isPromoCodeValid.test(value);
      break;

    case "PHONE":
      result = isPhoneValid.test(value);
      break;

    case "CONTESTPHONE":
      result = isPhoneReqValid.test(value) || value === "";
      break;

    case "MESSAGE":
      result = isMessageValid.test(value);
      break;

    case "SUBSCRIPTIONID":
    case "LEVEL":
      result = selected !== 0;
      break;

    case "PAYMENT":
      result = checked;
      break;

    case "ADDRESS":
      result = isAddressValid.test(value);
      break;

    case "CITY":
      result = isCityValid.test(value);
      break;

    default:
      result = false;
      break;
  }

  return result;
};

export const checkValidationFields = (obj) =>
  Object.values(obj).every((val) => val);
export const checkNotifyActive = (obj, groupType) =>
  Object.values(obj).some((val) => val.active && val.typeGroup === groupType);

export const rejectObjectProp = (obj, keys) => {
  const result = Object.keys(obj)
    .filter((propName) => !keys.includes(propName))
    .map((propName) => Object.assign({}, { [propName]: obj[propName] }))
    .reduce((res, o) => Object.assign(res, o), {});
  return result;
};

export const debounced = (delay, fn, args = []) => {
  let timerId;
  return () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);

      timerId = null;
    }, delay);
  };
};

export const checkAccessTokenExpirationTime = (
  accessTokenExpirationTimeStore
) => {
  const localTimestamp = new Date().getTime();

  if (
    accessTokenExpirationTimeStore !== 0 &&
    accessTokenExpirationTimeStore !== undefined
  ) {
    return checkCurrentTime(localTimestamp, accessTokenExpirationTimeStore);
  } else {
    if (JSON.parse(localStorage.getItem("easyMath")) !== null) {
      const accessTokenExpirationTimeLocalStorage = JSON.parse(
        localStorage.getItem("easyMath")
      ).exprirationTime;
      return checkCurrentTime(
        localTimestamp,
        accessTokenExpirationTimeLocalStorage
      );
    }
  }
};
export const isObjectEmpty = (obj) =>
  obj.constructor === Object && Object.keys(obj).length === 0;

const checkCurrentTime = (localTime, expirationTime) => {
  if (expirationTime >= localTime) {
    return false;
  } else {
    return true;
  }
};

export const copyToClipboard = (textarea) => {
  const copyContext = textarea;

  copyContext.select();
  document.execCommand("copy");
};
