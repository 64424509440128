import { call, put } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorAuthor from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotifications from "../../store/root_actions/notifications";

export function* getPageContentAuthorData(action) {
  yield put(actionsCreatorLoading.loadingStart(action.pageType));

  const url = `/API/pages/${action.pageType}`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.pageType));

    } else {
        const data = response.body.data.author;
        const newData = {
          loaded: true,
          ...data
        }

        yield put(actionsCreatorLoading.loadingStop(action.pageType));
        yield put(actionsCreatorAuthor.getPageContentSuccess(newData));
      }
    }
    catch(error) {
      yield put(actionsCreatorLoading.loadingStop(action.pageType));

      console.log('------------------------------------');
      console.log(error);
      console.log('------------------------------------');

      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.pageType,
        htmlTemplate: `${action.pageType}CatchError`,
        active: true,
        closeNotify: false,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
  }
}