import { call, put } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorOnlineContest from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotifications from "../../store/root_actions/notifications";
import * as actionsCreatorUploadFiles from './../upload_files/actions';

export function* getOnlineContestInfo(action) {

  yield put(actionsCreatorLoading.loadingStart(action.contestType));

  const url = '/API/competitions/' + action.id;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.contestType));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.contestType));
      yield put(actionsCreatorOnlineContest.getOnlineContestInfoSuccess(
        {
          ...response.body
        }
        ));
      }
    }
    catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.contestType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.contestType,
      htmlTemplate: `${action.contestType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }))
  }

}

export function* getOnlineContestExerciseInfo(action) {

  yield put(actionsCreatorLoading.loadingStart(action.contestType));
  try {

    const url =  `/API/competitions/${action.id}/exercises?number=${action.pageNumber}`;
    let response = yield call(fetchingData, url, null, 'GET');

    if (!response.ok) {

      yield put(actionsCreatorLoading.loadingStop(action.contestType));
      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.contestType,
        htmlTemplate: `${action.contestType}Error`,
        active: true,
        closeNotify: false,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    } else {

      yield put(actionsCreatorLoading.loadingStop(action.contestType));

      if (response.body.data.exercise.length > 0) {
        if (response.body.data.exercise[0].no_answer === "1") {
          yield put(actionsCreatorUploadFiles.getUploadedFilesData(
            {
              totalFilesCount: response.body.data.exercise[0].files.length,
              uploadedFiles: response.body.data.exercise[0].files
            }
          ));
        }
      }
      
      yield put(actionsCreatorOnlineContest.getOnlineContestExerciseSuccess(
        {
          ...response.body.data
        }
      ));

    }
  } catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.contestType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.contestType,
      htmlTemplate: `${action.contestType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* onlineContestCalculateResults(action) {

  yield put(actionsCreatorLoading.loadingStart(action.pageType));

  const url = `/API/competitions/${action.contestId}/${action.pageType}`;
  const payload = {
    clientAnswers: action.clientAnswers
  }

  const response = yield call(fetchingData, url, payload, 'POST');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.pageType));
    } else {

      yield put(actionsCreatorLoading.loadingStop(action.pageType));

      yield put(actionsCreatorOnlineContest.OnlineContestCalculateResultsSuccess({
        contestSubmissionMessage: response.body.message
      }));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.pageType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.pageType,
      htmlTemplate: `${action.pageType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}