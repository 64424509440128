import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  isContactDataDeliveredSuccess: false,
  isContactInfoDataSuccess: false,
  mol: '',
  paymentTitle: '',
  email: '',
  phone: null,
  bic: '',
  IBAN: '',
  company: '',
  isDataBodyEmpty: true
}

const contactDeliveredSuccess = (state, action) => {

  return updateObject(state, {
    isContactDataDeliveredSuccess: action.contactDeliveredState
  })
}
const contactInfoDataSuccess = (state, action) => {

  return updateObject(state, {
    isContactInfoDataSuccess: action.contactInfoState
  })
}
const getContactInfoData = (state, action) => {
  return updateObject(state, {
    mol: action.data.bankPaymentFirm,
    paymentTitle: action.data.bankPaymentTitle,
    bic: action.data.bic,
    email: action.data.email,
    company: action.data.firm,
    IBAN: action.data.iBAN,
    phone: action.data.phone,
    isDataBodyEmpty: action.data.isEmpty
  })
}

const contact = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.CONTACT_DELIVERED_SUCCESS: return contactDeliveredSuccess(state, action);
    case actionTypes.CONTACT_INFO_DATA_SUCCESS: return contactInfoDataSuccess(state, action);
    case actionTypes.GET_CONTACT_INFO_DATA: return getContactInfoData(state, action);

    default: return state
  }
}

export default contact;