import React from 'react';
import { NavLink } from 'react-router-dom';
import PlatformSvg from './../../shared/ui/svg/platform_svg';

import { debounced } from "../../../utils/helpers/common";

export default function MainNav(props){

  const {authModalState, accountStats, signIn, navMenu, menuVisible, authName} = props;

  return(
    <nav className='main-nav'>

      <NavLink className="nav-link" onClick={menuVisible ? debounced(300, navMenu) : null} exact activeClassName="selected" to="/">Начало</NavLink>
      <NavLink className="nav-link" onClick={menuVisible ? debounced(300, navMenu) : null} activeClassName="selected" to="/about-us">За нас</NavLink>
      <NavLink className="nav-link" onClick={menuVisible ? debounced(300, navMenu) : null} activeClassName="selected" to="/news/all">Новини</NavLink>
      <NavLink className="nav-link" onClick={menuVisible ? debounced(300, navMenu) : null} activeClassName="selected" to="/contact-us">Контакти</NavLink>
      <NavLink className="nav-link" onClick={menuVisible ? debounced(300, navMenu) : null} activeClassName="selected" to="/subscriptions">Абонамент и отстъпки</NavLink>
      <NavLink className="nav-link nav-cta" onClick={menuVisible ? debounced(300, navMenu) : null} activeClassName="selected" to="/platform/intro">
        <PlatformSvg />
        Към Платформата
      </NavLink>

      <button className="nav-link visible-desktop" onClick={signIn ? debounced(300, accountStats) : debounced(500, authModalState)}>
        <i className="fas fa-user"></i>
        { signIn ? authName : "Акаунт" }
      </button>

    </nav>
  )
}