import { call, put } from "redux-saga/effects";
import { fetchingData } from '../../utils/services/fetching_data';

import * as actionsCreatorNews from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotifications from "../../store/root_actions/notifications";

export function* getNewsCategoriesData(action) {

  yield put(actionsCreatorLoading.loadingStart(action.newsCategoriesType));

  const url = `/API/news/${action.newsType}`;
  const response = yield call(fetchingData, url, null, 'GET', action.newsType);

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.newsCategoriesType));

    } else {
      const data = response.body.data;

      yield put(actionsCreatorNews.getNewsCategoriesData(data));
      yield put(actionsCreatorLoading.loadingStop(action.newsCategoriesType));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.newsCategoriesType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.newsCategoriesType,
      htmlTemplate: `${action.newsCategoriesType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }
}

export function* getNews(action) {

  let url = '';
  yield put(actionsCreatorLoading.loadingStart(action.newsType));

  if (action.newsCategoryType === 'allNews') {
    url = `/API/news/list?${action.url}`;
  }

  if (action.newsCategoryType === 'categoryNews') {
    url = `/API/news/list?${action.url}`;
  }

  const response = yield call(fetchingData, url, null, 'GET', action.newsType);

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.newsType));
      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.newsType,
        htmlTemplate: `${action.newsType}CatchError`,
        active: true,
        closeNotify: false,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    } else {
      const data = response.body.data;

      yield put(actionsCreatorNews.getArticles(data));
      yield put(actionsCreatorLoading.loadingStop(action.newsType));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.newsType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.newsType,
      htmlTemplate: `${action.newsType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}

export function* getArticle(action) {

  yield put(actionsCreatorLoading.loadingStart(action.articleType));

  const url = `/API/news/${action.id}`;
  const response = yield call(fetchingData, url, null, 'GET', action.articleType);

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.articleType));
      yield put(actionsCreatorNotifications.notifyMessages({
        type: action.articleType,
        htmlTemplate: `${action.articleType}CatchError`,
        active: true,
        closeNotify: false,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    } else {
      const data = response.body.data;

      yield put(actionsCreatorNews.getArticle(data));
      yield put(actionsCreatorLoading.loadingStop(action.articleType));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.articleType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotifications.notifyMessages({
      type: action.articleType,
      htmlTemplate: `${action.articleType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }
}