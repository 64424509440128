import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import './css/style.scss';
import * as actionsCreatorAuth from "./../../store/root_actions/auth";
import * as actionsCreatorAddSubstription from "./../../components/add_subscription/actions";
import * as actionsCreatorHeader from "./actions";

import { debounced } from "./../../utils/helpers/common";
import Spinner from "./../shared/ui/spinner/spinner";
import NotifyError from "../shared/ui/notifications/notify_error";

 class AccountStats extends PureComponent {

  static propTypes = {
    authName: PropTypes.string.isRequired,
    signIn: PropTypes.bool.isRequired,
    logoutLoading: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    accountStatsContentSubscription: PropTypes.array.isRequired
  };

  handleLogout = () => {
    this.props.logout(this.props.history);
  }

  handleAddSubscription = () => {
    this.props.addSubscriptionModalState(true);
  }

  render() {

    const { authName, signIn, logoutLoading, accountStatsContentSubscription, loading } = this.props;

    return(
      <div className="account-details">

        <h3 className="regular-title visible-mobile y-axis-margin remove-top-margin">
          <i className="fas fa-user account-details-user-icon"></i>
          <span className="account-details-username">{signIn ? authName : ""}</span>
        </h3>

        <h2 className="regular-title">Абонамент/и</h2>

        <div className={`account-subscription-shell ${accountStatsContentSubscription.length > 1 ? 'scroll-y' : ''}`}>
          {
            loading
              ? <Spinner classColor={`spinner-white`} />
              : null
          }
          {
            accountStatsContentSubscription.length > 0
            ? accountStatsContentSubscription.map(({payType, periodName, startDate, endDate}, index) => (
                <div className="account-subscription-detail" key={`subsciprition${index}`}>
                  <p className="regular-paragraph">
                    {
                      (() => {
                        if (payType) {
                          return payType;
                        } else if (periodName) {
                          return periodName;
                        } else {
                          return "Няма намерена информация за абонамента";
                        }
                      })()
                    }
                  </p>

                  <div className="flex-container-opposite-sides y-axis-margin">
                    <div className="subscription-date date-start">
                      <i className="far fa-calendar-plus"></i>
                      {startDate ? startDate : "Липсваща дата"}
                    </div>

                    <div className="subscription-date date-end">
                      <i className="far fa-calendar-minus"></i>
                      {endDate ? endDate : "Липсваща дата"}
                    </div>
                  </div>

                </div>
              ))
            : <div className="account-subscription-detail y-axis-margin">
                {/* <p className="regular-paragraph y-axis-margin">Няма налични абонаменти</p> */}
                <NotifyError classStyle={'notify-white account-info'} messageError={'Няма налични абонаменти'} />
              </div>
          }
        </div>


        <div className="y-axis-margin text-center">
          <button onClick={this.handleAddSubscription} type="button" className="easy-math-button btn-yellow btn-account-size" value="Exit" title="Добави абонамент">
            <i className="fas fa-plus"></i>
            Добави абонамент
          </button>
        </div>

        <div className="text-center account-stats-loading">
          {
            logoutLoading
              ? <Spinner classColor={`spinner-white spinner-logout`}> waiting... </Spinner>
              : null
          }
          <button type="button" className="easy-math-button btn-blue btn-account-size" value="Exit" title="Изход" onClick={debounced(500, this.handleLogout, [])}>
            <i className="fas fa-power-off"></i>
            Изход
          </button>
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({authedUser, loading, accountStats}) => {
  return {
    authName: authedUser.name,
    loading: loading.accountInfoLoading,
    logoutLoading: loading.logoutLoading,
    signIn: authedUser.signIn,
    accountStatsContentSubscription: accountStats.accountStatsContent.subscriptions
  }
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
    ...actionsCreatorAuth,
    ...actionsCreatorHeader,
    ...actionsCreatorAddSubstription
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountStats));