export const login = "login";
export const register = "register";
export const forgottenPassword = "forgottenPassword";
export const authorizeNewPassword = "authorizeNewPassword";

export const authTypes = {
  login: "login",
  register: "register",
  registerConfirm: "registerConfirm",
  forgottenPassword: "forgottenPassword",
  authorizeNewPassword: "authorizeNewPassword"
};
export const authInitState = {
  login: {
    email: "",
    password: "",
  },
  register: {
    name: "",
    email: "",
    password: "",
    repassword: "",
    rules: false,
  },
  authorizeNewPassword: {
    password: "",
    repassword: "",
  },
  loginValidateInputs: {
    email: false,
    password: false,
  },
  registerValidateInputs: {
    name: false,
    email: false,
    password: false,
    repassword: false,
    rules: false,
  },
  authorizeNewPasswordValidateInputs: {
    password: false,
    repassword: false
  },
  loginUpdate: {
    email: false,
    password: false,
  },
  registerUpdate: {
    name: false,
    email: false,
    password: false,
    repassword: false,
    rules: false
  },
  authorizeNewPasswordUpdate: {
    password: false,
    repassword: false
  },
  loginFocus: {
    email: false,
    password: false,
  },
  registerFocus: {
    name: false,
    email: false,
    password: false,
    repassword: false
  },
  authorizeNewPasswordFocus: {
    password: false,
    repassword: false
  }
};
export const forgottenPasswordInitState = {
  forgottenPassword: {
    email: ""
  },
  forgottenPasswordValidate: {
    email: false
  },
  forgottenPasswordUpdate: {
    email: false
  },
  forgottenPasswordFocus: {
    email: false
  }
};