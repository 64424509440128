import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  totalFilesCount: null,
  uploadedFiles: [],
  isUploadedFilesSuccess: false,
  childUid: '',
  className: '',
  competitionName: '',
  competitionYear: '',
  questNumber: null
};

const getUploadedFilesData = (state, action) => {
  return updateObject(state, {
    totalFilesCount: action.data.totalFilesCount,
    uploadedFiles: action.data.uploadedFiles,
    className: action.data.className,
    childUid: action.data.childUid,
    competitionName: action.data.competitionName,
    competitionYear: action.data.competitionYear,
    questNumber: action.data.questNumber
  })
}

const isUploadedFilesSuccess = (state, action) => {
  return updateObject(state, {
    isUploadedFilesSuccess: action.state
  })
}

const uploadFiles = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_UPLOADED_FILES_DATA: return getUploadedFilesData(state, action);
    case actionTypes.SUCCESSFULLY_UPLOADED_FILES: return isUploadedFilesSuccess(state, action);

    default: return state
  }
}

export default uploadFiles;