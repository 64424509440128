const fnConfig = (payload, methodAction, dataType) => {

  let headers = headerConfig(dataType);

  if (methodAction === 'POST' && payload !== null) {
    return {
      method: methodAction,
      body: JSON.stringify(payload),
      headers: new Headers({
        ...headers
      }),
    };
  } else {
    return {
      method: methodAction,
      headers: new Headers({
        ...headers
      }),
    }
  }
}

const headerConfig = (dataType) => {
  let headers;

  if (dataType === "authorizationAccessToken") {
    let accesssToken = localStorage.getItem("easyMath");
    accesssToken = JSON.parse(accesssToken).token;

    headers = {
      Authorization: accesssToken,
      "Content-Type": "application/json",
    };
    return headers;

  } else {
    headers = {
      "Content-Type": "application/json"
    }
    return headers;
  }
}

export const getContentGeneric = async (url, payload, method, dataType) => {

  let response = (await fetch(url, fnConfig(payload, method, dataType))
    .then(res => res.json().then((body) => {

        // console.log('res, ', res);
        // console.log('body, ', body);
        return {
          ...body
        };
      })
    )
    .catch(err => {
      console.log('Error: ', err);
    }));

  return response
}