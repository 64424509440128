import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';

import * as actionsCreatorSubscription from "./actions";
import * as actionsCreatorAuth from "../../store/root_actions/auth";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";

import { promoCodeInitState } from '../../constants/add_subscriptions';
import SubscriptionsCompetitions from './subscriptions_competitions';

import Parser from 'html-react-parser';
import { isInputValid, checkAccessTokenExpirationTime, checkValidationFields } from "../../utils/helpers/common";

class SubscriptionBody extends PureComponent {

  static propTypes = {
    subscriptionFocus: PropTypes.object.isRequired,
    contest: PropTypes.object.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChangeInfo: PropTypes.func.isRequired,
    onChangeContestFields: PropTypes.func.isRequired,
    subscription: PropTypes.object.isRequired,
    subscriptionList: PropTypes.array.isRequired,
    accessTokenExprirationTime: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    notifySubscriptionDiscount: PropTypes.object.isRequired,
    refreshingTokenInPorgress: PropTypes.bool.isRequired,
    subscriptionModalState: PropTypes.bool.isRequired,
    subscriptionValidate: PropTypes.object.isRequired,
    contestUpdate: PropTypes.object.isRequired,
    contestValidate: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      ...promoCodeInitState,
      isSubmited: false
    };
  }
  componentWillUnmount() {
    if (this.props.notifySubscriptionDiscount.active) {
      this.props.notifyMessageReset("SubscriptionDiscount");
    }
  }

  componentDidUpdate() {

    if (!this.props.refreshingTokenInPorgress && this.state.isSubmited) {
      this.setState({
        ...promoCodeInitState,
        isSubmited: false
      });
    }

  }

  handleChange = (e) => {
    const target = e.currentTarget;
    const targetValue = target.value;
    const isValid = isInputValid(targetValue, 'PROMOCODE');

    this.setState({
      promoCode: targetValue,
      promoCodeValidate: isValid,
      promoCodeUpdate: true
    })
  }

  submitHandler = () => {
    const { accessTokenExprirationTime, userId } = this.props;
    const isAccessTokenExpired = checkAccessTokenExpirationTime(accessTokenExprirationTime);

    const data = {
      userId: userId,
      promoCode: this.state.promoCode
    }

    this.setState({
      isSubmited: true
    })

    if (isAccessTokenExpired) {
      this.props.authRefresh('promoCode', data);
    } else {
      this.props.deliverySubscriptionPromoCode(data);
    }
  }

  render() {

    const { subscriptionFocus, onFocus, onBlur, onChangeInfo, onChangeContestFields, subscription, subscriptionList, notifySubscriptionDiscount, subscriptionValidate, contest,
      contestValidate, contestUpdate } = this.props;
    const { promoCode, promoCodeValidate, promoCodeUpdate } = this.state;

    const isSubscriptionValid = checkValidationFields(subscriptionValidate);
    const isValid = isSubscriptionValid && promoCodeValidate;

    return (
      <div className="subscription-body">

        <fieldset className="form-field-set addSubscription-offset">
          <select id="subscriptions-list" name="subscriptionId" className={`form-dropdown ${subscriptionFocus.subscriptionId ? 'focus-bg' : ''}`}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChangeInfo}
            value={subscription.subscriptionId}>

            <option value="Абонаменти" disabled={true}>Абонаменти</option>
            { subscriptionList.length > 0
              ? subscriptionList.map((option) =>
                <option key={`subscriptionList${option.id}`} value={option.id} data-price={option.price} data-contest={option.isCompetition}>{option.name} - {option.price} лв.</option>
                )
              : null
            }
          </select>

        </fieldset>

        {
          subscription.subscriptionId !== ''
            ? subscriptionList.filter(ele => ele.id === subscription.subscriptionId).map((ele, index) => {

                if (ele.isCompetition === true) {

                  return <Fragment key={`subscription-info-${index}`}>

                    {
                      ele.body !== '' ? <fieldset className="subscription-annotation form-field-set addSubscription-offset">{Parser(ele.body)}</fieldset> : null
                    }

                    <SubscriptionsCompetitions
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onChangeContestFields={onChangeContestFields}
                      contest={contest}
                      contestValidate={contestValidate}
                      contestUpdate={contestUpdate}
                      isFocus={subscriptionFocus}
                      subscription={subscription} />
                  </Fragment>

                } else if (ele.body !== '') {
                  return <fieldset className="subscription-annotation form-field-set addSubscription-offset" key={`subscription-info-${index}`}>{Parser(ele.body)}</fieldset>
                } else {
                  return null;
                }
              })
            : null
        }

        <div className="subscription-invoice-field">

          <fieldset className="form-field-set">
            <label htmlFor="subscription-invoice" className="subscription-label form-label label-blue">Желаете ли фактура:</label>
            <input type="checkbox" id="subscription-invoice" name="invoice" className={`form-checkbox`} onChange={onChangeInfo} checked={subscription.invoice} />
            <label htmlFor="subscription-invoice" className="form-checked-tick border-blue"><i className="fas fa-check"></i></label>
          </fieldset>

          <div className="subscription-invoice-subField">
            <fieldset className="form-field-set">
              <input type="text" id="subscription-promo" name="promoCode" value={promoCode}
                className={`white-autofill form-input input-blue ${!promoCodeValidate && promoCodeUpdate ? 'invalid ' : ''}`}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={this.handleChange}
                placeholder="Забележка"/>
              <label className={`form-label-focus focus-white ${subscriptionFocus.promoCode ? 'focus-animate' : ''} ${!promoCodeValidate && promoCodeUpdate ? 'invalid ' : ''}`}></label>

              <label htmlFor="subscription-promo" className="form-label form-label-error">
                {!promoCodeValidate && promoCodeUpdate && promoCode !== '' ? 'Въвеждате забранени символи (3-12)' : ''}
                {notifySubscriptionDiscount.htmlTemplate === "subscriptionDiscountError" && promoCode === '' ? notifySubscriptionDiscount.messageError : ''}
                {notifySubscriptionDiscount.htmlTemplate === "subscriptionDiscountSuccess" && promoCode === '' ? Parser(notifySubscriptionDiscount.messageBody.replace('OFF', '%')) : ''}
              </label>

            </fieldset>

            <fieldset className="form-field-set">
              <button type="button" className="easy-math-button btn-blue subscription-size no-padding" title="Въведи"
                disabled={!isValid}
                onClick={this.submitHandler}>
                  Въведи
              </button>
            </fieldset>
          </div>
        </div>

      </div>
    );
  }
}
const mapStateToProps = ({ addSubscription, authedUser, notifications }) => {

  return {
    userId: authedUser.userId,
    accessTokenExprirationTime: authedUser.accessTokenExprirationTime,
    refreshingTokenInPorgress: authedUser.refreshingTokenInPorgress,
    subscriptionList: addSubscription.subscriptionList,
    subscriptionModalState: addSubscription.subscriptionModalState,
    notifySubscriptionDiscount: notifications.subscriptionDiscount
  };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
    ...actionsCreatorSubscription,
    ...actionsCreatorAuth,
    ...actionsCreatorNotify
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionBody);