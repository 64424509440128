import React, { PureComponent  } from 'react';
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import './back_to_top.scss';

class BackToTop extends PureComponent  {

  static propTypes = {
    id: PropTypes.string,
    range: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isScrollActive: false
    }
  }

  componentDidMount() {
    if (this.props.id !== undefined) {
      const wrapper = document.getElementById(`${this.props.id}`);
      wrapper.addEventListener("scroll", this.onScroll);
    } else {
      document.addEventListener("scroll", this.onScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.id !== undefined) {

      const wrapper = document.getElementById(`${this.props.id}`);
      wrapper.removeEventListener('scroll', this.onScroll);

    } else {
      document.removeEventListener('scroll', this.onScroll);
    }

    if(this.state.isScrollActive) {
      this.setState({isScrollActive: false})
    }
  }

  onScroll = () => {
    if (this.props.id !== undefined) {

      const wrapper = document.getElementById(`${this.props.id}`);
      this.showToTopButton(wrapper);

    } else {
      this.showToTopButton(document.documentElement);
    }
  }

  showToTopButton = (handler) => {
    if (handler.scrollTop > this.props.range) {

      if(!this.state.isScrollActive) {
        this.setState({isScrollActive: true});
      }
    } else {
      if(this.state.isScrollActive) {
        this.setState({isScrollActive: false});
      }
    }
  }

  // scrollToTop = () => {
  //   if (this.props.id !== undefined) {
  //     const wrapper = document.getElementById(`${this.props.id}`);

  //     wrapper.scrollTo({
  //       top: 0,
  //       behavior: "smooth"
  //     });
  //   } else {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth"
  //     });
  //   }
  // }

  scrollToTop = (pos, time) => {

    let currentPosition;
    const wrapper = document.getElementById(`${this.props.id}`);

    if(isNaN(pos)) {
      const error = new Error('Position must be a number!')
      throw error
    }

    if(pos < 0) {
      const error = new Error('Position can not be negative!')
      throw error
    }

    if (this.props.id !== undefined) {
      currentPosition = wrapper.scrollTop;

    } else {
      currentPosition = window.scrollY || window.screenTop;
    }

    if(currentPosition < pos) {
      let t = 10;

      for(let i = currentPosition; i <= pos; i+=10) {
        t+=10;

        setTimeout(() => {
          if (this.props.id !== undefined) {
            wrapper.scrollTo(0, i);
          } else {
            window.scrollTo(0, i);
          }
        }, t/2);

      }
    } else {
      time = time || 2;

      let i = currentPosition;
      let x;

      x = setInterval(() => {

        if (this.props.id !== undefined) {
          wrapper.scrollTo(0, i);
        } else {
          window.scrollTo(0, i);
        }

        i -= 10;

        if(i <= pos) {
          clearInterval(x);
        }
      }, time);
    }
  }

	render() {
    const { isScrollActive } = this.state;

		return (
      <div className={`back-to-top ${isScrollActive ? 'active fade-in' : ''}`} onClick={ () => this.scrollToTop(Number(0)) }>
        <Link to="#" className={`back-to-top-btn`} title="Най-горе"><i className="fas fa-angle-up"></i></Link>
      </div>
    );
	}
}

export default BackToTop;