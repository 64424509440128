import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  content: {
    contents: [],
    total_count: 1,
    total_exercises: 1,
  },
  info: {
    loaded: false,
    contests: {
      name: "",
      body: ""
    }
  },
  currentContestInfo: {
    body: "",
    name: "",
    id: "",
    free: "",
    class: "",
    year: "",
    remainingTime: 0
  },
  currentMathExercise: {
    answers: [],
    exercise: []
  },
  currentMathExerciseError: false,
  filters: {
    loaded: false,
    classes: [],
    contestTypes: [],
    years: []
  },
  exerciseAnswersStatus: [],
  calculatedAnswerResults: {
    totalEarnedPoints: 0,
    totalPoints: 0,
    results: []
  },
  contestLiterature: {
    contest: [],
    loaded: false,
  },
  answerComparison: {
    question: []
  }

}

const getContentSuccess = (state, action) => {
  
  return updateObject(state, {
    content: action.content
  })
}

const getContentModalSuccess = (state, action) => {

  return updateObject(state, {
    info: action.info
  })
}

const getCurrentMathFilters = (state, action) => {

  return updateObject(state, {
    filters: action.filters
  })
}

const getCurrentContestInfoSuccess = (state, action) => {
  
  const updatedContestInfo = {
    ...action.contestInfo.contest[0], 
    hasTimerDuration: !!parseInt(action.contestInfo.contest[0].durationInMinutes)                             
  }                         

  localStorage.setItem('currentContestInfo', JSON.stringify(updatedContestInfo));
  
  // in case you land on the info page, it means you are just starting the test 
  // and in case you have some stored answers data, we remove them
  if (localStorage.getItem('exerciseAnswersStatus') !== undefined && localStorage.getItem('exerciseAnswersStatus') !== null) {
    localStorage.removeItem('exerciseAnswersStatus');
  }
    

  return updateObject(state, {
    currentContestInfo: updatedContestInfo
  })
}

const getCurrentMathExerciseSuccess = (state, action) => {

  let updatedAction = {
    answers: action.info.answers,
    exercise: action.info.exercise
  }
  // in case the key:
  // exerciseAnswersStatus
  // is already populated, don't do anything 
  if (state.exerciseAnswersStatus.length > 0){

    return updateObject(state, {
      currentMathExercise: updatedAction
    })
  } else if (localStorage.getItem('exerciseAnswersStatus') !== undefined && localStorage.getItem('exerciseAnswersStatus') !== null) {
    // this case covers the moment when the user has started the test
    // refreshed and lost the answers to the questions at the redux store
    // and in this case, we take the questions answers from the localstorage if they exist
    let exerciseAnswersStatusFromStore = JSON.parse(localStorage.getItem('exerciseAnswersStatus'));

    return updateObject(state, {
      currentMathExercise: updatedAction,
      exerciseAnswersStatus: exerciseAnswersStatusFromStore
    })
  } else {
    // in order to keep track of answered and not answer questions we keep 
    // a separate key holding record on how the quiz the going

    // as we get the total number of pages and a pagination needs to be build
    // the id, which corresponds to the itterator is pushed to handmage array
    let arrayMadeByHand = [];
  
    for (let itterator = 1; itterator <= action.info.total_count; itterator++) {

      if (itterator === 1 && action.info.exercise[0].no_answer === "1" && action.info.exercise[0].number === "1"){

        arrayMadeByHand.push({
          countNumber: itterator,
          wasItAnswered: true,
          no_answer: true,
          answer: {},
          exerciseId: action.info.exercise[0].id,
          isOpenAnswer: ""
        });
      } else {
        
        arrayMadeByHand.push({
          countNumber: itterator,
          wasItAnswered: false,
          answer: {},
          exerciseId: "",
          isOpenAnswer: ""
        });  
      }
    }
  
    return updateObject(state, {
      currentMathExercise: updatedAction,
      exerciseAnswersStatus: arrayMadeByHand
    })
  }
}

const getCurrentMathExerciseFailure = (state, action) => {

  return updateObject(state, {
    currentMathExerciseError: action.error
  })
}

const updateMathQuizSingleAnswer = (state, action) => {

  // this case covers refresh, when you have stored answers in localstorage
  // and you want to update an answer
  if (!state.exerciseAnswersStatus.length && localStorage.getItem('exerciseAnswersStatus') !== undefined && localStorage.getItem('exerciseAnswersStatus') !== null){
    state.exerciseAnswersStatus = JSON.parse(localStorage.getItem('exerciseAnswersStatus'));
  }

  if (action.quizAnswerData.answer[0].no_answer === "0") {

    let filteredEmptyAnswers = action.quizAnswerData.answer.filter(singleAnswer => singleAnswer.answer !== "");
    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].wasItAnswered = filteredEmptyAnswers.length > 0 ? true : false;
    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].isOpenAnswer = action.quizAnswerData.isOpenAnswer;
    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].exerciseId = state.currentMathExercise.exercise[0].id;
    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].answer[state.currentMathExercise.exercise[0].id] = action.quizAnswerData.answer;
    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].no_answer = false;
  } else {

    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].wasItAnswered = true;
    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].no_answer = true;
    state.exerciseAnswersStatus[action.quizAnswerData.pageNum].exerciseId = action.quizAnswerData.exerciseId;
  }

  localStorage.setItem('exerciseAnswersStatus', JSON.stringify(state.exerciseAnswersStatus));

  return state;
}

const mathContestMassResetState = (state) => {

  localStorage.removeItem('timerData')
  localStorage.removeItem('exerciseAnswersStatus');
  localStorage.removeItem('currentContestInfo');

  state = initialState;
  return state;
}

const mathContestCalculateResultsSuccess = (state, action) => {

  return updateObject(state, {
    calculatedAnswerResults: action.calculatedAnswerResults
  })
}

const mathContestResultsLiterature = (state, action) => {

  return updateObject(state, {
    contestLiterature: action.contestLiterature
  })
}

const mathContestResultsAnswerComparison = (state, action) => {

  return updateObject(state, {
    answerComparison: action.answerComparison
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_CONTEST_LIST_SUCCESS: return getContentSuccess(state, action);
    case actionTypes.GET_CONTENT_CONTEST_INFO_SUCCESS: return getContentModalSuccess(state, action);
    case actionTypes.GET_CONTENT_CURRENT_CONTEST_INFO_SUCCESS: return getCurrentContestInfoSuccess(state, action);
    case actionTypes.GET_CONTENT_CURRENT_CONTEST_EXERCISE_SUCCESS: return getCurrentMathExerciseSuccess(state, action);
    case actionTypes.GET_CONTENT_CURRENT_CONTEST_EXERCISE_FAILURE: return getCurrentMathExerciseFailure(state, action);
    case actionTypes.GET_CONTENT_CONTEST_FILTERS_SUCCESS: return getCurrentMathFilters(state, action);
    case actionTypes.UPDATE_MATH_QUIZ_SINGLE_ANSWER: return updateMathQuizSingleAnswer(state, action);
    case actionTypes.MATH_CONTEST_MASS_RESET_STATE: return mathContestMassResetState(state);
    case actionTypes.MATH_CONTEST_CALCULATE_RESULTS_SUCCESS: return mathContestCalculateResultsSuccess(state, action);
    case actionTypes.GET_MATH_CONTEST_RESULTS_LITERATURE_SUCCESS: return mathContestResultsLiterature(state, action);
    case actionTypes.GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON_SUCCESS: return mathContestResultsAnswerComparison(state, action);
    default: return state;
  }
}

export default reducer;