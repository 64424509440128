import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NotifyError from "../shared/ui/notifications/notify_error";

class InvoiceHeader extends PureComponent {

  static propTypes = {
    selectInvoice: PropTypes.string.isRequired,
    onChangeInfo: PropTypes.func.isRequired,
    invoiceType: PropTypes.array.isRequired
  }

  render() {

    const { selectInvoice, onChangeInfo, invoiceType } = this.props;

    return (
      <div className={`choice-of-invoice ${selectInvoice !== '' ? '' : 'choice-invoice-offset'}`}>
        {
          invoiceType.length > 0
            ? invoiceType.sort((a, b) => b.id - a.id).map((invoice) => {

              if (invoice.type !== null && invoice.type !== '') {
                return (
                  <fieldset className="form-field-set addSubscription-offset no-marginBottom" key={`invoiceType${invoice.id}`}>

                    <input type="radio"
                      id={`${invoice.type}-person${invoice.id}`}
                      name="selectInvoice"
                      value={invoice.type}
                      className="form-radio"
                      onChange={onChangeInfo}
                      checked={selectInvoice === invoice.type}
                      data-id={invoice.id} />

                    <label htmlFor={`${invoice.type}-person${invoice.id}`} className="form-radio-checked border-blue"></label>
                    <label htmlFor={`${invoice.type}-person${invoice.id}`} className="form-label subscription-label label-blue">{invoice.name}</label>
                  </fieldset>
                )
              } else {
                return <NotifyError classStyle={'notify-white'} key={`error-invoice${invoice.id}`}
                  messageError={'Възникна грешка <span class="error-offset"> при избор на тип за фактура!</span>'}/>
              }
            }
              )
            : null
        }
      </div>
    )
  }
}
const mapStateToProps = ({ addSubscription }) => {

  return {
    invoiceType: addSubscription.invoiceType
  };
};


export default connect(mapStateToProps)(InvoiceHeader);