import { call, put } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorContactUs from "./actions";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";
import * as actionsCreatorLoading from "../../store/root_actions/loading";

export function* getPageContentPartialMission(action) {

  yield put(actionsCreatorLoading.loadingStart(action.typeMission));

  const url = `/API/partial/${action.typeMission}`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.typeMission));

    } else {
      yield put(actionsCreatorLoading.loadingStop(action.typeMission));

      const data = response.body.data;
      const newMissionData = {
        loaded: true,
        name: data.mission.name,
        body: data.mission.body
      }
      yield put(actionsCreatorContactUs.getPageContentSuccess(newMissionData));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.typeMission));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.typeMission,
      htmlTemplate: `${action.typeMission}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}