import { combineReducers } from 'redux';

import authedUser from './auth';
import accountStats from "./../../components/account_stats/reducer";
import loading from './loading';
import notifications from './notifications';
import aboutUsPage from './../../components/page_about_us/reducer';
import subscriptionsPage from './../../components/page_subscriptions/reducer';
import contact from './../../components/page_contact_us/reducer';
import news from './../../components/page_news/reducer';
import partialMission from './../../components/mission_small/reducer';
import partialSubscriptions from './../../components/sumbscriptions_small/reducer';
import videoQuizText from './../../components/jumbotron_cta/reducer';
import generalTermsPage from './../../components/page_general_terms/reducer';
import privateDataPage from './../../components/page_private_data/reducer';
import authorPage from './../../components/page_author/reducer';
import faqPage from './../../components/page_faq/reducer';
import addSubscription from './../../components/add_subscription/reducer';
import videoList from './../../components/video-tutoring/reducer';
import mathPlatform from './../../components/math_quiz/reducer';
import onlineContest from './../../components/online_contest/reducer';
import modal from './../../components/shared/ui/modal/reducer';
import introCompetitions from './../../components/intro_competitions/reducer';
import uploadFiles from './../../components//upload_files/reducer';
import headerBar from './../../components/header_bar/reducer'

export default combineReducers(
  {
    authedUser,
    accountStats,
    loading,
    notifications,
    aboutUsPage,
    subscriptionsPage,
    partialMission,
    partialSubscriptions,
    videoQuizText,
    contact,
    news,
    generalTermsPage,
    privateDataPage,
    authorPage,
    faqPage,
    addSubscription,
    videoList,
    mathPlatform,
    onlineContest,
    modal,
    introCompetitions,
    uploadFiles,
    headerBar
  }
)