export const GET_CONTENT_PARTIAL_MISSION = 'GET_CONTENT_PARTIAL_MISSION';
export const GET_CONTENT_PARTIAL_MISSION_SUCCESS = 'GET_CONTENT_PARTIAL_MISSION_SUCCESS'

export function getPageContent() {
  return {
    type: GET_CONTENT_PARTIAL_MISSION,
    typeMission: 'mission'
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_PARTIAL_MISSION_SUCCESS,
    content
  }
}