import { call, put } from "redux-saga/effects";
import { fetchingData } from '../../utils/services/fetching_data';

import * as actions from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";

export function* introCompetitions(action) {

  yield put(actionsCreatorLoading.loadingStart(action.introContestType));

  const url = `/API/competitions/${action.competitionsAuthorize}`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {
    yield put(actionsCreatorLoading.loadingStop(action.introContestType));

    if (!response.ok) {

    } else {
      const data = response.body.data;
      yield put(actions.getIntroCompetitionsData(data));
    }
  } catch (error) {
    yield put(actionsCreatorLoading.loadingStop(action.introContestType));

    console.log('====================================');
    console.log(error);
    console.log('====================================');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.introContestType,
      htmlTemplate: `${action.introContestType}CatchError`,
      active: true,
      closeNotify: true,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));

  }
}