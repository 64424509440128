export const GET_CONTENT_PARTIAL_SUBSCRIPTIONS = 'GET_CONTENT_PARTIAL_SUBSCRIPTIONS';
export const GET_CONTENT_PARTIAL_SUBSCRIPTIONS_SUCCESS = 'GET_CONTENT_PARTIAL_SUBSCRIPTIONS_SUCCESS'

export function getPageContent() {
  return {
    type: GET_CONTENT_PARTIAL_SUBSCRIPTIONS,
    typeSubscription: 'topSubscriptions',
    typePartialSubscription: 'partialSubscriptions'
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_PARTIAL_SUBSCRIPTIONS_SUCCESS,
    content
  }
}