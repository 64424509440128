import React from 'react';

export default function MathLogoSvg(props){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="61.867" height="45.685" viewBox="0 0 61.867 45.685" className="math-logo-svg">
      <g id="Group_56" data-name="Group 56" transform="translate(-360.117 -1560)">
        <g id="Group_51" data-name="Group 51" transform="translate(360.117 1560)">
          <g id="Group_50" data-name="Group 50" transform="translate(0 0)">
            <path id="Path_39" data-name="Path 39" d="M376,264.169V295.8h10.543V272.178l18.225,10.788v-18.8Z" transform="translate(-376.003 -264.169)"/>
            <path id="Path_40" data-name="Path 40" d="M452.859,264.169V295.8H442.317V272.178l-18.225,10.788v-18.8Z" transform="translate(-390.992 -264.169)"/>
          </g>
          <path id="Path_41" data-name="Path 41" d="M413.543,295.517,397.2,285.254v31.172h32.691V285.254Z" transform="translate(-382.61 -270.741)"/>
        </g>
      </g>
    </svg>
  )
}