import { call, put } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';

import * as actionsCreators from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";

export function* getVideoQuiz(action) {

  yield put(actionsCreatorLoading.loadingStart(action.loadingType));

  if (action.quizType === 'videoLessons') {

    const url = `/API/pages/${action.quizType}`;
    const response = yield call(fetchingData, url, null, 'GET');

    try {

      if (!response.ok) {
        yield put(actionsCreatorLoading.loadingStop(action.loadingType));

      } else {
        yield put(actionsCreatorLoading.loadingStop(action.loadingType));

        const data = response.body.data.teachs;
        const newData = {
          name: data.name,
          body: data.body,
          loaded: true
        }
        yield put(actionsCreators.getVideoLessons(newData));
      }
    }
    catch (error) {
      yield put(actionsCreatorLoading.loadingStop(action.loadingType));

      console.log('------------------------------------');
      console.log(error);
      console.log('------------------------------------');

      yield put(actionsCreatorNotify.notifyMessages({
        type: action.loadingType,
        htmlTemplate: `${action.loadingType}CatchError`,
        active: true,
        closeNotify: true,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    }
  }

  if (action.quizType === 'examPreparation') {

    const url = `/API/pages/${action.quizType}`;
    const response = yield call(fetchingData, url, null, 'GET');

    try {

      if (!response.ok) {
        yield put(actionsCreatorLoading.loadingStop(action.loadingType));

      } else {
        yield put(actionsCreatorLoading.loadingStop(action.loadingType));

        const data = response.body.data.contests;
        const newData = {
          name: data.name,
          body: data.body,
          loaded: true
        }
        yield put(actionsCreators.getExamPreparation(newData));
      }
    }
    catch (error) {
      yield put(actionsCreatorLoading.loadingStop(action.loadingType));

      console.log('------------------------------------');
      console.log(error);
      console.log('------------------------------------');

      yield put(actionsCreatorNotify.notifyMessages({
        type: action.loadingType,
        htmlTemplate: `${action.loadingType}CatchError`,
        active: true,
        closeNotify: true,
        messageError: 'Възникна грешка!',
        messageHeading: 'Моля, пробвайте отново или ни пишете на:',
        messageBody: 'Благодарим Ви!'
      }));
    }
  }
}