import React from 'react';

import asyncComponent from './../hoc/async-component/async-component';

/**
 * Component inclusion using hoc function
 * the returned component is used at { routesArray }
 */
const AsyncPageLanding = asyncComponent(() => import ('./../components/page_landing'));
const AsyncPageAboutUs = asyncComponent(() => import ('./../components/page_about_us'));
const AsyncPageNews = asyncComponent(() => import ('./../components/page_news'));
const AsyncPageContactUs = asyncComponent(() => import ('./../components/page_contact_us'));
const AsyncPageSubscriptions = asyncComponent(() => import ('./../components/page_subscriptions'));
const AsyncVideoTutor = asyncComponent(() => import ('./../components/video-tutoring'));
const MathTutor = asyncComponent(() => import ('./../components/math_quiz'));
const AsyncAuthFP = asyncComponent(() => import ('./../components/authorize_codes/authorize_codes'));
const AsyncArticle = asyncComponent(() => import ('./../components/page_news/article'));
const AsyncGeneralTerms = asyncComponent(() => import ('./../components/page_general_terms'));
const AsyncPrivateData = asyncComponent(() => import ('./../components/page_private_data'));
const AsyncAuthorPage = asyncComponent(() => import ('../components/page_author'));
const AsyncFaqPage = asyncComponent(() => import ('../components/page_faq'));
const AsyncPlatformLanding = asyncComponent(() => import ('../components/page_platform_landing'));
const AsyncMobileUpload = asyncComponent(() => import('./../components/page_mobile_image_upload'));

/**
 * @const {array} routesArray
 * each object entry holds:
 * if it's an exact view or not (optional)
 * path to view (mandatory)
 * mainComponent relative to the view (mandatory)
 * note: please make sure to pass "props" as an argument
 * to the anonymous function returning the Component
 */

export const routesArray = [
  {
    'exact': true,
    'path': '/',
    'mainComponent': (props) => <AsyncPageLanding {...props} />
  },
  {
    'path': '/about-us',
    'mainComponent': (props) => <AsyncPageAboutUs {...props} />
  },
  {
    'exact': true,
    'path': '/news/:id',
    'mainComponent': (props) => <AsyncPageNews {...props} />
  },
  {
		'path': '/article/:id',
		'mainComponent': (props) => <AsyncArticle {...props} />
	},
  {
    'path': '/contact-us',
    'mainComponent': (props) => <AsyncPageContactUs {...props} />
  },
  {
    'path': '/subscriptions',
    'mainComponent': (props) => <AsyncPageSubscriptions {...props} />
  },
  {
    'path': '/video-tutorials',
    'mainComponent': (props) => <AsyncVideoTutor {...props} />
  },
  {
    'path': '/math-quiz',
    'mainComponent': (props) => <MathTutor {...props} />
  },
  {
    'path': '/callback',
    'mainComponent': (props) => <AsyncAuthFP {...props} />
  },
  {
    'path': '/general-terms',
    'mainComponent': (props) => <AsyncGeneralTerms {...props} />
  },
  {
    'path': '/private-data',
    'mainComponent': (props) => <AsyncPrivateData {...props} />
  },
  {
    'path': '/author-details',
    'mainComponent': (props) => <AsyncAuthorPage {...props} />
  },
  {
    'exact': true,
    'path': '/faq/:category',
    'mainComponent': (props) => <AsyncFaqPage {...props} />
  },
  {
    'path': '/platform',
    'mainComponent': (props) => <AsyncPlatformLanding {...props} />
  }, 
  {
    'path': '/mobile-upload',
    'mainComponent': (props) => <AsyncMobileUpload {...props} />
  }
]