import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  categories:[],
  news:[],
  article: {},
  articleImages: [],
  articleCategories: [],
  totalCount: 0
}

const getNewsCategoriesData = (state, action) => {
  return updateObject(state, {
    categories: action.data
  })
}
const getArticles = (state, action) => {
  return updateObject(state, {
    news: action.data.news,
    totalCount: action.data.total_count
  })
}
const getArticle = (state, action) => {

  return updateObject(state, {
    article: action.data.news_data,
    articleImages: action.data.news_pictures,
    articleCategories: action.data.categories
  })
}

const news = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.GET_NEWS_CATEGORIES_DATA: return getNewsCategoriesData(state, action);
    case actionTypes.GET_ARTICLES: return getArticles(state, action);
    case actionTypes.GET_ARTICLE: return getArticle(state, action);
    default: return state
  }
}

export default news;