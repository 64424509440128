import React, { PureComponent } from 'react';
import ReactTooltip from "react-tooltip";
import PropTypes from 'prop-types'

class SubsriptionFooter extends PureComponent {

  static propTypes = {
    onChangeInfo: PropTypes.func.isRequired,
    subscriptionPayment: PropTypes.string.isRequired,
  }

  render() {
    const { onChangeInfo, subscriptionPayment } = this.props;

    return (
      <div className="subscription-payment-field">
        <h3 className="subscription-payment-heading">Начин на плащане:</h3>

        <fieldset className="form-field-set addSubscription-offset">
          <input type="radio" id="subscription-bank" name="payment" value="bank" className="form-radio" onChange={onChangeInfo} checked={subscriptionPayment === 'bank'} />
          <label htmlFor="subscription-bank" className="form-radio-checked border-blue"></label>
          <label htmlFor="subscription-bank" className="form-label subscription-label label-blue">По банков път</label>
        </fieldset>

        <fieldset className="form-field-set addSubscription-offset">
          <input type="radio" id="subscription-post" name="payment" value="epay" className="form-radio" onChange={onChangeInfo} checked={subscriptionPayment === 'epay'} />
          <label htmlFor="subscription-post" className="form-radio-checked border-blue"></label>
          <label htmlFor="subscription-post" className="form-label subscription-label label-blue">ePay / с карта / каса Изипей</label>
          <label
            className="form-label subscription-label label-blue label-tooltip"
            data-tip="ePay предлага плащане с ePay профил, на каса, с карта или на банкомат."
            data-for="subscription-tooltip"
            data-class="subscription-tooltip"
            data-background-color="#055187"
            data-text-color="#fff">
              <i className="fas fa-question-circle"></i>
          </label>
        </fieldset>

        <ReactTooltip effect="solid" id="subscription-tooltip" />

      </div>
    )
  }
}

export default SubsriptionFooter;