export const GET_ONLINE_CONTEST_INFO = "GET_ONLINE_CONTEST_INFO";
export const GET_ONLINE_CONTEST_INFO_SUCCESS = "GET_ONLINE_CONTEST_INFO_SUCCESS"
export const GET_ONLINE_CONTEST_EXERCISE = "GET_ONLINE_CONTEST_EXERCISE";
export const GET_ONLINE_CONTEST_EXERCISE_SUCCESS = "GET_ONLINE_CONTEST_EXERCISE_SUCCESS";
export const GET_ONLINE_CONTEST_EXERCISE_FAILURE = "GET_ONLINE_CONTEST_EXERCISE_FAILURE";
export const UPDATE_ONLINE_CONTEST_SINGLE_ANSWER = "UPDATE_ONLINE_CONTEST_SINGLE_ANSWER";
export const ONLINE_CONTEST_MASS_RESET_STATE = "ONLINE_CONTEST_MASS_RESET_STATE";
export const ONLINE_CONTEST_CALCULATE_RESULTS = "ONLINE_CONTEST_CALCULATE_RESULTS";
export const ONLINE_CONTEST_CALCULATE_RESULTS_SUCCESS = "ONLINE_CONTEST_CALCULATE_RESULTS_SUCCESS";

export function getOnlineContestInfo(id) {
  return {
    type:  GET_ONLINE_CONTEST_INFO,
    id,
    contestType: "contestInfo"
  }
}

export function getOnlineContestInfoSuccess(contestInfo) {
  return {
    type:  GET_ONLINE_CONTEST_INFO_SUCCESS,
    contestInfo
  }
}

export function getOnlineContestExercise(id, pageNumber) {
  return {
    type: GET_ONLINE_CONTEST_EXERCISE,
    id,
    pageNumber,
    contestType: 'contestExercise'
  }
}

export function getOnlineContestExerciseSuccess(info) {
  return {
    type: GET_ONLINE_CONTEST_EXERCISE_SUCCESS,
    info
  }
}

export function getOnlineContestExerciseFailure(error) {
  return {
    type: GET_ONLINE_CONTEST_EXERCISE_FAILURE,
    error
  }
}

export function updateOnlineContestSingleAnswer(quizAnswerData) {
  return {
    type: UPDATE_ONLINE_CONTEST_SINGLE_ANSWER,
    quizAnswerData
  }
}

export function OnlineContestMassResetState() {
  return {
    type: ONLINE_CONTEST_MASS_RESET_STATE
  }
}

export function OnlineContestCalculateResults(clientAnswers, contestId) {
  return {
    type: ONLINE_CONTEST_CALCULATE_RESULTS,
    clientAnswers,
    contestId,
    pageType: 'calculateResult'
  }
}

export function OnlineContestCalculateResultsSuccess(contestSubmissionMessage) {
  return {
    type: ONLINE_CONTEST_CALCULATE_RESULTS_SUCCESS,
    ...contestSubmissionMessage
  }
}