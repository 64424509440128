export const GET_CONTENT_VIDEO_LIST = 'GET_CONTENT_VIDEO_LIST';
export const GET_CONTENT_VIDEO_LIST_SUCCESS = 'GET_CONTENT_VIDEO_LIST_SUCCESS'
export const GET_CONTENT_VIDEO_INFO = 'GET_CONTENT_VIDEO_INFO';
export const GET_CONTENT_VIDEO_INFO_SUCCESS = 'GET_CONTENT_VIDEO_INFO_SUCCESS';
export const GET_CONTENT_CURRENT_VIDEO = "GET_CONTENT_CURRENT_VIDEO";
export const GET_CONTENT_CURRENT_VIDEO_SUCCESS = "GET_CONTENT_CURRENT_VIDEO_SUCCESS"
export const GET_CONTENT_CURRENT_VIDEO_EXERCISE = "GET_CONTENT_CURRENT_VIDEO_EXERCISE";
export const GET_CONTENT_CURRENT_VIDEO_EXERCISE_SUCCESS = "GET_CONTENT_CURRENT_VIDEO_EXERCISE_SUCCESS";

export function getPageContent(activePage, itemsPerPage) {
  return {
    type: GET_CONTENT_VIDEO_LIST,
    videoType: "videoList",
    activePage,
    itemsPerPage
  }
}

export function getPageContentSuccess(content) {
  return {
    type: GET_CONTENT_VIDEO_LIST_SUCCESS,
    content
  }
}

export function getModalContent() {
  return {
    type: GET_CONTENT_VIDEO_INFO,
    infoType: 'info',
    modalVideoInfoType: 'modalVideoInfo'
  }
}

export function getModalContentSuccess(data) {
  return {
    type: GET_CONTENT_VIDEO_INFO_SUCCESS,
    data
  }
}

export function getCurrentVideoInfo(id) {
  return {
    type: GET_CONTENT_CURRENT_VIDEO,
    id
  }
}

export function getCurrentVideoInfoSuccess(info) {
  return {
    type: GET_CONTENT_CURRENT_VIDEO_SUCCESS,
    info
  }
}

export function getCurrentVideoExerciseInfo(id, pageNumber) {
  return {
    type: GET_CONTENT_CURRENT_VIDEO_EXERCISE,
    id,
    pageNumber,
    videoType: 'exercises'
  }
}

export function getCurrentVideoExerciseInfoSuccess(info) {
  return {
    type: GET_CONTENT_CURRENT_VIDEO_EXERCISE_SUCCESS,
    info
  }
}