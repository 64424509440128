import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import './spinner.scss';

class Spinner extends PureComponent {

  static propTypes = {
    classColor: PropTypes.string.isRequired
	}

	render() {
		const { classColor } = this.props;

		return (
      <div className={`spinner-wrapper ${classColor}`}>
        <div className="spinner"></div>
      </div>
    );
	}

}

export default Spinner;