import { call, put } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorAboutUs from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";

export function* getPageContentAboutUs(action) {

  yield put(actionsCreatorLoading.loadingStart(action.pageType));

  const url = `/API/pages/${action.pageType}`;
  const response = yield call(fetchingData, url, null, 'GET');

  try {

    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.pageType));

    } else {

      yield put(actionsCreatorLoading.loadingStop(action.pageType));

      const data = response.body.data;
      const newData = {
        loaded: true,
        ...data
      }

      yield put(actionsCreatorAboutUs.getPageContentSuccess(newData));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.pageType));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.pageType,
      htmlTemplate: `${action.pageType}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}