import { call, put } from 'redux-saga/effects';
import { fetchingData } from '../../utils/services/fetching_data';
import * as actionsCreatorContactUs from "./actions";
import * as actionsCreatorLoading from "../../store/root_actions/loading";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";

export function* getPageContentPartialSubscriptions(action) {

  yield put(actionsCreatorLoading.loadingStart(action.typePartialSubscription));
  
  const url = `/API/partial/${action.typeSubscription}`;
  const response = yield call(fetchingData, url, null, 'GET');
  
  try {
    
    if (!response.ok) {
      yield put(actionsCreatorLoading.loadingStop(action.typePartialSubscription));
      
    } else {
      yield put(actionsCreatorLoading.loadingStop(action.typePartialSubscription));
      
      const data = response.body.data;
      const newData = {
        items: data,
        loaded: true
      }
      
      yield put(actionsCreatorContactUs.getPageContentSuccess(newData));
    }
  }
  catch(error) {
    yield put(actionsCreatorLoading.loadingStop(action.typePartialSubscription));

    console.log('------------------------------------');
    console.log(error);
    console.log('------------------------------------');

    yield put(actionsCreatorNotify.notifyMessages({
      type: action.typePartialSubscription,
      htmlTemplate: `${action.typePartialSubscription}CatchError`,
      active: true,
      closeNotify: false,
      messageError: 'Възникна грешка!',
      messageHeading: 'Моля, пробвайте отново или ни пишете на:',
      messageBody: 'Благодарим Ви!'
    }));
  }

}