import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import Parser from 'html-react-parser';
import './notifications.scss';

class NotifyError extends PureComponent {

  static propTypes = {
    messageError: PropTypes.string.isRequired,
    classStyle: PropTypes.string.isRequired,
  }

  render() {
    const { messageError, classStyle } = this.props;

    return (
      <div className={`notify-relative active ${classStyle}`}>
        <div className="notify">
          <p className="notify-title">
            <i className="fas fa-exclamation-triangle"></i>
            <span className="notify-heading-error">{Parser(messageError)}</span>
          </p>
        </div>
      </div>
    )
  }
}

export default NotifyError;