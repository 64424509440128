export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties
	}

};
export const updateObjectDeep = (state, type, updatedProperties) => {

	return {
    ...state,
    [type]: {
      ...state[type],
      ...updatedProperties,
    },
  };
};