import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import Parser from "html-react-parser";
import "./notifications.scss";
import * as actionCreatorNotify from "../../../../store/root_actions/notifications";
import * as actionCreatorModal from "../modal/actions";
import {
  isObjectEmpty,
  copyToClipboard,
} from "../../../../utils/helpers/common";

class Notify extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    htmlTemplate: PropTypes.string.isRequired,
    closeNotify: PropTypes.bool.isRequired,
    typeGroup: PropTypes.string.isRequired,
    messageHeading: PropTypes.string,
    messageBody: PropTypes.string,
    messageError: PropTypes.string,
    classStyle: PropTypes.string.isRequired,
    classPosition: PropTypes.string.isRequired,
    successPayment: PropTypes.object.isRequired,
    subscriptionEpay: PropTypes.object,
    statusPayment: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false,
      isSubmited: false,
    };

    this.ePayFormRef = React.createRef(null);
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.ePayFormRef.current) {
        this.ePayFormRef.current.submit();
      }
    }, 3000);
  }

  componentWillUnmount() {
    if (this.state.isSubmited) {
      this.setState({
        isSubmited: false,
      });
    }
  }

  handleClose = () => {
    if (this.props.closeNotify) {
      this.props.notifyMessageReset(this.props.typeGroup);
    }
  };

  handleCopyToClipboard = () => {
    copyToClipboard(this.textArea);

    this.setState({ copySuccess: true });

    const copyContextTimeout = setTimeout(() => {
      this.setState({ copySuccess: false });

      clearTimeout(copyContextTimeout);
    }, 2000);
  };
  handleSubmit = () => {
    this.setState({
      isSubmited: true,
    });
  };

  render() {
    const {
      messageHeading,
      messageBody,
      messageError,
      htmlTemplate,
      closeNotify,
      type,
      active,
      classStyle,
      classPosition,
      successPayment,
      subscriptionEpay,
      statusPayment,
    } = this.props;

    const { copySuccess } = this.state;
    const isEmpty = isObjectEmpty(successPayment);

    return (
      <div
        className={`${classStyle} ${classPosition} ${active ? "active" : ""} ${
          htmlTemplate === `${type}CatchError` ||
          htmlTemplate === `${type}Error`
            ? "activeIndex"
            : ""
        }`}
      >
        {closeNotify ? (
          <div className="notify-close">
            <button
              type="button"
              title="Затвори прозореца"
              className="notify-close-btn easy-math-button btn-text-yellow no-padding"
              onClick={this.handleClose}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        ) : null}
        <div className="notify">
          {htmlTemplate === `${type}CatchError` ||
          htmlTemplate === `${type}Error` ? (
            <p className="notify-title">
              <i className="fas fa-exclamation-triangle"></i>
              <span className="notify-heading-error">
                {Parser(messageError)}
              </span>
            </p>
          ) : (
            <div className="notify-title">
              <i
                className={`fas ${
                  statusPayment === "cancelled" ? "fa-ban" : "fa-check"
                }`}
              ></i>
              <div className="notify-message-heading">
                {Parser(messageHeading)}
              </div>
            </div>
          )}
          {htmlTemplate !== `${type}CatchError` &&
          htmlTemplate !== `${type}Error` ? (
            <div className="notify-info">{Parser(messageBody)}</div>
          ) : (
            <div className="notify-info">
              <span>{Parser(messageHeading)}</span>
              <p className="notify-error-contact">office@easymath.bg</p>
              <span>Телефон за връзка:</span>
              <p className="notify-error-contact">+359 888 870812</p>
              <p className="notify-body-error">{messageBody}</p>
            </div>
          )}
          {!isEmpty && successPayment.type === "bank" ? (
            <div className="success-payment-subscription">
              <p className="success-payment-content">
                {Parser(successPayment.company)}
              </p>
              <p className="success-payment-content payment-iban">
                IBAN: {successPayment.IBAN}
                <textarea
                  readOnly
                  className="textarea-clipboard"
                  ref={(textarea) => (this.textArea = textarea)}
                  value={successPayment.IBAN}
                />
                {copySuccess ? (
                  <button
                    type="button"
                    className="clipboard-copy-iban copy-success"
                  >
                    <i className="fas fa-check-circle" disabled></i>
                  </button>
                ) : (
                  <button
                    onClick={this.handleCopyToClipboard}
                    type="button"
                    className="clipboard-copy-iban"
                    title="Копирайте IBAN"
                  >
                    <i className="far fa-copy"></i>
                  </button>
                )}
              </p>
              <p className="success-payment-content">
                BIC: {successPayment.bic}
              </p>
              <p className="success-payment-content payment-number offset-payment">
                {successPayment.successPayment}
              </p>
              {/* <p className="success-payment-content payment-number">Участник No. {successPayment.childId}</p> */}
            </div>
          ) : !isEmpty && successPayment.type === "epay" ? (
            <div className="success-payment-subscription">
              <p className="success-payment-content">
                {" "}
                Основание за плащането:
              </p>
              <p className="success-payment-content">
                {successPayment.successPayment}
              </p>

              <form
                ref={this.ePayFormRef}
                action={subscriptionEpay.action}
                method={subscriptionEpay.method}
                target="_self"
                onSubmit={this.handleSubmit}
              >
                <input
                  type="hidden"
                  name="CHECKSUM"
                  value={subscriptionEpay.CHECKSUM}
                />
                <input
                  type="hidden"
                  name="ENCODED"
                  value={subscriptionEpay.ENCODED}
                />
                <input
                  type="hidden"
                  name="PAGE"
                  value={subscriptionEpay.PAGE}
                />
                <input
                  type="hidden"
                  name="URL_CANCEL"
                  value={subscriptionEpay.URL_CANCEL}
                />
                <input
                  type="hidden"
                  name="URL_OK"
                  value={subscriptionEpay.URL_OK}
                />
                <div className="payment-text">
                  След няколко секунди ще бъдете пренасочени. Ако това не се
                  случи, моля{" "}
                  <a
                    href="/#"
                    onClick={(e) => e.target.closest("form").submit()}
                  >
                    натиснете тук
                  </a>
                  .
                </div>
              </form>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ addSubscription }) => {
  return {
    successPayment: addSubscription.successPayment,
    subscriptionEpay: addSubscription.subscriptionEpay,
    statusPayment: addSubscription.statusPayment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actionCreatorNotify,
      ...actionCreatorModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notify);
