import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import MathLogoSvg from "./../shared/ui/svg/math_logo_svg";

import "./css/style.scss";

class Footer extends Component {
  render() {
    let content = null;
    let platformWasLoaded = !this.props.location.pathname.includes("platform");
    content = (
      <footer className="grid-container footer">
        {platformWasLoaded && (
          <div className="grid-item logo">
            <MathLogoSvg />
          </div>
        )}
        <div className="grid-item footer-links">
          <nav>
            <NavLink className="footer-link" to="/about-us">
              За нас
            </NavLink>
            <NavLink className="footer-link" to="/author-details">
              Нашият екип
            </NavLink>
            <NavLink className="footer-link" to="/general-terms">
              Общи условия
            </NavLink>
            <NavLink className="footer-link" to="/private-data">
              Лични данни
            </NavLink>
            <NavLink className="footer-link" to="/faq/0">
              Често задавани въпроси
            </NavLink>
            <NavLink className="footer-link" to="/contact-us">
              Контакти
            </NavLink>
          </nav>
          <div className="copyrights visible-desktop">
            &copy; 2019 - 2022 easymath.bg - всички права запазени
          </div>
        </div>
        {platformWasLoaded && (
          <div className="grid-item social">
            <a
              href="https://www.facebook.com/Easymathbg-Математиката-е-лесна-118681832868219"
              rel="noopener noreferrer"
              target="_blank"
            >
              Последвай ни: <i className="fab fa-facebook"></i>
            </a>
          </div>
        )}
        <div className="copyrights visible-mobile">
          &copy; 2019 - 2022 easymath.bg - всички права запазени
        </div>
      </footer>
    );

    return <Fragment> {content} </Fragment>;
  }
}

export default connect()(Footer);
