import * as actionTypes from './actions';
import { updateObject } from './../../hoc/update-object/update-object';

const initialState = {
  name: "",
  body: "",
  loaded: false
}

const getContentSuccess = (state, action) => {

  return updateObject(state, {
    name: action.content.name,
    body: action.content.body,
    loaded: action.content.loaded
  })
}

const partialMission = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_PARTIAL_MISSION_SUCCESS: return getContentSuccess(state, action);
    default: return state;
  }
}

export default partialMission;