export const GET_NEWS_CATEGORIES_INIT = "GET_NEWS_CATEGORIES_INIT";
export const GET_NEWS_CATEGORIES_DATA = "GET_NEWS_CATEGORIES_DATA";
export const GET_NEWS = "GET_NEWS";
export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLE_INIT = "GET_ARTICLE_INIT";
export const GET_ARTICLE = "GET_ARTICLE";

export function getNewsCategoriesInit() {
  return {
    type: GET_NEWS_CATEGORIES_INIT,
    newsType: 'categories',
    newsCategoriesType: 'categoriesNews',
  }
}
export function getNewsCategoriesData(data) {
  return {
    type: GET_NEWS_CATEGORIES_DATA,
    data
  }
}
export function getNews(url, newsType, newsCategoryType) {
  return {
    type: GET_NEWS,
    url,
    newsType,
    newsCategoryType
  }
}
export function getArticles(data) {
  return {
    type: GET_ARTICLES,
    data
  }
}
export function getArticleInit(id) {
  return {
    type: GET_ARTICLE_INIT,
    articleType: 'newsArticle',
    id
  }
}
export function getArticle(data) {
  return {
    type: GET_ARTICLE,
    data,
  };
}