export const DELIVERY_UPLOAD_FILES = 'DELIVERY_UPLOAD_FILES';
export const GET_UPLOADED_FILES_DATA = 'GET_UPLOADED_FILES_DATA';
export const DELIVERY_UPLOAD_FILES_FROM_QR = 'DELIVERY_UPLOAD_FILES_FROM_QR';
export const DELETE_UPLOAD_FILES = 'DELETE_UPLOAD_FILES';
export const SUCCESSFULLY_UPLOADED_FILES = 'SUCCESSFULLY_UPLOADED_FILES';
export const LISTING_UPLOADED_FILES = 'LISTING_UPLOADED_FILES';

export const deliveryUploadFiles = (data, competitionId, questId) => {

  return {
    type: DELIVERY_UPLOAD_FILES,
    uploadType: 'upload',
    uploadAuthorize: 'uploadAuthorize',
    authedTypeService: "authorizationAccessToken",
    competitionId,
    questId,
    data
  }
}
export const getUploadedFilesData = (data) => {

  return {
    type: GET_UPLOADED_FILES_DATA,
    data
  }
}

export const deliveryUploadFilesThroughQr = (data, competitionId, questId, userId, signature) => {

  return {
    type: DELIVERY_UPLOAD_FILES_FROM_QR,
    uploadType: 'upload',
    uploadAuthorize: 'uploadAuthorize',
    authedTypeService: "authorizationAccessToken",
    data,
    competitionId,
    questId,
    userId,
    signature
  }
}

export const deleteUploadFiles = (data, competitionId, questId, signature, userId = null) => {

  return {
    type: DELETE_UPLOAD_FILES,
    uploadType: 'delete',
    uploadFileType: 'upload',
    authedTypeService: "authorizationAccessToken",
    competitionId,
    questId,
    data,
    signature,
    userId
  }
}

export const successfullyUploadedFiles = (state) => {

  return {
    type: SUCCESSFULLY_UPLOADED_FILES,
    state
  }
}

export const listingUploadedFiles = (competitionId, questId, signature, userId = null) => {

  return {
    type: LISTING_UPLOADED_FILES,
    uploadType: 'upload',
    uploadAuthorize: 'uploadAuthorize',
    authedTypeService: "authorizationAccessToken",
    competitionId,
    questId,
    signature,
    userId
  }
}