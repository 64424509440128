const notifyModel = {
  type: "",
  htmlTemplate: "",
  active: false,
  closeNotify: false,
  messageError: "",
  messageHeading: "",
  messageBody: "",
}
export const notifyInitState = {
  login: {
    ...notifyModel,
      typeGroup: 'Auth',
  },
  register: {
    ...notifyModel,
      typeGroup: 'Auth',
  },
  forgottenPassword: {
    ...notifyModel,
      typeGroup: 'Auth',
  },
  authorizeNewPassword: {
    ...notifyModel,
      typeGroup: 'Auth',
  },
  registerConfirm: {
    ...notifyModel,
      typeGroup: 'Auth',
  },
  contact: {
    ...notifyModel,
      typeGroup: 'Contact',
  },
  contactInfo: {
    ...notifyModel,
      typeGroup: 'ContactInfo',
  },
  categoriesNews: {
    ...notifyModel,
      typeGroup: 'CategoriesNews',
  },
  news: {
    ...notifyModel,
      typeGroup: 'News',
  },
  newsArticle: {
    ...notifyModel,
      typeGroup: 'NewsArticle',
  },
  subscriptions: {
    ...notifyModel,
      typeGroup: 'Subscriptions',
  },
  videoQuiz: {
    ...notifyModel,
      typeGroup: 'VideoQuiz',
  },
  modalVideoInfo: {
    ...notifyModel,
      typeGroup: 'ModalVideoInfo',
  },
  videoList: {
    ...notifyModel,
      typeGroup: 'VideoList',
  },
  partialSubscriptions: {
    ...notifyModel,
      typeGroup: 'PartialSubscriptions',
  },
  subscriptionsPage: {
    ...notifyModel,
      typeGroup: 'SubscriptionsPage',
  },
  subscriptionDiscount: {
    ...notifyModel,
      typeGroup: 'SubscriptionDiscount'
  },
  mission: {
    ...notifyModel,
      typeGroup: 'Mission'
  },
  exercises: {
    ...notifyModel,
      typeGroup: 'Exercises'
  },
  aboutUs: {
    ...notifyModel,
      typeGroup: 'AboutUs'
  },
  mathQuizList: {
    ...notifyModel,
      typeGroup: 'MathQuizList'
  },
  modalMathQuiz: {
    ...notifyModel,
      typeGroup: 'ModalMathQuiz'
  },
  contestExercise: {
    ...notifyModel,
      typeGroup: 'ContestExercise'
  },
  paymentSubscription: {
    ...notifyModel,
      typeGroup: 'PaymentSubscription'
  },
  contestInfo: {
    ...notifyModel,
      typeGroup: 'ContestInfo'
  },
  author: {
    ...notifyModel,
    typeGroup: 'Author'
  },
  generalTerms: {
    ...notifyModel,
    typeGroup: 'GeneralTerms'
  },
  privateData: {
    ...notifyModel,
    typeGroup: 'PrivateData'
  },
  modalResults: {
    ...notifyModel,
    typeGroup: 'ModalResults'
  },
  calculateResult: {
    ...notifyModel,
    typeGroup: 'CalculateResult'
  },
  faqPage: {
    ...notifyModel,
    typeGroup: 'FaqPage'
  },
  upload: {
    ...notifyModel,
    typeGroup: 'Upload'
  }
};