import * as actionTypes from '../root_actions/auth';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  token: null,
  refreshToken: null,
  name: "",
  email: "",
  userId: null,
  expiration: 0,
  signIn: false,
  accessTokenExprirationTime: 0,
  authModalState: false,
  isForgottenPasswordOpen: false,
  authorizeNewPasswordAuthorizeCode: "",
  authorizeNewPasswordTemplate: false,
  registerConfirmAuthorizeCode: "",
  registerConfirmTemplate: false,
  examPreparationSubscription: null,
  videoLessonsSubscription: null,
  refreshingTokenInPorgress: false
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.authData.token,
    refreshToken: action.authData.refreshToken,
    name: action.authData.name,
    email: action.authData.email,
    userId: action.authData.userId,
    expiration: action.authData.expiration,
    accessTokenExprirationTime: action.authData.exprirationTime,
    examPreparationSubscription: action.authData.examPreparationSubscription,
    videoLessonsSubscription: action.authData.videoLessonsSubscription,
    signIn: true
  });
};

const authRefreshSignIn = (state, action) => {
  return updateObject(state, {
    token: action.authData.token,
    refreshToken: action.authData.refreshToken,
    name: action.authData.name,
    email: action.authData.email,
    userId: action.authData.userId,
    expiration: action.authData.expiration,
    accessTokenExprirationTime: action.authData.exprirationTime,
    examPreparationSubscription: action.authData.examPreparationSubscription,
    videoLessonsSubscription: action.authData.videoLessonsSubscription,
    signIn: true
  });
};

const logoutReset = (state) => {
  return updateObject(state, {
    token: null,
    refreshToken: null,
    name: "",
    email: "",
    userId: null,
    expiration: 0,
    accessTokenExprirationTime: 0,
    examPreparationSubscription: null,
    videoLessonsSubscription: null,
    signIn: false

  });
};

const authModalState = (state, action) => {

  if (typeof action.subscriptionState === "boolean") {
    return updateObject(state , {
      authModalState: action.authModalState
    })
  } else {
    return updateObject(state, {
      authModalState: action.authModalState
    })
  }
};

const forgottenPasswordState = (state, action) => {
  return updateObject(state, {
    isForgottenPasswordOpen: action.isForgottenPasswordOpen
  });
};

const authorizeCode = (state, action) => {
  return updateObject(state, {
    [`${action.authType}AuthorizeCode`]: action.authCode,
    [`${action.authType}Template`]: action.authHtmlTemplate,
  });
};

const authorizeCodeReset = (state, action) => {
  return updateObject(state, {
    authorizeNewPasswordAuthorizeCode: action.authCode,
    registerConfirmAuthorizeCode: action.authCode,
  });
};

const authorizeNewPasswordTemplateReset = (state, action) => {
  return updateObject(state, {
    authorizeNewPasswordTemplate: action.authHtmlTemplate
  });
};
const authorizeRegisterTemplateReset = (state, action) => {
  return updateObject(state, {
    registerConfirmTemplate: action.authHtmlTemplate
  });
};

const refreshingTokenInProgress = (state, action) => {
  return updateObject(state, {
    refreshingTokenInPorgress: action.refreshingToken
  });
};

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_REFRESH_SIGNIN: return authRefreshSignIn(state, action);
    case actionTypes.AUTH_LOGOUT_RESET: return logoutReset(state);
    case actionTypes.AUTH_MODAL_STATE: return authModalState(state, action);
    case actionTypes.FORGOTTEN_PASSWORD_STATE: return forgottenPasswordState(state, action);
    case actionTypes.AUTHORIZE_CODE: return authorizeCode(state, action);
    case actionTypes.AUTHORIZE_CODE_RESET: return authorizeCodeReset(state, action);
    case actionTypes.AUTHORIZE_NEW_PASSWORD_TEMPLATE_RESET: return authorizeNewPasswordTemplateReset(state, action);
    case actionTypes.AUTHORIZE_REGISTER_TEMPLATE_RESET: return authorizeRegisterTemplateReset(state, action);
    case actionTypes.AUTH_REFRESH_TOKEN_IN_PROGRESS: return refreshingTokenInProgress(state, action);
    default: return state;
  }
};

export default reducer;