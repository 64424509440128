import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import * as actionsCreatorAuth from "../../store/root_actions/auth";
import { checkValidationFields, isInputValid, debounced } from "../../utils/helpers/common";
import { forgottenPasswordInitState } from '../../constants/auth_types';

class ForgottenPassword extends Component {
  static propTypes = {
    notifyForgottenPasswordHtmlTemplate: PropTypes.string.isRequired,
    notifyForgottenPasswordMessageError: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { ...forgottenPasswordInitState };
  }

  componentDidMount() {
    if (!this.props.isForgottenPasswordOpen) {
      this.setState({ ...forgottenPasswordInitState });
    }
  }

  handleChange = (e) => {
    const { forgottenPassword, forgottenPasswordValidate, forgottenPasswordUpdate, } = this.state;

    let newFormData = { ...forgottenPassword };
    let newValidateFormData = { ...forgottenPasswordValidate };
    let newUpdateFormData = { ...forgottenPasswordUpdate };

    const targetValue = e.currentTarget.value;
    const targetName = e.currentTarget.name;

    newFormData[targetName] = targetValue;
    newValidateFormData[targetName] = isInputValid(targetValue,`${targetName.toUpperCase()}`);
    newUpdateFormData[targetName] = true;

    this.setState({
      forgottenPassword: newFormData,
      forgottenPasswordValidate: newValidateFormData,
      forgottenPasswordUpdate: newUpdateFormData,
    });
  };

  handleSubmit = () => {
    const deliveryData = { ...this.state.forgottenPassword };
    this.props.deliverAuthedData(deliveryData, "forgottenPassword");
  };

  handleFocus = (e, type) => {
    const currentName = e.currentTarget.name;
    const newFocusData = {
      ...this.state[`${type}Focus`]
    };
    newFocusData[currentName] = true;

    this.setState({
      [`${type}Focus`]: newFocusData
    })
  }

  handleFocusOut = (e, type) => {
    const currentName = e.currentTarget.name;
    const newFocusData = {
      ...this.state[`${type}Focus`]
    };
    newFocusData[currentName] = false;

    this.setState({
      [`${type}Focus`]: newFocusData
    })
  }

  render() {
    const { forgottenPassword, forgottenPasswordValidate, forgottenPasswordUpdate, forgottenPasswordFocus } = this.state;
    const { notifyForgottenPasswordMessageError, notifyForgottenPasswordHtmlTemplate } = this.props;
    const isValid = checkValidationFields(forgottenPasswordValidate);

    return (
      <form className="forgotten-password-form">
        <h2 className="auth-title text-fp">Забравена Парола</h2>
        <p className="auth-text-content text-fp">
          Въведете вашия e-mail адрес на акаунта, за да ви изпратим линк, с
          който да промените вашата парола.
        </p>

        <fieldset className="form-field-set form-offset">
          <input type="text" id="forgotten-password-email" name="email" value={forgottenPassword.email}
            className={`white-autofill form-input input-blue ${!forgottenPasswordValidate.email && forgottenPasswordUpdate.email ? 'invalid ': ''}`}
            onChange={this.handleChange}
            onFocus={(e) => this.handleFocus(e, 'forgottenPassword')}
            onBlur={(e) => this.handleFocusOut(e, 'forgottenPassword')}
            placeholder="E-mail"
          />
          <label htmlFor="forgotten-password-email" className="form-label form-label-error">
            {notifyForgottenPasswordHtmlTemplate === "forgottenPasswordError" ? notifyForgottenPasswordMessageError : ""}
          </label>
          <label className={`form-label-focus focus-white ${forgottenPasswordFocus.email ? 'focus-animate' : ''}
              ${!forgottenPasswordValidate.email && forgottenPasswordUpdate.email ? 'invalid ': ''}`}>
            </label>
        </fieldset>

        <fieldset className="form-field-set field-set-btn">
          <button type="button" className="easy-math-button btn-yellow btn-form-size" value="Send" title="Изпрати"
            disabled={!isValid}
            onClick={debounced(500, this.handleSubmit, [])}>
            Изпрати
          </button>
        </fieldset>
      </form>
    );
  }
}

const mapStateToProps = ({ notifications }) => {
  return {
    notifyForgottenPasswordMessageError: notifications.forgottenPassword.messageError,
    notifyForgottenPasswordHtmlTemplate: notifications.forgottenPassword.htmlTemplate
  };
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
    ...actionsCreatorAuth
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword)