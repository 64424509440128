import * as actionTypes from "./actions";
import { updateObject } from "./../../hoc/update-object/update-object";

const initialState = {
  accountStats: false,
  accountStatsContent: {
    loaded: false,
    subscriptions: [],
  },
};

const toggleAccountStatus = (state, action) => {
  return updateObject(state, {
    accountStats: action.accountStats,
  });
};

const deliverAccountStatus = (state, action) => {
  return updateObject(state, {
    accountStatsContent: action.accountStatsContent,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_ACCOUNT_STATUS:
      return toggleAccountStatus(state, action);
    case actionTypes.ACCOUNT_STATS_DELIVER:
      return deliverAccountStatus(state, action);
    default:
      return state;
  }
};

export default reducer;
