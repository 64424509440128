export const subscriptionsInitState = {
  subsriptionPrice: 0,
  selectedInvoiceId: 23,
  percentDiscountPrice: 0,
  isContestSelected: false,
  competitionId: null,
  subscription: {
    invoice: false,
    selectInvoice: "legal",
    payment: "",
    subscriptionId: "Абонаменти",
  },
  contest: {
    name: "",
    level: "Клас",
    school: "",
    city: "",
    parent: "",
    contestPhone: "",
  },
  legal: {
    company: "",
    companyId: "",
    vatNumber: "",
    city: "",
    address: "",
    personInCharge: "",
    recipient: "",
  },
  individual: {
    city: "",
    address: "",
    name: "",
  },
  subscriptionValidate: {
    payment: false,
    subscriptionId: false,
  },
  contestValidate: {
    name: true,
    level: false,
    school: true,
    city: true,
    parent: false,
    contestPhone: false,
  },
  legalValidate: {
    company: false,
    companyId: false,
    vatNumber: true,
    city: false,
    address: false,
    personInCharge: false,
    recipient: false,
  },
  individualValidate: {
    name: false,
    city: false,
    address: false,
  },
  subscriptionUpdate: {
    invoice: false,
    payment: false,
    subscriptionId: false,
  },
  contestUpdate: {
    name: false,
    level: false,
    school: false,
    city: false,
    parent: false,
    contestPhone: false,
  },
  legalUpdate: {
    company: false,
    companyId: false,
    vatNumber: false,
    city: false,
    address: false,
    personInCharge: false,
    recipient: false,
  },
  individualUpdate: {
    name: false,
    city: false,
    address: false,
  },
  subscriptionFocus: {
    promoCode: false,
    subscriptionId: false,
    name: false,
    level: false,
    school: false,
    city: false,
    parent: false,
    contestPhone: false,
  },
  legalFocus: {
    company: false,
    companyId: false,
    vatNumber: false,
    city: false,
    address: false,
    personInCharge: false,
    recipient: false,
  },
  individualFocus: {
    city: false,
    address: false,
    name: false,
  },
};
export const promoCodeInitState = {
  promoCode: "",
  promoCodeValidate: false,
  promoCodeUpdate: false,
};
export const legalInitState = {
  ...subscriptionsInitState.legal,
};
export const individualInitState = {
  ...subscriptionsInitState.individual,
};
export const individualValidateInitState = {
  ...subscriptionsInitState.individualValidate,
};
export const legalValidateInitState = {
  ...subscriptionsInitState.legalValidate,
};
export const individualUpdateInitState = {
  ...subscriptionsInitState.individualUpdate,
};
export const legalUpdateInitState = {
  ...subscriptionsInitState.legalUpdate,
};
