
export const GET_VIDEO_QUIZ = 'GET_VIDEO_QUIZ';

export const GET_VIDEO_LESSONS = 'GET_VIDEO_LESSONS';
export const GET_EXAM_PREPARATION = 'GET_EXAM_PREPARATION';

export function getVideoQuiz(type) {
  return {
    type: GET_VIDEO_QUIZ,
    quizType: type,
    loadingType: 'videoQuiz',
  }
}
export function getVideoLessons(data) {
  return {
    type: GET_VIDEO_LESSONS,
    data
  }
}
export function getExamPreparation(data) {
  return {
    type: GET_EXAM_PREPARATION,
    data
  }
}