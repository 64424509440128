export const INTRO_COMPETITIONS_INIT = 'INTRO_COMPETITIONS_INIT';
export const GET_INTRO_COMPETITIONS_DATA = 'GET_INTRO_COMPETITIONS_DATA';
export const RESET_INTRO_CONTEST_STATE = 'RESET_INTRO_CONTEST_STATE';

export const introCompetitionsInit = () => {
  return {
    type: INTRO_COMPETITIONS_INIT,
    introContestType: '',
    competitionsAuthorize: 'upcoming',
    authedTypeService: "authorizationAccessToken"
  }
}

export const getIntroCompetitionsData = (data) => {
  return {
    type: GET_INTRO_COMPETITIONS_DATA,
    data
  }
}

export const resetIntoContestState = () => {
  return {
    type: RESET_INTRO_CONTEST_STATE
  }
}