import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import './add_subscription.scss';

import * as actionsCreatorSubscription from "./actions";
import * as actionsCreatorNotify from "../../store/root_actions/notifications";

import Notify from "../shared/ui/notifications/notify_template";
import Spinner from "../shared/ui/spinner/spinner";
import Modal from '../shared/ui/modal/modal';

class PaymentSubscriptionNotify extends PureComponent {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    notifyPaymentSubscription: PropTypes.object.isRequired,
    isPaymentModalStateOpen: PropTypes.bool.isRequired,
    statusPayment: PropTypes.string.isRequired
  }
  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.props.history.push("/");
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
    if (this.props.notifyPaymentSubscription.active) {
      this.props.notifyMessageReset("PaymentSubscription");
    }

    if (this.props.statusPayment !== '') {
      this.props.paymentStatus('');
    }
  }

  handleClose = () => {
    this.props.successFullyPaymentModalState(false);
  }

  render() {

    const { loading, notifyPaymentSubscription, isPaymentModalStateOpen } = this.props;

    const notifyPaymentSubscriptionTemplate = <Notify
      typeGroup={'PaymentSubscription'}
      type={notifyPaymentSubscription.type}
      htmlTemplate={notifyPaymentSubscription.htmlTemplate}
      active={notifyPaymentSubscription.active}
      closeNotify={notifyPaymentSubscription.closeNotify}
      messageHeading={notifyPaymentSubscription.messageHeading}
      messageBody={notifyPaymentSubscription.messageBody}
      messageError={notifyPaymentSubscription.messageError}
      classStyle={'notify-white'}
      classPosition={'notify-relative'} />

    return (
      <Modal
        classID={`paymentSubscription-main`}
        classMain={`paymentSubscription-main`}
        classWrapper={`paymentSubscription-wrapper`}
        classShell={`paymentSubscription-shell ${notifyPaymentSubscription.active ? 'modal-error-offset' : ''}`}
        handleClose={this.handleClose}
        stateModal={isPaymentModalStateOpen}
        range={300}>

        {
          loading
            ? <Spinner classColor={`spinner-white`} />
            : null
        }
        {
          notifyPaymentSubscription.htmlTemplate === "paymentSubscriptionSuccess" || notifyPaymentSubscription.htmlTemplate === "paymentSubscriptionCatchError" || notifyPaymentSubscription.htmlTemplate === "paymentSubscriptionError"
            ? notifyPaymentSubscriptionTemplate
            : null
        }
      </Modal>
    )
  }
}
const mapStateToProps = ({ loading, notifications, addSubscription }) => {

  return {
    notifyPaymentSubscription: notifications.paymentSubscription,
    loading: loading.paymentSubscriptionLoading,
    isPaymentModalStateOpen: addSubscription.paymentModalState,
    statusPayment: addSubscription.statusPayment
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    ...actionsCreatorSubscription,
    ...actionsCreatorNotify
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentSubscriptionNotify));
