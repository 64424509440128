import { applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, takeEvery } from 'redux-saga/effects';

import logger from './logger';
import { DELIVER_AUTHED_DATA, DELIVER_AUTHED_REGISTER_AUTHORIZE, AUTH_LOGOUT, AUTH_REFRESH, REFRESH_LOGOUT } from "./../root_actions/auth";
import { deliverAuthedData, deliverAuthedRegisterAuthorize, authLogout, authRefresh, authRefreshLogout } from "./auth";

import { ACCOUNT_STATS_INFO } from "./../../components/account_stats/actions";
import { initAccountStatsInfo } from "./../../components/account_stats/middleware";

import { GET_CONTENT_ABOUT_US } from "./../../components/page_about_us/actions";
import { getPageContentAboutUs } from "./../../components/page_about_us/middleware";

import { GET_CONTENT_SUBSCRIPTIONS } from "./../../components/page_subscriptions/actions";
import { getPageContentSubscriptions } from "./../../components/page_subscriptions/middleware";

import { GET_CONTENT_PARTIAL_MISSION } from "./../../components/mission_small/actions";
import { getPageContentPartialMission } from "./../../components/mission_small/middleware";

import { GET_CONTENT_PARTIAL_SUBSCRIPTIONS } from "./../../components/sumbscriptions_small/actions";
import { getPageContentPartialSubscriptions } from "./../../components/sumbscriptions_small/middleware";

import { GET_VIDEO_QUIZ } from "./../../components/jumbotron_cta/actions";
import { getVideoQuiz } from "./../../components/jumbotron_cta/middleware";

import { GET_CONTENT_GENERAL_TERMS } from "./../../components/page_general_terms/actions";
import { getPageContentGeneralTerms } from "./../../components/page_general_terms/middleware";

import { GET_CONTENT_PRIVATE_DATA } from "./../../components/page_private_data/actions";
import { getPageContentPrivateData } from "./../../components/page_private_data/middleware";

import { GET_CONTENT_AUTHOR_PAGE } from "./../../components/page_author/actions";
import { getPageContentAuthorData } from "./../../components/page_author/middleware";

import { GET_CONTENT_FAQ_PAGE } from "./../../components/page_faq/actions";
import { getPageContentFaqQuestions } from "./../../components/page_faq/middleware";

import { GET_CONTENT_VIDEO_LIST, GET_CONTENT_VIDEO_INFO, GET_CONTENT_CURRENT_VIDEO, GET_CONTENT_CURRENT_VIDEO_EXERCISE} from "./../../components/video-tutoring/actions";
import { getPageContentVideoList, getPageContentVideoInfo, getCurrentVideoInfo, getCurrentVideoExerciseInfo } from "./../../components/video-tutoring/middleware";

import { GET_CONTENT_CONTEST_LIST, GET_CONTENT_CONTEST_INFO, GET_CONTENT_CONTEST_FILTERS, GET_CONTENT_CURRENT_CONTEST_INFO, GET_CONTENT_CURRENT_CONTEST_EXERCISE, MATH_CONTEST_CALCULATE_RESULTS, GET_MATH_CONTEST_RESULTS_LITERATURE, GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON } from "./../../components/math_quiz/actions";
import { getPageContentContestList, getPageContentContestInfo, getPageContentContestFilters, getCurrentContestInfo, getCurrentContestExerciseInfo, mathContestCalculateResults, getMathContestResultsLiterature, getMathContestResultsAnswerComparison } from "./../../components/math_quiz/middleware";

import { GET_ONLINE_CONTEST_INFO, GET_ONLINE_CONTEST_EXERCISE, ONLINE_CONTEST_CALCULATE_RESULTS } from "./../../components/online_contest/actions";
import { getOnlineContestInfo, getOnlineContestExerciseInfo, onlineContestCalculateResults } from "./../../components/online_contest/middleware";

import { DELIVER_CONTACT_DATA, GET_CONTACT_INFO_INIT } from "./../../components/page_contact_us/actions";
import { deliverContactData, getContactInfoData } from "./../../components/page_contact_us/middleware";

import { GET_NEWS_CATEGORIES_INIT, GET_NEWS, GET_ARTICLE_INIT } from "./../../components/page_news/actions";
import { getNewsCategoriesData, getNews, getArticle } from "./../../components/page_news/middleware";

import { INTRO_COMPETITIONS_INIT } from "./../../components/intro_competitions/actions";
import { introCompetitions } from "./../../components/intro_competitions/middleware";

import { DELIVERY_SUBSCRIPTION_PAYMENT, SUBSCRIPTIONS_INIT, DELIVERY_SUBSCRIPTION_PROMO_CODE, DELIVERY_PAYMENT_DATA } from "./../../components/add_subscription/actions";
import { deliverySubscriptionPayment, subscriptions, deliverySubscriptionPromoCode, subscriptionPaymentNotify } from "./../../components/add_subscription/middleware";

import { DELIVERY_UPLOAD_FILES, DELIVERY_UPLOAD_FILES_FROM_QR, DELETE_UPLOAD_FILES, LISTING_UPLOADED_FILES } from "./../../components/upload_files/actions";
import { deliveryUploadFiles, deliveryUploadFilesFromQr, deleteUploadFiles, listUploadedFiles } from "./../../components/upload_files/middleware";

import { GET_HOT_STRIPS } from "./../../components/header_bar/actions";
import { getHotStrips } from "./../../components/header_bar/middleware";

let composeEnhancers = "";

if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const sagaMiddleware = createSagaMiddleware();

let middlewareWOEnhancers = process.env.NODE_ENV === "production" ? applyMiddleware(sagaMiddleware) : composeEnhancers(applyMiddleware(sagaMiddleware))

export default middlewareWOEnhancers;

let sagasArray = [
  takeEvery(DELIVER_AUTHED_DATA, deliverAuthedData),
  takeEvery(AUTH_LOGOUT, authLogout),
  takeEvery(AUTH_REFRESH, authRefresh),
  takeEvery(DELIVER_AUTHED_REGISTER_AUTHORIZE, deliverAuthedRegisterAuthorize),
  takeEvery(REFRESH_LOGOUT, authRefreshLogout),
  takeEvery(ACCOUNT_STATS_INFO, initAccountStatsInfo),
  takeEvery(GET_CONTENT_ABOUT_US, getPageContentAboutUs),
  takeEvery(GET_CONTENT_SUBSCRIPTIONS, getPageContentSubscriptions),
  takeEvery(GET_CONTENT_PARTIAL_MISSION, getPageContentPartialMission),
  takeEvery(GET_CONTENT_PARTIAL_SUBSCRIPTIONS, getPageContentPartialSubscriptions),
  takeEvery(GET_VIDEO_QUIZ, getVideoQuiz),
  takeEvery(GET_CONTENT_GENERAL_TERMS, getPageContentGeneralTerms),
  takeEvery(GET_CONTENT_PRIVATE_DATA, getPageContentPrivateData),
  takeEvery(GET_CONTENT_AUTHOR_PAGE, getPageContentAuthorData),
  takeEvery(GET_CONTENT_FAQ_PAGE, getPageContentFaqQuestions),
  takeEvery(GET_CONTENT_VIDEO_LIST, getPageContentVideoList),
  takeEvery(GET_CONTENT_VIDEO_INFO, getPageContentVideoInfo),
  takeEvery(GET_CONTENT_CURRENT_VIDEO, getCurrentVideoInfo),
  takeEvery(GET_CONTENT_CURRENT_VIDEO_EXERCISE, getCurrentVideoExerciseInfo),
  takeEvery(GET_CONTENT_CONTEST_LIST, getPageContentContestList),
  takeEvery(GET_CONTENT_CONTEST_INFO, getPageContentContestInfo),
  takeEvery(GET_CONTENT_CONTEST_FILTERS, getPageContentContestFilters),
  takeEvery(GET_CONTENT_CURRENT_CONTEST_INFO, getCurrentContestInfo),
  takeEvery(GET_CONTENT_CURRENT_CONTEST_EXERCISE, getCurrentContestExerciseInfo),
  takeEvery(MATH_CONTEST_CALCULATE_RESULTS, mathContestCalculateResults),
  takeEvery(GET_MATH_CONTEST_RESULTS_LITERATURE, getMathContestResultsLiterature),
  takeEvery(GET_MATH_CONTEST_RESULTS_ANSWER_COMPARISON, getMathContestResultsAnswerComparison),
  takeEvery(GET_ONLINE_CONTEST_INFO, getOnlineContestInfo),
  takeEvery(GET_ONLINE_CONTEST_EXERCISE, getOnlineContestExerciseInfo),
  takeEvery(ONLINE_CONTEST_CALCULATE_RESULTS, onlineContestCalculateResults),
  takeEvery(DELIVER_CONTACT_DATA, deliverContactData),
  takeEvery(GET_CONTACT_INFO_INIT, getContactInfoData),
  takeEvery(GET_NEWS_CATEGORIES_INIT, getNewsCategoriesData),
  takeEvery(GET_NEWS, getNews),
  takeEvery(GET_ARTICLE_INIT, getArticle),
  takeEvery(SUBSCRIPTIONS_INIT, subscriptions),
  takeEvery(DELIVERY_SUBSCRIPTION_PAYMENT, deliverySubscriptionPayment),
  takeEvery(DELIVERY_SUBSCRIPTION_PROMO_CODE, deliverySubscriptionPromoCode),
  takeEvery(DELIVERY_PAYMENT_DATA, subscriptionPaymentNotify),
  takeEvery(INTRO_COMPETITIONS_INIT, introCompetitions),
  takeEvery(DELIVERY_UPLOAD_FILES, deliveryUploadFiles),
  takeEvery(DELIVERY_UPLOAD_FILES_FROM_QR, deliveryUploadFilesFromQr),
  takeEvery(DELETE_UPLOAD_FILES, deleteUploadFiles),
  takeEvery(LISTING_UPLOADED_FILES, listUploadedFiles),
  takeEvery(GET_HOT_STRIPS, getHotStrips)
];

if (process.env.NODE_ENV === "development") {
  sagasArray.splice(0, 0, logger());
}

export function* combineSagas() {
  yield all ( sagasArray )
}