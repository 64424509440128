import * as actionTypes from './actions';
import { updateObject } from '../../hoc/update-object/update-object';

const initialState = {
  content: {
    lessons: [],
    total_count: 1
  },
  info: {
    literatureName: '',
    videoInfo: '',
    videoName: '',
    literature: '',
    loaded: false
  },
  currentVideo: {
    name: "",
    free: ""
  },
  currentVideoExercise: {
    lessons: [],
    total_count: []
  }
}

const getContentSuccess = (state, action) => {

  return updateObject(state, {
    content: action.content
  })
}

const getContentModalSuccess = (state, action) => {

  return updateObject(state, {
    info: action.data
  })
}

const getCurrentVideoSuccess = (state, action) => {

  return updateObject(state, {
    currentVideo: action.info[0]
  })
}

const getCurrentVideoExerciseSuccess = (state, action) => {

  // as we get the total number of pages and a pagination needs to be build
  // the id, which corresponds to the itterator is pushed to handmage array
  let arrayMadeByHand = [];

  for (let itterator = 1; itterator <= action.info.total_count; itterator++) {
    arrayMadeByHand.push(itterator);
  }

  let updatedAction = {
    lessons: action.info.lessons,
    total_count: arrayMadeByHand
  }

  return updateObject(state, {
    currentVideoExercise: updatedAction
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_VIDEO_LIST_SUCCESS: return getContentSuccess(state, action);
    case actionTypes.GET_CONTENT_VIDEO_INFO_SUCCESS: return getContentModalSuccess(state, action);
    case actionTypes.GET_CONTENT_CURRENT_VIDEO_SUCCESS: return getCurrentVideoSuccess(state, action);
    case actionTypes.GET_CONTENT_CURRENT_VIDEO_EXERCISE_SUCCESS: return getCurrentVideoExerciseSuccess(state, action);
    default: return state;
  }
}

export default reducer;