import * as actionTypes from './actions';
import { updateObject } from './../../hoc/update-object/update-object';

const initialState = {

  aboutUs: {
    name: 'За нас',
    body: ''
  },
  mission: {
    name: '',
    body: ''
  },
  whyWeChoose: {
    name: '',
    body: ''
  },
  loaded: false
};

const getContentSuccess = (state, action) => {

  return updateObject(state, {
    aboutUs: action.content.aboutUs,
    mission: action.content.mission,
    whyWeChoose: action.content.whyWeChoose,
    loaded: action.content.loaded
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_ABOUT_US_SUCCESS: return getContentSuccess(state, action);
    default: return state;
  }
}

export default reducer;