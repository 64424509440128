import * as actionTypes from './actions';
import { updateObject } from './../../hoc/update-object/update-object';

const initialState = {
  content: {
    items: [],
    loaded: false
  },
}

const getContentSuccess = (state, action) => {
  return updateObject(state, {
    content: action.content
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTENT_PARTIAL_SUBSCRIPTIONS_SUCCESS: return getContentSuccess(state, action);
    default: return state;
  }
}

export default reducer;