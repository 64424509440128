import { updateObjectDeep } from '../../hoc/update-object/update-object';
import { notifyInitState } from '../../constants/notify';
import * as actionType from '../root_actions/notifications';

let updateDataNotify = null;
const initialState = {
  ...notifyInitState
};

const notifierMessages = (state, action) => {

  return updateObjectDeep(state, action.notifierProps.type, {
      type: action.notifierProps.type,
      htmlTemplate: action.notifierProps.htmlTemplate,
      active: action.notifierProps.active,
      closeNotify: action.notifierProps.closeNotify,
      messageError: action.notifierProps.messageError,
      messageHeading: action.notifierProps.messageHeading,
      messageBody: action.notifierProps.messageBody
  });
}

const notifyMessageReset = (state, action) => {

  const notifyObj = {
    type: action.notifyType,
    htmlTemplate: action.htmlTemplate,
    active: action.active,
    closeNotify: action.closeNotify,
    messageError: action.messageError,
    messageHeading: action.messageHeading,
    messageBody: action.messageBody
  }

  for (let key in state) {
    if (state[key].active && state[key].typeGroup === action.typeGroup) {
      if (updateDataNotify === null) {
        updateDataNotify = updateObjectDeep(state, state[key].type, notifyObj);
      } else {
        updateDataNotify = updateObjectDeep(updateDataNotify, state[key].type, notifyObj);
      }
    }
  }
  return updateDataNotify;
}

export default function notifiers (state = initialState, action) {
	switch (action.type) {
    case actionType.NOTIFY_MESSAGES: return notifierMessages(state, action);
    case actionType.NOTIFY_MESSAGE_RESET: return notifyMessageReset(state, action);

    default:
      return state;
  }
}
